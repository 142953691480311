const apiEndPoints = {
  LOGIN: '/Utilities/LoginAPI/',
  FORGOT_PASSWORD: '/Utilities/ForgotPasswordAPI/',
  RESEND_OTP: '/Utilities/ResendOTPAPI/',
  VALIDATE_OTP: '/Utilities/VerifyOTPAPI/',
  COUNTRIES_LIST: '/Utilities/CountriesAPI/',
  STATES_LIST: '/Utilities/StatesAPI/',
  CITIES_LIST: '/Utilities/CitiesAPI/',
  PRODUCT_INFO: '/Customers/GetProductInfoAPI/',
  VERIFY_CUSTOMER: '/Customers/CheckCustomerExistenceAPI/',
  CUSTOMER_RSEND_OTP: '/Customers/CustomerSendOTPAPI/',
  CUSTOMER_VERIFY_OTP: '/Customers/CustomerVerifyOTPAPI/',
  CREATE_CUSTOMER: '/Customers/CustomerCreateAPI/',
  STRIPE_PAYMENT_API: '/Customers/StripePaymentAPI/',
  PAYPAL_PAYMENT_API: '/Customers/PayPalPaymentAPI/',
  SCHEDULE_DEMO: '/Utilities/ScheduleDemoAPI/',
  VALIDATE_COUPON_CODE: '/Customers/ValidateCouponAPI',
  USERS_TESTIMONIALS: '/Customers/GetCustTestimonialsAPI/',
  CONTACT_US: '/Utilities/ContactUsAPI/',
  NEWS_UPDATES: '/Customers/GetNewsUpdatesAPI/',
  PRODUCT_GALLERY: '/Customers/GetProductGalleryAPI/',
  USER_ORDER_DETAILS: '/AptaAdmin/OrderCRUDAPI/',
  REQUEST_ORDER_CANCEL: '/Customers/RequestCancellationAPI/',
  ACTIVATE_DEVICE: '/Customers/VerifyAndActivateDeviceAPI/',
  LOGOUT: '/Customers/LogoutAPI/',

  CHECK_USER_EXISTENCE: '/Utilities/CheckClientExistenceAPI/',
  SUBSCRIBE_ALERTS: '/Utilities/SubsribeAlertsAPI/',
  UPDATE_USER_PROFILE: '/Customers/CustomerInfoAPI/',
  USER_FEEDBACK: '/Customers/FeedbackCRUDAPI/',

  DEVICECRUDAPI: '/AptaAdmin/DeviceCRUDAPI/',
  CHANGE_PASSWORD: '/Customers/ChangePasswordAPI/',

  PEOPLES_API: '/Customers/PeopleCRUDAPI/',
  HOW_IT_WORKS: '/AptaAdmin/HIWQandACRUDAPI/',
};

export default apiEndPoints;
