import React from 'react';

const SecondaryButton = ({ children }) => {
  return (
    <div className='bg-white hover:bg-[#cccccc1f] border border-primary text-primary px-4 max-w-fit min-w-[8rem] flex justify-center py-[6px] rounded text-[15px] cursor-pointer'>
      {children}
    </div>
  );
};

export default SecondaryButton;
