import React, { createContext, useEffect, useReducer } from 'react';
export const AuthContext = createContext();

let initialState = {
  isAuthenticated: JSON.parse(localStorage.getItem('token')) ? true : false,
  token: JSON.parse(localStorage.getItem('token')) || null,
  email: JSON.parse(localStorage.getItem('email')) || null,
  username: JSON.parse(localStorage.getItem('username')) || null,
  user_initials: JSON.parse(localStorage.getItem('user_initials')) || null,
  profile_picture: JSON.parse(localStorage.getItem('profile_picture')) || null,
  user_id: JSON.parse(localStorage.getItem('user_id')) || null,
  order_received: JSON.parse(localStorage.getItem('order_received')) || false,
  device_activated:
    JSON.parse(localStorage.getItem('device_activated')) || false,
};

const reducer = (state, action) => {
  switch (action.type) {
    /**
     * LOGIN
     *
     * as name suggest we will set the user information and preference to the local storage
     * Beacuse local storage only store String, we have to convert everything to string using JSON.stringify() method
     *
     * read more https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify
     */

    case 'LOGIN':
      localStorage.setItem(
        'token',
        JSON.stringify(action.payload.token ?? false)
      );
      localStorage.setItem(
        'email',
        JSON.stringify(action.payload.email ?? false)
      );
      localStorage.setItem(
        'username',
        JSON.stringify(action.payload.username ?? false)
      );
      localStorage.setItem(
        'user_initials',
        JSON.stringify(action.payload.user_initials ?? false)
      );
      localStorage.setItem(
        'profile_picture',
        JSON.stringify(action.payload.profile_picture ?? false)
      );
      localStorage.setItem(
        'user_id',
        JSON.stringify(action.payload.user_id ?? false)
      );
      localStorage.setItem(
        'order_received',
        JSON.stringify(action.payload.order_received ?? false)
      );
      localStorage.setItem(
        'device_activated',
        JSON.stringify(action.payload.device_activated ?? false)
      );
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload.token ?? null,
        email: action.payload.email ?? null,
        username: action.payload.username ?? null,
        user_initials: action.payload.user_initials ?? null,
        profile_picture: action.payload.profile_picture ?? null,
        user_id: action.payload.user_id ?? null,
        order_received: action.payload.order_received ?? false,
        device_activated: action.payload.device_activated ?? false,
      };

    /**
     * UPDATEPROFILEIMAGE
     *
     * This reducer is responsible for updating the profile picture
     * this is usefull when user update his/her profile,
     * after updating the profile info we will get back the new profile picture URL,
     * then we will update our local version with the updated url from server
     *
     */

    case 'UPDATEPROFILEIMAGE':
      localStorage.setItem('profile_picture', JSON.stringify(action.payload));
      return {
        ...state,
        profile_picture: action.payload,
      };

    case 'UPDATE_DEVICVE_ACTIVATED_STATUS':
      localStorage.setItem('device_activated', JSON.stringify(action.payload));
      return {
        ...state,
        device_activated: action.payload,
      };

    /**
     * LOGOUT
     *
     * This reducer is responsible for logging the user out
     * and clearing the browser localstorage after that
     *
     */

    case 'LOGOUT':
      localStorage.clear();

      return {
        isAuthenticated: false,
        token: null,
        email: null,
        username: null,
        user_initials: '',
        profile_picture: null,
        user_id: null,
        order_received: false,
        device_activated: false,
      };
    default:
      return state;
  }
};

const AuthProvider = ({ children }) => {
  const [authState, authDispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    initialState = { ...initialState };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        authState,
        authDispatch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
