import React, { Fragment, useState, useContext } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from 'context/authContext';

import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndPoints';

import { PrimaryButton } from 'components/primaryButton';

import { ReactComponent as DeviceIlls } from 'assets/images/trackorder/deviceIlls.svg';

export default function ActivateDeviceModal({
  isActivateModalOpen,
  setActivateModalOpen,
}) {
  const [errorMessage, setErrorMessage] = useState('');
  const { authDispatch } = useContext(AuthContext);

  const navigate = useNavigate();

  const defaultValues = {
    device_id: '',
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ defaultValues });

  const orderCancelMutation = useMutation(
    async (data) => {
      const res = await makeAPIRequest.post(apiEndPoints.ACTIVATE_DEVICE, data);
      return res.data;
    },
    {
      onSuccess: () => {
        reset({ device_id: '' });
        authDispatch({
          type: 'UPDATE_DEVICVE_ACTIVATED_STATUS',
          payload: true,
        });
        navigate('/app/people-and-training', { replace: true });
      },
      onError: (err) => {
        setErrorMessage(err?.response?.data?.message);
        setTimeout(() => {
          setErrorMessage('');
        }, 3000);
        reset({ device_id: '' });
        // authDispatch({
        //   type: 'UPDATE_DEVICVE_ACTIVATED_STATUS',
        //   payload: true,
        // });
        // navigate('/app/people-and-training', { replace: true });
      },
    }
  );

  const onSubmit = (data) => {
    orderCancelMutation.mutate(data);
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <small className='text-placeholder text-alert-error pl-1'>
          {errors[name].message}
        </small>
      )
    );
  };

  return (
    <div>
      <Transition appear show={isActivateModalOpen} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-10 overflow-y-auto w-full'
          onClose={() => setActivateModalOpen(false)}
        >
          <Dialog.Overlay />
          <div className='h-full px-4 text-center flex items-center justify-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-black opacity-60' />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <div className='inline-block md:w-3/4 lg:w-1/2 md:h-3/4 p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg'>
                <form onSubmit={handleSubmit(onSubmit)} className='h-full'>
                  <div className='h-full grid content-around pb-6'>
                    <div>
                      <DeviceIlls className='w-80 mx-auto' />
                    </div>
                    <div className='pt-8'>
                      <p className='text-heading-3 text-center font-medium text-primary'>
                        Activate your device
                      </p>
                      <p className='text-gray text-center'>
                        Device ID is displayed on your order package
                      </p>
                    </div>
                    <div className='schedule-demo my-8 w-2/3 xl:w-1/2 mx-auto'>
                      <Controller
                        name='device_id'
                        control={control}
                        rules={{
                          required: 'This field is required',
                        }}
                        render={({ field }) => (
                          <input
                            {...field}
                            className='w-full mt-1 border-primary py-3 border-2 rounded-lg focus:outline-primary px-2'
                          />
                        )}
                      />
                      {getFormErrorMessage('device_id')}
                      {errorMessage && (
                        <p className='text-center pt-1 text-primary font-medium'>
                          {errorMessage}
                        </p>
                      )}
                    </div>
                    <div>
                      <div className='flex justify-center mt-6'>
                        <button>
                          <PrimaryButton>Activate</PrimaryButton>
                        </button>
                      </div>
                      <p className='text-gray2 text-center pt-4 px-5'>
                        Do you have any trouble activating it? No worries,
                        please <span className='text-primary'>contact us</span>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
