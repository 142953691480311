import Loader from 'components/Loader';

const PrimaryButton = ({ children, isLoading }) => {
  if (isLoading) {
    return (
      <div className='bg-primary px-4 max-w-fit min-w-[8rem] flex justify-center py-[6px] rounded text-[15px] text-white cursor-wait bg-opacity-70'>
        <Loader height={23} width={23} />
      </div>
    );
  }
  return (
    <div className='bg-primary px-4 max-w-fit min-w-[8rem] flex justify-center py-[7px] rounded text-[15px] text-white'>
      {children}
    </div>
  );
};

export default PrimaryButton;
