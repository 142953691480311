import React, { useState } from 'react';
import { SearchIcon } from 'components/Icons';
import { useQuery } from 'react-query';
import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndPoints';
import DOMPurify from 'dompurify';

import { ReactComponent as ForwardIcon } from 'assets/Icon/forwardIcon.svg';
import { ReactComponent as BackwardIcon } from 'assets/Icon/backwardIcon.svg';

export default function HowItWorksPage() {
  const [questionSearchValue, setQuestionSearchValue] = useState('');
  const [isAnswerVisible, setAnswerVisible] = useState(false);
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');

  const getHowItWorksQuestions = useQuery(
    ['How-it-works', questionSearchValue],
    async () => {
      const resData = await makeAPIRequest.get(
        `${apiEndPoints.HOW_IT_WORKS}?search=${questionSearchValue}`
      );
      return resData?.data;
    }
  );

  const handleViewAnswer = (data) => {
    setAnswerVisible(true);
    setQuestion(data?.question);
    setAnswer(data?.answer);
  };

  const createMarkup = (data) => {
    return { __html: DOMPurify.sanitize(data) };
  };

  // debounce function
  const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const updateSearchValue = debounce(function (e) {
    setQuestionSearchValue(e.target.value);
  }, 500);

  return (
    <div className='h-full'>
      <div className='flex items-center'>
        <p className='text-heading-4 text-primary font-semibold mt-2'>
          How it works
        </p>
        {isAnswerVisible ? null : (
          <div className='relative w-44 xl:w-52 ml-auto'>
            <input
              type='text'
              className='border border-primary rounded-3xl focus:ring-primary focus:border-primary block w-full  py-1.5 pl-9 text-small bg-[#FEFAF8] placeholder:tracking-wide'
              placeholder='Search'
              onChange={(e) => updateSearchValue(e)}
            />
            <span className='absolute left-3 top-2.5 '>
              <SearchIcon />
            </span>
          </div>
        )}
      </div>
      <div className='mt-4 pr-4 h-full'>
        {isAnswerVisible ? (
          <div className='h-full overflow-hidden'>
            <div className='flex pt-2 items-center'>
              <BackwardIcon
                className='cursor-pointer'
                onClick={() => setAnswerVisible(false)}
              />
              <p className='text-heading-4 font-medium text-font-secondary pl-3'>
                {question}
              </p>
            </div>
            <div
              className='h-3/4 overflow-y-scroll scroller mt-4 pl-10'
              dangerouslySetInnerHTML={createMarkup(answer)}
            ></div>
          </div>
        ) : (
          <>
            {getHowItWorksQuestions?.data?.data?.map((item) => (
              <div
                className='border-b-2 border-D9DBE1 flex items-center'
                key={item?.id}
              >
                <p className='text-font-secondary text-text-primary py-4 w-11/12'>
                  {item?.question}
                </p>
                <ForwardIcon
                  className='ml-auto cursor-pointer w-8'
                  onClick={() => handleViewAnswer(item)}
                />
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
}
