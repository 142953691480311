import { atom, useAtom } from 'jotai';
import { memo, useState, useEffect } from 'react';
import { Fragment, useContext } from 'react';
import { Dialog, Transition } from '@headlessui/react';

import AvatarUpload from 'components/avatar/avatarUpload';
import ChangePasswordModal from 'components/changePasswordModal';
import UserFeedbackModal from 'components/userFeedbackModal';

import { AuthContext } from 'context/authContext';

import { useMutation } from 'react-query';
import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndPoints';

import { ReactComponent as PasswordIlls } from 'assets/images/PasswordIlls.svg';
import { ReactComponent as FeedbackIlls } from 'assets/images/feedbackIlls.svg';

export const personalDetailsSidePanelVisibleAtom = atom(false);

const PersonalDetailsSidePanel = () => {
  const [isModalOpen, setIsModalOpen] = useAtom(
    personalDetailsSidePanelVisibleAtom
  );

  const { authDispatch } = useContext(AuthContext);

  const [selectedFile, setSelectedFile] = useState('');
  const [isChangePasswordModalOpen, setChangePasswordModalOpen] =
    useState(false);
  const [isFeedbackModalOpen, setFeedbackModalOpen] = useState(false);

  const handlePasswordChangeModalOpen = (name) => {
    setIsModalOpen(false);
    setTimeout(() => {
      if (name === 'password') {
        setChangePasswordModalOpen(true);
      } else {
        setFeedbackModalOpen(true);
      }
    }, 200);
  };

  const userProfileUpdate = useMutation(
    async (data) => {
      const res = await makeAPIRequest.put(
        apiEndPoints.UPDATE_USER_PROFILE,
        data
      );
      return res.data;
    },
    {
      onSuccess: (data) => {
        setSelectedFile('');
        authDispatch({
          type: 'UPDATEPROFILEIMAGE',
          payload: data?.data,
        });
      },
    }
  );

  useEffect(() => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append('profile_picture', selectedFile);
      userProfileUpdate.mutate(formData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile]);

  return (
    <>
      <Transition.Root show={isModalOpen} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 overflow-hidden'
          onClose={setIsModalOpen}
        >
          <div className='absolute inset-0 overflow-hidden'>
            <Transition.Child
              as={Fragment}
              enter='ease-in-out duration-500'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in-out duration-500'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='absolute inset-0 bg-black/50 transition-opacity' />
            </Transition.Child>
            <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10'>
              <Transition.Child
                as={Fragment}
                enter='transform transition ease-in-out duration-500 sm:duration-700'
                enterFrom='translate-x-full'
                enterTo='translate-x-0'
                leave='transform transition ease-in-out duration-500 sm:duration-700'
                leaveFrom='translate-x-0'
                leaveTo='translate-x-full'
              >
                <div className='pointer-events-auto relative w-screen max-w-md'>
                  <div className='flex h-full flex-col bg-white py-6 shadow-xl'>
                    <div className='relative mt-6 flex-1 px-4 sm:px-6'>
                      {/* Replace with your content */}
                      <div>
                        <div className='relative w-24 mb-8 ml-2'>
                          <AvatarUpload
                            imgUrl={
                              JSON.parse(
                                localStorage.getItem('profile_picture')
                              )
                                ? JSON.parse(
                                    localStorage.getItem('profile_picture')
                                  )
                                : 'https://api-atsv2.0.aptagrim.com/media_ats/Static_images/noImage.svg'
                            }
                            setSelectedFile={setSelectedFile}
                          />
                        </div>
                        <div className='px-2'>
                          <div className='pb-4'>
                            <label className='pl-1 text-font-tertiary'>
                              Full Name
                            </label>
                            <input
                              type='text'
                              readOnly
                              disabled={true}
                              value={JSON.parse(
                                localStorage.getItem('username')
                              )}
                              className='w-full base-input mt-1.5 opacity-60'
                            />
                          </div>
                          <div>
                            <label className='pl-1 text-font-tertiary'>
                              Email
                            </label>
                            <input
                              type='text'
                              readOnly
                              disabled={true}
                              value={JSON.parse(localStorage.getItem('email'))}
                              className='w-full base-input mt-1.5 opacity-60'
                            />
                          </div>
                        </div>
                        <hr className='mt-10 mb-8 border-2 border-primary bg-primary opacity-30' />
                        <div>
                          <div
                            className='flex select-none cursor-pointer pl-1'
                            onClick={() =>
                              handlePasswordChangeModalOpen('password')
                            }
                          >
                            <PasswordIlls className='w-4 mr-3' />
                            <p className='text-font-tertiary text-text-primary'>
                              Change Password
                            </p>
                          </div>
                          <div
                            className='flex mt-6 select-none cursor-pointer pl-1'
                            onClick={() =>
                              handlePasswordChangeModalOpen('feedback')
                            }
                          >
                            <FeedbackIlls className='w-5 mr-3' />
                            <p className='text-font-tertiary text-text-primary'>
                              Send Feedback
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* /End replace */}
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <ChangePasswordModal
        isChangePasswordModalOpen={isChangePasswordModalOpen}
        setChangePasswordModalOpen={setChangePasswordModalOpen}
      />
      <UserFeedbackModal
        isFeedbackModalOpen={isFeedbackModalOpen}
        setFeedbackModalOpen={setFeedbackModalOpen}
      />
    </>
  );
};

export default memo(PersonalDetailsSidePanel);
