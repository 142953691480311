import { Link } from "react-router-dom";

import { PrimaryButton } from "components/primaryButton";
import { SecondaryButton } from "components/secondaryButton";

import getInTouchImage from "../../../assets/images/landingpage/getInTouch.png";
import { ReactComponent as PlayStoreIcon } from "../../../assets/images/landingpage/playstoreIcon.svg";
import { ReactComponent as AppleStoreIcon } from "../../../assets/images/landingpage/appleStoreIcon.svg";

export default function GetInTouch() {
  return (
    <div>
      <div className="flex h-44 md:h-80 lg:h-96 bg-primary mt-4 md:mt-6">
        <div className="w-1/2 flex items-center pl-3 pr-1 md:px-8 lg:px-16 text-white">
          <div>
            <p className="text-text-primary md:text-heading-3 lg:text-heading-2 font-semibold leading-tight md:pb-4 xl:pb-2">
              Download the app now
            </p>
            <p className="text-placeholder md:text-text-primary">
              The app integrates seamlessly with our AI-powered smart glasses to
              simplify everyday life.
            </p>
            <div className="flex space-x-1.5 md:space-x-4 pt-2 md:pt-8">
              <AppleStoreIcon className="w-20 md:w-32" />
              <PlayStoreIcon className="w-20 md:w-32" />
            </div>
          </div>
        </div>
        <div className="w-1/2 pr-2 md:pr-4 xl:pr-0">
          <img src={getInTouchImage} alt="" className="h-44 md:h-80 lg:h-96" />
        </div>
      </div>
      {/* <div className='my-10 md:my-20 lg:my-32 md:flex md:px-10'>
        <p className='md:w-1/2 text-heading-4 md:text-heading-3 lg:text-heading-2 font-medium text-515151 text-center md:text-left xl:text-center'>
          Get in touch for Apta
          <span className='font-semibold text-primary'>I</span> alerts and
          updates
        </p>
        <div className='md:w-1/2 flex justify-center items-center space-x-4 md:space-x-8 pt-5 md:pt-0'>
          <Link to='/schedule-demo'>
            <SecondaryButton>
              {' '}
              <p className='leading-7 whitespace-nowrap'>Schedule a demo</p>
            </SecondaryButton>
          </Link>
          <Link to='/products'>
            <button>
              <PrimaryButton>
                {' '}
                <p className='leading-7'>Order now</p>
              </PrimaryButton>
            </button>
          </Link>
        </div>
      </div> */}
    </div>
  );
}
