import { useAtom } from 'jotai';
import { useLocation, useNavigate } from 'react-router-dom';

import { logoutModalVisibleAtom } from 'pages/auth/components/LogoutModal';

import {
  PeopleIcon,
  MyDeviceIcon,
  HowItWorksIcon,
  FaqIcon,
  SupportIcon,
  LogoutIcon,
} from './icons';
import { ReactComponent as AptaILogo } from 'assets/aptai-logo.svg';

const Sidebar = () => {
  const [, setIsmodalVisible] = useAtom(logoutModalVisibleAtom);
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className='bg-white w-[22%] xl:w-[17%] h-screen pl-6 pt-4'>
      <AptaILogo />

      <ul className=' mt-12 mb-4 text-[15px]'>
        <li className='flex space-x-3 font-semibold cursor-pointer'>
          <p className='py-3 w-9'>{PeopleIcon}</p>
          <p
            className={`w-full py-3 whitespace-nowrap cursor-pointer ${
              location.pathname.includes('people-and-training')
                ? 'bg-primary px-2 pl-3 rounded-tl-2xl rounded-bl-2xl text-white'
                : 'text-font-placeholder'
            }`}
            onClick={() => navigate('/app/people-and-training')}
          >
            People and training
          </p>
        </li>
        <li className='flex space-x-3 font-semibold my-4 cursor-pointer'>
          <p className='py-3 w-9'>{MyDeviceIcon}</p>
          <p
            className={`w-full py-3 whitespace-nowrap cursor-pointer ${
              location.pathname.includes('my-device')
                ? 'bg-primary px-2 pl-3 rounded-tl-2xl rounded-bl-2xl text-white'
                : 'text-font-placeholder'
            }`}
            onClick={() => navigate('/app/my-device')}
          >
            My Device
          </p>
        </li>
      </ul>
      <div className=' h-px bg-primary/50 w-11/12 mx-auto my-6'></div>
      <ul className=' mb-4 text-[15px]'>
        <li className='flex space-x-3 font-semibold my-2 cursor-pointer'>
          <p className='py-3 w-9'>{HowItWorksIcon}</p>
          <p className='w-full py-3  text-font-placeholder'>How it works</p>
        </li>
        <li className='flex space-x-3 font-semibold my-4 cursor-pointer'>
          <p className=' py-3 w-9'>{FaqIcon}</p>
          <p className='w-full py-3  text-font-placeholder'>FAQ</p>
        </li>
        <li className='flex space-x-3 font-semibold my-2 cursor-pointer'>
          <p className=' py-3 w-9'>{SupportIcon}</p>
          <p className='w-full py-3  text-font-placeholder'>Support</p>
        </li>
      </ul>
      <div className='h-px bg-primary/20 w-11/12 mx-auto my-6'></div>

      <ul className='text-[15px]'>
        <li
          className='flex space-x-3 font-semibold my-2 cursor-pointer'
          onClick={() => setIsmodalVisible(true)}
        >
          <p className=' py-3 w-9'>{LogoutIcon}</p>
          <p className='w-full py-3  text-font-placeholder'>Logout</p>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
