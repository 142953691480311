import { memo } from 'react';

import PeopleCardActionDropdown from './peopleCardActionDropdown';

const PeopleCard = ({ isLoading, personDetails }) => {
  if (isLoading) {
    return (
      <div className='bg-[#FDF5F0] p-4 rounded-md animate-pulse'>
        <div className='w-full'>
          <div className='relative w-full h-32  bg-font-placeholder/10 rounded'></div>
          <div className=' w-1/2 h-5 my-4 bg-font-placeholder/20 rounded'></div>
          <div className=' w-4/5 h-4 my-4 bg-font-placeholder/10 rounded'></div>
          <div className=' w-3/5 h-4 bg-font-placeholder/10 rounded'></div>
        </div>
      </div>
    );
  }
  return (
    <div className='bg-[#FDF5F0]  p-4 rounded-md'>
      <div className='w-full'>
        <div className='relative w-full'>
          <img
            src={personDetails.profile_picture}
            alt={personDetails.name}
            className='mb-6 w-full h-32 2xl:h-36'
          />
          <div className=' absolute top-2 right-2'>
            <PeopleCardActionDropdown currentUser={personDetails} />
          </div>
        </div>
        <p className='text-font-primary text-text-primary font-medium'>
          {personDetails.name}
        </p>
        <p className='text-font-secondary my-1'>
          {personDetails.contact_number}
        </p>
        <p className=' text-small text-font-placeholder'>
          {personDetails.relation_with_user}
        </p>
      </div>
    </div>
  );
};

export default memo(PeopleCard);
