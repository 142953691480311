import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';

import { PrimaryButton } from 'components/primaryButton';

import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndPoints';

import scheduleDemoBg from 'assets/images/scheduleDemo/scheduleDemoBg.png';

import 'react-datetime/css/react-datetime.css';

export default function ContactUs() {
  const [error, setErrorMessage] = useState();
  const [disableSubmit, setDisableButton] = useState(false);
  const navigate = useNavigate();

  const defaultValues = {
    name: '',
    country: '',
    company_name: '',
    email: '',
    contact_number: '',
    source: '',
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
  } = useForm({ defaultValues });

  const getCountriesData = useQuery(['getCountriesList'], async () => {
    const resData = await makeAPIRequest.get(`${apiEndPoints.COUNTRIES_LIST}`);
    return resData?.data;
  });

  // checking the user existence
  const checkExistingEmail = (val) => {
    makeAPIRequest
      .get(
        `${apiEndPoints.CHECK_USER_EXISTENCE}?email=${val}&scheduled_client=False`
      )
      .then((res) => {
        if (res?.data?.message === 'Client Found') {
          setError('email', {
            type: 'manual',
            message: 'User with same email already requested',
          });
          setDisableButton(true);
        } else {
          setDisableButton(false);
        }
      });
  };

  const contactUsMutation = useMutation(
    async (data) => {
      const res = await makeAPIRequest.post(apiEndPoints.CONTACT_US, data);
      return res.data;
    },
    {
      onSuccess: () => {
        reset({
          name: '',
          country: '',
          company_name: '',
          email: '',
          contact_number: '',
          source: '',
        });
        navigate('/success');
      },
      onError: (err) => {
        setErrorMessage(err.response.data.message);
        setTimeout(() => {
          setErrorMessage();
        }, 3000);
      },
    }
  );

  const onSubmit = (data) => {
    contactUsMutation.mutate(data);
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <small className='text-placeholder text-alert-error pl-1'>
          {errors[name].message}
        </small>
      )
    );
  };

  return (
    <div
      className='h-screen -mt-20  pt-20 overflow-y-scroll pb-4 scroller'
      style={{
        background: `url(${scheduleDemoBg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 85%',
        backgroundPosition: 'bottom',
      }}
    >
      <p className='text-primary text-heading-2 font-medium text-center pt-8 pb-6'>
        Contact us
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='shadow-lg border border-font-placeholder border-opacity-20 w-11/12 md:w-3/4 lg:w-2/3 xl:w-1/2 mx-auto rounded-lg bg-white pt-8 px-8 md:px-10 pb-6'>
          <div className='flex flex-wrap schedule-demo'>
            <div className='w-full md:w-1/2 md:pr-4 pb-4'>
              <label htmlFor='name' className='pl-1 text-828282'>
                Full name
              </label>
              <Controller
                name='name'
                control={control}
                rules={{ required: 'This field is required' }}
                render={({ field }) => (
                  <input {...field} className='mt-0.5 base-input' type='text' />
                )}
              />
              {getFormErrorMessage('name')}
            </div>
            <div className='w-full md:w-1/2 md:pl-4 pb-4 md:pb-0'>
              <label htmlFor='country' className='pl-1 text-828282'>
                Country
              </label>
              <Controller
                name='country'
                control={control}
                rules={{ required: 'This field is required' }}
                render={({ field }) => (
                  <select {...field} className='mt-0.5 base-select'>
                    <option value=''>Select country</option>
                    {getCountriesData?.data?.data?.map((country) => (
                      <option
                        key={country.common_name}
                        value={country.common_name}
                      >
                        {country.common_name}
                      </option>
                    ))}
                  </select>
                )}
              />
              {getFormErrorMessage('country')}
            </div>
            <div className='w-full md:w-1/2 md:pr-4 pb-4 md:pb-4'>
              <label htmlFor='email' className='pl-1 text-828282'>
                Email
              </label>
              <Controller
                name='email'
                control={control}
                rules={{
                  required: 'This field is required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'Invalid email address.',
                  },
                }}
                render={({ field }) => (
                  <input
                    {...field}
                    className='mt-0.5 base-input'
                    type='text'
                    onBlur={(e) => checkExistingEmail(e.target.value)}
                  />
                )}
              />
              {getFormErrorMessage('email')}
            </div>
            <div className='w-full md:w-1/2 md:pl-4'>
              <label htmlFor='contact_number' className='pl-1 text-828282'>
                Contact
              </label>
              <Controller
                name='contact_number'
                control={control}
                rules={{
                  required: 'This field is required',
                  pattern: {
                    value: /^[0-9]*$/i,
                    message: 'Only numbers allowed.',
                  },
                }}
                render={({ field }) => (
                  <input {...field} className='mt-0.5 base-input' type='text' />
                )}
              />
              {getFormErrorMessage('contact_number')}
            </div>
            <div className='w-full contact-page'>
              <label htmlFor='source' className='pl-1 text-828282'>
                Message
              </label>
              <Controller
                name='source'
                control={control}
                rules={{
                  required: 'This field is required',
                }}
                render={({ field }) => (
                  <textarea
                    {...field}
                    className='mt-0.5 base-textarea'
                    rows={4}
                  />
                )}
              />
              {getFormErrorMessage('source')}
            </div>
          </div>
          <div className='flex justify-center pt-6'>
            <button className='w-32' disabled={disableSubmit}>
              <PrimaryButton isLoading={contactUsMutation?.isLoading}>
                Submit
              </PrimaryButton>
            </button>
          </div>
          {error && (
            <p className='text-xs text-center pt-2 text-alert-error'>{error}</p>
          )}
        </div>
      </form>
    </div>
  );
}
