import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import PrimaryButton from './primaryButton/primaryButton';
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from 'react-query';
import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndPoints';

import { SuccessToast } from 'services/toasterService';

import { ReactComponent as CancelIcon } from 'assets/images/trackorder/cancelIcon.svg';

export default function ChangePasswordModal({
  isChangePasswordModalOpen,
  setChangePasswordModalOpen,
}) {
  const [errorMessage, setErrorMessage] = useState('');

  function closeModal() {
    setChangePasswordModalOpen(false);
  }

  const defaultValues = {
    new_password: '',
    old_password: '',
    confirm_password: '',
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm({ defaultValues });

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <small className='text-placeholder text-alert-error pl-1'>
          {errors[name].message}
        </small>
      )
    );
  };

  const changePasswordMutation = useMutation(
    async (data) => {
      const res = await makeAPIRequest.post(apiEndPoints.CHANGE_PASSWORD, data);
      return res?.data;
    },
    {
      onSuccess: (data) => {
        SuccessToast({ text: data.message });
        setChangePasswordModalOpen(false);
        reset();
      },
      onError: (err) => {
        setErrorMessage(err.response.data.message);
        setTimeout(() => {
          setErrorMessage('');
        }, 3000);
      },
    }
  );

  const onSubmit = (data) => {
    const newData = {
      old_password: data.old_password,
      new_password: data.new_password,
    };
    changePasswordMutation.mutate(newData);
  };

  return (
    <>
      <Transition appear show={isChangePasswordModalOpen} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-10 overflow-y-auto'
          onClose={closeModal}
        >
          <div className='min-h-screen px-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-black opacity-50' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className='inline-block h-screen align-middle'
              aria-hidden='true'
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <div className='inline-block w-1/2 max-w-xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-xl'>
                <div>
                  <CancelIcon
                    className='ml-auto cursor-pointer'
                    onClick={() => closeModal()}
                  />
                </div>
                <p className='text-heading-4 text-primary font-semibold text-center pb-8'>
                  Change Password
                </p>
                <div className='px-12 pb-4'>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div>
                      <label className='text-font-placeholder pl-1'>
                        Old Password
                      </label>
                      <Controller
                        name='old_password'
                        control={control}
                        rules={{ required: 'This field is required' }}
                        render={({ field }) => (
                          <input
                            {...field}
                            type='password'
                            className='base-input mt-1'
                          />
                        )}
                      />
                      {getFormErrorMessage('old_password')}
                    </div>
                    <div className='mt-4'>
                      <label className='text-font-placeholder pl-1'>
                        New Password
                      </label>
                      <Controller
                        name='new_password'
                        control={control}
                        rules={{ required: 'This field is required' }}
                        render={({ field }) => (
                          <input
                            {...field}
                            type='password'
                            className='base-input mt-1'
                          />
                        )}
                      />
                      {getFormErrorMessage('new_password')}
                    </div>
                    <div className='mt-4 mb-10'>
                      <label className='text-font-placeholder pl-1'>
                        Confirm Password
                      </label>
                      <Controller
                        name='confirm_password'
                        control={control}
                        rules={{
                          required: 'This field is required',
                          validate: (value) =>
                            value === watch('new_password') ||
                            'The passwords do not match',
                        }}
                        render={({ field }) => (
                          <input
                            {...field}
                            type='password'
                            className='base-input mt-1'
                          />
                        )}
                      />
                      {getFormErrorMessage('confirm_password')}
                    </div>
                    <div className='flex justify-center mb-1'>
                      <button>
                        <PrimaryButton
                          isLoading={changePasswordMutation?.isLoading}
                        >
                          Save
                        </PrimaryButton>
                      </button>
                    </div>
                    {errorMessage && (
                      <p className='text-alert-error text-small text-center'>
                        {errorMessage}
                      </p>
                    )}
                  </form>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
