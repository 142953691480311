import { atom, useAtom } from 'jotai';
import { Fragment, memo } from 'react';
import { Transition } from '@headlessui/react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import cx from 'classnames';

import { PrimaryButton } from 'components/primaryButton';
import { SecondaryButton } from 'components/secondaryButton';

import { ReactComponent as PersonIlls } from 'assets/images/noPeopleIlls.svg';

import { currentSelectedPersonAtom } from './peopleCardActionDropdown';
import useDeletePeopleMutation from '../hooks/useDeletePeopleMutation';

export const peopleDeleteModalVisibleAtom = atom(false);

const DeletePeopleModal = () => {
  const [isModalOpen, setIsModalOpen] = useAtom(peopleDeleteModalVisibleAtom);

  const [currentSelectedUser, setCurrentSelectedUser] = useAtom(
    currentSelectedPersonAtom
  );

  const deletePeopleMutation = useDeletePeopleMutation();

  return (
    <DialogPrimitive.Root
      open={isModalOpen}
      onOpenChange={(e) => {
        setIsModalOpen(e);
        if (e === false) {
          setCurrentSelectedUser({});
        }
      }}
    >
      <Transition.Root show={isModalOpen}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <DialogPrimitive.Overlay
            forceMount
            className='fixed inset-0 z-20 bg-black/50'
          />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0 scale-95'
          enterTo='opacity-100 scale-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100 scale-100'
          leaveTo='opacity-0 scale-95'
        >
          <DialogPrimitive.Content
            forceMount
            className={cx(
              'fixed z-50',
              'w-[95vw] max-w-xl rounded-lg p-4 px-8 md:w-full',
              'top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]',
              'bg-white',
              'focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75'
            )}
          >
            <DialogPrimitive.Title className='text-heading-3 text-primary font-medium text-center'>
              Delete confirmation
            </DialogPrimitive.Title>
            <section>
              <div>
                <div className=' my-10 py-4 flex flex-col items-center space-y-6'>
                  <PersonIlls />
                  <p className=' text-text-primary text-font-secondary text-center'>
                    Are you sure , want to delete{' '}
                    <span className='font-medium'>
                      {currentSelectedUser.name}
                    </span>{' '}
                    ?
                  </p>
                </div>

                <div className='flex justify-center my-8 space-x-8'>
                  <button
                    autoFocus
                    className='focus:outline focus:outline-offset-2 focus:outline-2 rounded outline-primary'
                    onClick={() =>
                      deletePeopleMutation.mutate({
                        id: currentSelectedUser.id,
                      })
                    }
                  >
                    <PrimaryButton isLoading={deletePeopleMutation.isLoading}>
                      <p className='select-none'>Yes</p>
                    </PrimaryButton>
                  </button>
                  <button
                    className='focus:outline focus:outline-offset-2 focus:outline-2 rounded outline-primary'
                    onClick={() => setIsModalOpen(false)}
                  >
                    <SecondaryButton>
                      <p className='select-none'>No</p>
                    </SecondaryButton>
                  </button>
                </div>
              </div>
            </section>

            <DialogPrimitive.Close
              className={cx(
                'absolute top-5 right-3.5 inline-flex items-center justify-center rounded-full',
                'focus:outline-none focus:ring-offset-1 focus-visible:ring focus-visible:ring-primary/80 focus-visible:ring-opacity-75'
              )}
            >
              <svg
                width='24'
                height='24'
                viewBox='0 0 30 33'
                fill='none'
                xmlns='https://www.w3.org/2000/svg'
              >
                <path
                  d='M28.75 16.2686C28.75 24.3982 22.5298 30.8692 15 30.8692C7.47015 30.8692 1.25 24.3982 1.25 16.2686C1.25 8.13903 7.47015 1.66797 15 1.66797C22.5298 1.66797 28.75 8.13903 28.75 16.2686Z'
                  stroke='#FF4D53'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M19.5 11.5132L10.5 21.0236'
                  stroke='#FF4D53'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M10.5 11.5132L19.5 21.0236'
                  stroke='#FF4D53'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </DialogPrimitive.Close>
          </DialogPrimitive.Content>
        </Transition.Child>
      </Transition.Root>
    </DialogPrimitive.Root>
  );
};

export default memo(DeletePeopleModal);
