import { useContext } from 'react';
import { useAtom } from 'jotai';
import { useMutation, useQueryClient } from 'react-query';

import makeApiRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndPoints';
import { ErrorToast, SuccessToast } from 'services/toasterService';

import { AuthContext } from 'context/authContext';

import { logoutModalVisibleAtom } from '../components/LogoutModal.jsx';

export default function useLogout() {
  const [, setIsmodalVisible] = useAtom(logoutModalVisibleAtom);

  const { authDispatch } = useContext(AuthContext);
  const queryClient = useQueryClient();

  return useMutation(
    async (data) => {
      const res = await makeApiRequest.post(apiEndPoints.LOGOUT, data);
      return res.data;
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries();
        SuccessToast({ text: data.message });
        setIsmodalVisible(false);
        authDispatch({
          type: 'LOGOUT',
        });
      },
      onError: (error) => {
        ErrorToast({ text: error.response.data.message });
      },
    }
  );
}
