import React, { useState } from 'react';
import Countdown from 'react-countdown';

function CountDownNormal({ sendOtpForEmail, userEmail }) {
  const [keyValue, setKeyValue] = useState(0);

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return (
        <p
          className='text-primary font-semibold select-none cursor-pointer'
          onClick={() => {
            sendOtpForEmail(userEmail, true);
            setKeyValue(keyValue + 1);
          }}
        >
          Resend
        </p>
      );
    } else {
      // Render a countdown
      return (
        <p className='text-primary select-none font-semibold'>
          0{minutes}:{seconds.toString().length < 2 ? 0 : ''}
          {seconds}
        </p>
      );
    }
  };

  return (
    <div>
      <Countdown
        date={Date.now() + 179400}
        renderer={renderer}
        key={keyValue}
      />
    </div>
  );
}

export const CountDown = React.memo(CountDownNormal);
