import { useState, useEffect } from 'react';
import { atom, useAtom } from 'jotai';
import { Fragment, memo } from 'react';
import { Transition } from '@headlessui/react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import cx from 'classnames';
import { useForm } from 'react-hook-form';

import { PrimaryButton } from 'components/primaryButton';
import AvatarUpload from 'components/avatar/avatarUpload';

import useAddPeopleMutation from '../hooks/useAddPeopleMutation';
import useEditPeopleMutation from '../hooks/useEditPeopleMutation';

import { currentSelectedPersonAtom } from './peopleCardActionDropdown';

export const peopleAddAndEditModalVisibleAtom = atom(false);
export const peopleModalTypeAtom = atom('Add');

const AddAndEditPeopleModal = () => {
  const [isModalOpen, setIsModalOpen] = useAtom(
    peopleAddAndEditModalVisibleAtom
  );
  const [peopleModalType] = useAtom(peopleModalTypeAtom);
  const [currentSelectedPerson, setCurrentSelectedUser] = useAtom(
    currentSelectedPersonAtom
  );

  const [selectedFile, setSelectedFile] = useState(null);
  const [profilePictureError, setProfilePictureError] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const addPeopleMutation = useAddPeopleMutation(reset);
  const editPeopleMutation = useEditPeopleMutation();

  const onSubmit = (data) => {
    if (!currentSelectedPerson.profile_picture && !selectedFile) {
      setProfilePictureError('Profile picture is required!');
    } else {
      setProfilePictureError('');

      if (peopleModalType === 'Add') {
        addPeopleMutation.mutate({ ...data, profile_picture: selectedFile });
      } else {
        let mutationData = {
          ...data,
          id: currentSelectedPerson.id,
        };

        if (selectedFile) {
          mutationData = { ...mutationData, profile_picture: selectedFile };
        }
        editPeopleMutation.mutate(mutationData);
      }
    }
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <small className='text-placeholder text-alert-error'>
          {errors[name].message}
        </small>
      )
    );
  };

  useEffect(() => {
    reset({
      name: currentSelectedPerson.name || '',
      contact_number: currentSelectedPerson.contact_number || '',
      relation_with_user: currentSelectedPerson.relation_with_user || '',
    });
  }, [
    currentSelectedPerson.name,
    currentSelectedPerson.contact_number,
    currentSelectedPerson.relation_with_user,
    reset,
  ]);

  useEffect(() => {
    if (selectedFile) {
      setProfilePictureError('');
    }
  }, [selectedFile]);

  return (
    <DialogPrimitive.Root
      open={isModalOpen}
      onOpenChange={(e) => {
        setIsModalOpen(e);
        if (e === false) {
          setCurrentSelectedUser({});
          reset();
        }
      }}
    >
      <Transition.Root show={isModalOpen}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <DialogPrimitive.Overlay
            forceMount
            className='fixed inset-0 z-20 bg-black/50'
          />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0 scale-95'
          enterTo='opacity-100 scale-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100 scale-100'
          leaveTo='opacity-0 scale-95'
        >
          <DialogPrimitive.Content
            forceMount
            className={cx(
              'fixed z-50',
              'w-[95vw] max-w-xl rounded-lg p-4 px-8 md:w-full',
              'top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]',
              'bg-white',
              'focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75'
            )}
          >
            <DialogPrimitive.Title className='text-heading-3 text-primary font-medium text-center'>
              {peopleModalType} People
            </DialogPrimitive.Title>

            <form onSubmit={handleSubmit(onSubmit)} className='mt-8'>
              <div className='mb-4'>
                <div className=' relative mx-auto  w-max'>
                  <AvatarUpload
                    imgUrl={
                      currentSelectedPerson.profile_picture ||
                      'https://api-atsv2.0.aptagrim.com/media_ats/Static_images/noImage.svg'
                    }
                    setSelectedFile={setSelectedFile}
                  />
                </div>
                {profilePictureError && (
                  <div className=' w-full flex justify-center mt-2'>
                    <small className='text-placeholder text-alert-error text-center'>
                      {profilePictureError}
                    </small>
                  </div>
                )}
              </div>

              <label
                htmlFor='name'
                className='block text-font-placeholder text-small mb-1'
              >
                Full name
              </label>
              <input
                type='text'
                name='name'
                className='base-input select-auto'
                {...register('name', {
                  required: 'This is a required field!',
                })}
              />

              {getFormErrorMessage('name')}

              <label
                htmlFor='relation_with_user'
                className='block  text-font-placeholder text-small mb-1 mt-6'
              >
                Relationship with the device user
              </label>
              <input
                type='text'
                name='relation_with_user'
                className='base-input '
                {...register('relation_with_user', {
                  required: 'This is a required field!',
                })}
              />

              {getFormErrorMessage('relation_with_user')}

              <label
                htmlFor='contact_number'
                className='block text-small text-font-placeholder mt-6 mb-1'
              >
                Contact number
              </label>
              <input
                type='text'
                name='contact_number'
                className='base-input'
                {...register('contact_number', {
                  required: 'This is a required field!',
                  pattern: {
                    value: /^[0-9]+$/,
                    message: 'Please enter a number',
                  },
                })}
              />
              {getFormErrorMessage('contact_number')}

              <div className='flex justify-center my-8'>
                <button
                  autoFocus
                  className='focus:outline focus:outline-offset-2 focus:outline-2 rounded outline-primary'
                >
                  <PrimaryButton
                    isLoading={
                      addPeopleMutation.isLoading ||
                      editPeopleMutation.isLoading
                    }
                  >
                    <p>Save</p>
                  </PrimaryButton>
                </button>
              </div>
            </form>

            <DialogPrimitive.Close
              className={cx(
                'absolute top-5 right-3.5 inline-flex items-center justify-center rounded-full',
                'focus:outline-none focus:ring-offset-1 focus-visible:ring focus-visible:ring-primary/80 focus-visible:ring-opacity-75'
              )}
            >
              <svg
                width='24'
                height='24'
                viewBox='0 0 30 33'
                fill='none'
                xmlns='https://www.w3.org/2000/svg'
              >
                <path
                  d='M28.75 16.2686C28.75 24.3982 22.5298 30.8692 15 30.8692C7.47015 30.8692 1.25 24.3982 1.25 16.2686C1.25 8.13903 7.47015 1.66797 15 1.66797C22.5298 1.66797 28.75 8.13903 28.75 16.2686Z'
                  stroke='#FF4D53'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M19.5 11.5132L10.5 21.0236'
                  stroke='#FF4D53'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M10.5 11.5132L19.5 21.0236'
                  stroke='#FF4D53'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </DialogPrimitive.Close>
          </DialogPrimitive.Content>
        </Transition.Child>
      </Transition.Root>
    </DialogPrimitive.Root>
  );
};

export default memo(AddAndEditPeopleModal);
