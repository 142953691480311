import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useQuery } from "react-query";
import makeAPIRequest from "../../../services/makeApiRequest";
import apiEndPoints from "../../../services/apiEndPoints";
import ReactPlayer from "react-player";

import happyUsersBg from "../../../assets/images/landingpage/happyUsersBg.png";
import { ReactComponent as LandingIcons } from "../../../assets/images/landingpage/landingIcons.svg";
import { ReactComponent as QuotationsIcon } from "../../../assets/images/landingpage/quotationsIcon.svg";
import activeslickdot from "../../../assets/images/landingpage/activeslickdot.png";

export default function HappyUsers() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isAutoScrollActive, setAutoScrollActive] = useState(true);
  const [usersCount, setUsersCount] = useState(1);

  const settings = {
    // custom slick dots
    customPaging: function (i) {
      if (i === currentSlide) {
        return (
          <div className="flex items-end">
            <img src={activeslickdot} alt=" " className="h-1 rounded-full" />
          </div>
        );
      } else {
        return <p className="text-2xl font-extrabold text-C1C1C1">.</p>;
      }
    },
    dotsClass: "slick-dots slick-thumb",
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow:
      window.innerWidth < 470 || usersCount === 1
        ? 1
        : window.innerWidth < 770 || usersCount === 2
        ? 2
        : 3,
    // slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: isAutoScrollActive,
    arrows: false,
    beforeChange: (prev, next) => {
      setCurrentSlide(next);
    },
  };

  // get users data
  // const getUsersData = useQuery(
  //   ['getHappyUsersData'],
  //   async () => {
  //     const resData = await makeAPIRequest.get(
  //       `${apiEndPoints.USERS_TESTIMONIALS}?is_active=True`
  //     );
  //     return resData?.data;
  //   },
  //   {
  //     onSuccess: (data) => {
  //       setUsersCount(data?.data?.length);
  //     },
  //   }
  // );

  const getUsersData = {
    data: {
      data: [
        {
          id: 1,
          image:
            "https://img.freepik.com/free-photo/colorful-design-with-spiral-design_188544-9588.jpg",
          video:
            "https://www.youtube.com/embed/k5ZJQ4lIGGI?autoplay=0&mute=0&controls=1&origin=http%3A%2F%2Flocalhost%3A3000&playsinline=1&showinfo=0&rel=0&iv_load_policy=3&modestbranding=1&enablejsapi=1&widgetid=1",
          message: "test message",
          name: "test name",
        },
      ],
    },
  };

  return (
    <div
      className="relative mt-2 lg:px-8 pb-32"
      style={{
        background: `url(${happyUsersBg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition: "cover",
      }}
    >
      <div className="absolute top-0 ">
        <LandingIcons className="w-36 md:w-52" />
      </div>
      {/* <p className="text-heading-4 md:text-heading-3 lg:text-heading-2 font-semibold text-center pt-24 lg:pt-28 pb-8 md:pb-12">
        Meet our happy users
      </p>
      <div
        className={`w-11/12  mx-auto ${
          usersCount > 1 ? "w-full xl:w-11/12" : "md:w-2/3 lg:w-1/2 xl:w-2/5"
        }`}
      >
        <Slider {...settings}>
          {getUsersData?.data?.data?.map((user) => (
            <div className="px-6" key={user?.id}>
              <div className="md:text-text-primary text-828282">
                <div className="w-full bg-white flex flex-wrap justify-center shadow-md">
                  <QuotationsIcon className="my-6 w-10" />
                  {user?.image ? (
                    <div>
                      <img src={user?.image} alt="" className="h-60 w-full" />
                    </div>
                  ) : (
                    <div className="w-full news-player">
                      <ReactPlayer
                        url={user?.video}
                        width="auto"
                        height="240px"
                        onPlay={() => setAutoScrollActive(false)}
                        onPause={() => setAutoScrollActive(true)}
                        onEnded={() => setAutoScrollActive(true)}
                      />
                    </div>
                  )}
                </div>
                <p className="pt-8">{user?.message}</p>
                <p className="text-primary font-medium text-heading-4 pt-5 md:pt-6 lg:pt-28 xl:pt-4 mt-2 xl:mt-0">
                  {user?.name}
                </p>
              </div>
            </div>
          ))}
        </Slider>
      </div> */}
    </div>
  );
}
