import { useAtom } from 'jotai';
import { useEffect, useContext } from 'react';

import { AuthContext } from 'context/authContext';
import { Outlet, useNavigate } from 'react-router-dom';

import { Header } from '../components/header';
import { Sidebar } from '../components/sidebar';
import Avatar from '../components/avatar/avatar';

import LogoutModal from 'pages/auth/components/LogoutModal';
import PersonalDetailsSidePanel from 'components/PersonalDetailsSidePanel';

import { personalDetailsSidePanelVisibleAtom } from 'components/PersonalDetailsSidePanel';

const AppLayout = () => {
  const navigate = useNavigate();
  const { authState } = useContext(AuthContext);

  const [, setIsSidePanelOpen] = useAtom(personalDetailsSidePanelVisibleAtom);

  useEffect(() => {
    if (!authState.isAuthenticated) {
      navigate('/auth/login', { replace: true });
    }
  }, [navigate, authState.isAuthenticated]);

  if (authState.device_activated) {
    return (
      <>
        <main className='w-full h-screen admin-container max-h-screen overflow-y-hidden'>
          <div className='flex w-full justify-between'>
            <Sidebar />
            <div className='w-[78%] xl:w-[83%]'>
              <div className=' flex space-x-2 justify-end p-4 items-center'>
                <div className=' text-right'>
                  <p className='text-font-primary font-medium'>
                    Hello {authState?.username}
                  </p>
                  {/* <p className='text-[12px] text-font-placeholder'>
                    Device synced with iphone 13 pro
                  </p> */}
                </div>
                <div
                  className='select-none cursor-pointer'
                  onClick={() => setIsSidePanelOpen(true)}
                >
                  <Avatar />
                </div>
              </div>
              <section className=' px-4 mt-3'>
                <div className=' bg-white w-full h-[85vh] max-h-[85vh] rounded-md px-6 py-8'>
                  <Outlet />
                </div>
              </section>
            </div>
          </div>
        </main>
        <LogoutModal />
        <PersonalDetailsSidePanel />
      </>
    );
  }

  return (
    <>
      <main className='w-full h-screen admin-container max-h-screen overflow-y-hidden'>
        <Header />
        <Outlet />
      </main>
      <LogoutModal />
    </>
  );
};

export default AppLayout;
