import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { PrimaryButton } from 'components/primaryButton';

import useLogin from '../hooks/useLogin';

import { EyeIcon, EyeCloseIcon } from 'components/Icons';

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const loginMutation = useLogin();
  const onSubmit = (data) => {
    loginMutation.mutate({ ...data, re_login: true, admin: false });
  };
  const navigate = useNavigate();

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <small className='text-placeholder text-alert-error'>
          {errors[name].message}
        </small>
      )
    );
  };

  return (
    <div className='w-full lg:w-11/12 max-w-md mx-auto mt-10 lg:mt-36 tall:my-28'>
      <h1 className=' text-heading-3 text-primary font-medium text-center my-3'>
        Hello, welcome back
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label
          htmlFor='email'
          className='block  text-font-placeholder text-small mb-1'
        >
          Email
        </label>
        <input
          type='text'
          name='username_or_number'
          className='base-input'
          {...register('username_or_number', {
            required: 'This is a required field!',
          })}
        />
        {getFormErrorMessage('username_or_number')}

        <label
          htmlFor='email'
          className='block text-small text-font-placeholder mt-4 mb-1'
        >
          Password
        </label>
        <div className='w-full relative'>
          <input
            type={showPassword ? 'text' : 'password'}
            name='password'
            autoComplete='off'
            className=' pr-12 base-input'
            {...register('password', { required: 'This is a required field!' })}
          />
          <button
            className='cursor-pointer absolute right-2 top-3 outline-none'
            onClick={() => setShowPassword(!showPassword)}
            type='button'
          >
            {!showPassword ? EyeIcon : EyeCloseIcon}
          </button>
        </div>
        {getFormErrorMessage('password')}

        <p
          className=' text-[15px] text-font-tertiary text-right my-2 cursor-pointer select-none'
          onClick={() => navigate('/auth/forgot-password')}
        >
          Forgot Password ?
        </p>
        <div className='flex justify-center my-8'>
          <button className='focus:outline focus:outline-offset-2 focus:outline-2 rounded outline-primary'>
            <PrimaryButton isLoading={loginMutation.isLoading}>
              <p className='select-none'>Login</p>
            </PrimaryButton>
          </button>
        </div>
      </form>
    </div>
  );
};

export default Login;
