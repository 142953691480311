import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { TrackOrder } from '../pages/trackOrder';
import AuthPage from '../pages/auth/AuthPage';
import AppLayout from '../pages/AppLayout';

import LandingLayout from 'pages/LandingLayout';

import LandingPage from 'pages/landing';
import CheckoutPage from 'pages/checkoutPage';
import ContactUs from 'pages/contactUs';
import ScheduleDemo from 'pages/demo';
import ProductsList from 'pages/productsList';
import PaymentSuccessPage from 'pages/checkoutPage/PaymentSuccess';
import DemoSuccess from 'pages/demo/demoRequestSuccess';
import { PeopleAndTraining } from 'pages/peopleAndTraining';
import { MyDevice } from 'pages/myDevice';
import { HowItWorks } from 'pages/howItWorks';

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<LandingLayout />}>
          <Route index element={<LandingPage />} />
          <Route path='checkout/:id' element={<CheckoutPage />} />
          <Route path='schedule-demo' element={<ScheduleDemo />} />
          <Route path='contact' element={<ContactUs />} />
          <Route path='payment-success' element={<PaymentSuccessPage />} />
          <Route path='success' element={<DemoSuccess />} />
          <Route path='products' element={<ProductsList />} />
        </Route>
        <Route path='/auth/*' element={<AuthPage />} />
        <Route path='/app' element={<AppLayout />}>
          <Route path='trackorder' element={<TrackOrder />} />
          <Route path='people-and-training' element={<PeopleAndTraining />} />
          <Route path='my-device' element={<MyDevice />} />
          <Route path='how-it-works' element={<HowItWorks />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
