import './svgloader.styles.scss';

// See: https://codepen.io/supah/pen/BjYLdW
const Loader = ({ width = 20, height = 20, color = '#ffffff' }) => {
  return (
    <div className='loader-wrapper'>
      <svg
        className='spinner'
        width={width}
        height={height}
        viewBox='0 0 50 50'
      >
        <circle
          className='path'
          cx='25'
          cy='25'
          r='20'
          fill='none'
          strokeWidth='6'
          stroke={color}
        ></circle>
      </svg>
    </div>
  );
};

export default Loader;
