import { Suspense, useContext, useEffect } from 'react';
import { lazy } from '@loadable/component';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';

import Login from './components/Login';

import { AuthContext } from 'context/authContext';

import { ReactComponent as AptaILogo } from 'assets/aptai-logo.svg';
import bgTablet from 'assets/authBg_tablet.png';

const ForgotPassword = lazy(() =>
  import(
    /* webpackChunkName: "forgot-password" */ './components/ForgotPassword'
  )
);
const ChangePassword = lazy(() =>
  import(
    /* webpackChunkName: "change-password" */ './components/ChangePassword'
  )
);
const ValidateOTP = lazy(() =>
  import(/* webpackChunkName: "validate-otp" */ './components/ValidateOtp')
);

const AuthPage = () => {
  const location = useLocation();

  const navigate = useNavigate();

  const { authState } = useContext(AuthContext);

  // TODO:reverse it before production

  useEffect(() => {
    if (authState.isAuthenticated && !authState.device_activated) {
      navigate('/app/trackorder', { replace: true });
    } else if (authState.isAuthenticated && authState.device_activated) {
      navigate('/app/people-and-training', { replace: true });
    }
  }, [navigate, authState.isAuthenticated, authState.device_activated]);
  return (
    <div className='auth-bg-container h-screen'>
      <div
        className={`lg:h-screen ${
          location.pathname.includes('/auth/validate-otp')
            ? 'w-full lg:w-1/2'
            : 'w-full lg:w-2/5'
        } xl:w-[38%] bg-[#FFFAF2] p-4 lg:p-8`}
      >
        <div className=' md:ml-16 lg:ml-10'>
          <AptaILogo />
        </div>

        <Routes>
          <Route path='/login' element={<Login />} />
          <Route
            path='/forgot-password'
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <ForgotPassword />
              </Suspense>
            }
          />
          <Route
            path='/validate-otp'
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <ValidateOTP />
              </Suspense>
            }
          />
          <Route
            path='/change-password'
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <ChangePassword />
              </Suspense>
            }
          />
        </Routes>
      </div>
      <div className=' px-4 mt-10'>
        <img className='lg:hidden' src={bgTablet} alt='Background' />
        {/* <img className='block sm:hidden' src={bgMobile} alt='Background' /> */}
      </div>
    </div>
  );
};

export default AuthPage;
