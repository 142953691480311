import React from 'react';

import { ReactComponent as ConfirmedPendingIcon } from '../../../assets/images/trackorder/confirmedPendingIcon.svg';
import { ReactComponent as ConfirmedSuccessIcon } from '../../../assets/images/trackorder/confirmedSuccessIcon.svg';
import { ReactComponent as PreparingIcon } from '../../../assets/images/trackorder/preparingIcon.svg';
import { ReactComponent as PreparingPendingIcon } from '../../../assets/images/trackorder/preparingPendingIcon.svg';
import { ReactComponent as PreparingSuccessIcon } from '../../../assets/images/trackorder/preparingSuccessIcon.svg';
import { ReactComponent as ShippedIcon } from '../../../assets/images/trackorder/shippedIcon.svg';
import { ReactComponent as ShippingPendingIcon } from '../../../assets/images/trackorder/shippingPendingIcon.svg';
import { ReactComponent as ShippedSuccessIcon } from '../../../assets/images/trackorder/shippedSuccessIcon.svg';
import { ReactComponent as DeliveredIcon } from '../../../assets/images/trackorder/deliveredIcon.svg';
import { ReactComponent as DeliveredPendingIcon } from '../../../assets/images/trackorder/deliveredPendingIcon.svg';
import { ReactComponent as DeliveredSuccessIcon } from '../../../assets/images/trackorder/deliverSuccessIcon.svg';
import dayjs from 'dayjs';

export default function OrderTrackStatus({ data }) {
  return (
    <div className='w-full mx-auto relative flex flex-wrap justify-between md:-mt-4'>
      {/* horizontal line */}
      <hr className='hidden md:block absolute top-12 lg:top-1/3 w-9/12 ml-32 border-dashed border-font-placeholder border-opacity-50' />
      {/* verticle line for mobile screen */}
      <hr className='md:hidden h-80 top-20 absolute left-12 ml-2 border-font-placeholder border-dashed border border-opacity-50' />
      <div className='w-full md:w-1/4 flex md:flex-wrap justify-center my-12 md:my-0'>
        <div
          className={`bg-white z-10 flex items-center justify-center rounded-full ml-4 md:ml-0 w-16 md:w-20 lg:w-24 h-16 md:h-20 lg:h-24 border ${
            data?.order_status === 'New'
              ? ' border-warning'
              : 'border-alert-success'
          }`}
        >
          {data?.order_status === 'New' ? (
            <ConfirmedPendingIcon className='w-8 h-8 md:w-10 md:h-10' />
          ) : (
            <ConfirmedSuccessIcon className='w-8 h-8 md:w-10 md:h-10' />
          )}
        </div>
        {data?.order_status === 'New' ? (
          <p className='w-3/4 md:w-full md:text-center mt-3 md:mt-4 pl-4 md:px-6 xl:ml-8'>
            Your order is not yet confirmed
          </p>
        ) : (
          <div className='w-3/4 md:w-full md:text-center mt-2 md:mt-4 pl-4 md:px-6 '>
            <p>Your order is confirmed</p>
            <p>
              On {dayjs(data?.accepted_date).format('DD MMM YYYY, hh:mm A')}
            </p>
          </div>
        )}
      </div>
      <div className='w-full md:w-1/4 flex md:flex-wrap justify-center'>
        <div
          className={`z-10 flex items-center justify-center rounded-full ml-4 md:ml-0 w-16 md:w-20 lg:w-24 h-16 md:h-20 lg:h-24  ${
            data?.order_status === 'New'
              ? 'bg-FBEEE7'
              : data?.order_status === 'Confirmed'
              ? 'border border-warning bg-white'
              : 'border border-alert-success bg-white'
          }`}
        >
          {data?.order_status === 'New' ? (
            <PreparingIcon className='w-8 h-8 md:w-10 md:h-10' />
          ) : data?.order_status === 'Confirmed' ? (
            <PreparingPendingIcon className='w-8 h-8 md:w-10 md:h-10' />
          ) : (
            <PreparingSuccessIcon className='w-8 h-8 md:w-10 md:h-10' />
          )}
        </div>
        {data?.order_status === 'New' || data?.order_status === 'Confirmed' ? (
          <p className='w-3/4 md:w-full md:text-center mt-3 md:mt-4 pl-4 md:px-6 text-515151'>
            Preparing your order
          </p>
        ) : (
          <div className='w-3/4 md:w-full md:text-center mt-2 md:mt-4 pl-4 md:px-6'>
            <p>Your order is prepared</p>
            <p>
              On {dayjs(data?.prepared_date).format('DD MMM YYYY, hh:mm A')}
            </p>
          </div>
        )}
      </div>
      <div className='w-full md:w-1/4 flex md:flex-wrap justify-center my-12 md:my-0'>
        <div
          className={`z-10 flex items-center justify-center rounded-full ml-4 md:ml-0 w-16 md:w-20 lg:w-24 h-16 md:h-20 lg:h-24 ${
            data?.order_status === 'New' || data?.order_status === 'Confirmed'
              ? 'bg-FBEEE7'
              : data?.order_status === 'Prepared'
              ? 'border border-warning bg-white'
              : 'border border-alert-success bg-white'
          }`}
        >
          {data?.order_status === 'New' ||
          data?.order_status === 'Confirmed' ? (
            <ShippedIcon className='w-8 h-8 md:w-10 md:h-10' />
          ) : data?.order_status === 'Prepared' ? (
            <ShippingPendingIcon className='w-8 h-8 md:w-10 md:h-10' />
          ) : (
            <ShippedSuccessIcon className='w-8 h-8 md:w-10 md:h-10' />
          )}
        </div>
        {data?.order_status === 'Shipped' ||
        data?.order_status === 'Delivered' ? (
          <div className='w-3/4 md:w-full md:text-center mt-4 pl-4 md:px-6'>
            <p>Order Shipped</p>
            <p>
              On {dayjs(data?.shipping_date).format('DD MMM YYYY, hh:mm A')}
            </p>
          </div>
        ) : (
          <p className='w-3/4 md:w-full md:text-center mt-4 pl-4 md:px-6 text-515151'>
            Order Shipped
          </p>
        )}
      </div>
      <div className='w-full md:w-1/4 flex md:flex-wrap justify-center'>
        <div
          className={`z-10 flex items-center justify-center rounded-full ml-4 md:ml-0 w-16 md:w-20 lg:w-24 h-16 md:h-20 lg:h-24 ${
            data?.shipping_status === 'Delivered'
              ? 'border border-alert-success bg-white'
              : data?.order_status === 'Shipped'
              ? 'border border-warning bg-white'
              : 'bg-FBEEE7'
          }`}
        >
          {data?.shipping_status === 'Delivered' ? (
            <DeliveredSuccessIcon className='w-8 h-8 md:w-10 md:h-10' />
          ) : data?.order_status === 'Shipped' ? (
            <DeliveredPendingIcon className='w-8 h-8 md:w-10 md:h-10' />
          ) : (
            <DeliveredIcon className='w-8 h-8 md:w-10 md:h-10' />
          )}
        </div>
        {data?.order_status === 'Delivered' ? (
          <p className='w-3/4 md:w-full md:text-center mt-2 md:mt-4 pl-4 md:px-6'>
            Delivered
          </p>
        ) : (
          <p className='w-3/4 md:w-full md:text-center mt-5 md:mt-4 pl-4 md:px-6 text-515151'>
            Delivered
          </p>
        )}
      </div>
    </div>
  );
}
