export const WillRecomndedIcon = (
  <svg
    width='34'
    height='34'
    viewBox='0 0 34 34'
    fill='none'
    xmlns='https://www.w3.org/2000/svg'
  >
    <path
      d='M10.7852 22.2313C13.4994 20.027 20.3852 17.0235 26.2137 22.6446C23.2137 25.4001 15.928 29.175 10.7852 22.2313Z'
      stroke='white'
      strokeWidth='0.881188'
    />
    <path
      d='M11.2676 22.3208L25.7319 22.8029'
      stroke='white'
      strokeWidth='0.881188'
    />
    <path
      d='M15.6064 19.4277C15.6064 20.1992 15.6064 21.6777 15.6064 22.3206'
      stroke='white'
      strokeWidth='0.881188'
    />
    <path
      d='M17.5352 19.4277C17.5352 20.1992 17.5352 21.6777 17.5352 22.3206'
      stroke='white'
      strokeWidth='0.881188'
    />
    <path
      d='M19.4639 19.4277C19.4639 21.2277 19.4639 24.6777 19.4639 26.1777'
      stroke='white'
      strokeWidth='0.881188'
    />
    <path
      d='M21.3926 19.4277C21.3926 20.9706 21.3926 23.9277 21.3926 25.2134'
      stroke='white'
      strokeWidth='0.881188'
    />
    <path
      d='M15.6064 21.3569C15.6064 21.6141 15.6064 22.1069 15.6064 22.3212'
      stroke='white'
      strokeWidth='0.881188'
    />
    <path
      d='M15.6064 22.3208C15.6064 23.3494 15.6064 25.3208 15.6064 26.1779'
      stroke='white'
      strokeWidth='0.881188'
    />
    <path
      d='M17.5352 22.3208C17.5352 23.3494 17.5352 25.3208 17.5352 26.1779'
      stroke='white'
      strokeWidth='0.881188'
    />
    <path
      d='M11.4437 11.2465C11.6918 10.483 12.772 10.483 13.02 11.2465C13.131 11.588 13.4492 11.8192 13.8082 11.8192C14.611 11.8192 14.9448 12.8465 14.2953 13.3184C14.0049 13.5294 13.8833 13.9035 13.9943 14.2449C14.2423 15.0084 13.3685 15.6433 12.719 15.1715C12.4285 14.9604 12.0352 14.9604 11.7447 15.1715C11.0953 15.6433 10.2214 15.0084 10.4695 14.2449C10.5804 13.9035 10.4589 13.5294 10.1684 13.3184C9.51892 12.8465 9.85272 11.8192 10.6555 11.8192C11.0146 11.8192 11.3327 11.588 11.4437 11.2465Z'
      fill='white'
    />
    <path
      d='M23.015 11.2465C23.2631 10.483 24.3432 10.483 24.5913 11.2465C24.7023 11.588 25.0205 11.8192 25.3795 11.8192C26.1823 11.8192 26.5161 12.8465 25.8666 13.3184C25.5761 13.5294 25.4546 13.9035 25.5656 14.2449C25.8136 15.0084 24.9398 15.6433 24.2903 15.1715C23.9998 14.9604 23.6065 14.9604 23.316 15.1715C22.6666 15.6433 21.7927 15.0084 22.0408 14.2449C22.1517 13.9035 22.0302 13.5294 21.7397 13.3184C21.0902 12.8465 21.424 11.8192 22.2268 11.8192C22.5858 11.8192 22.904 11.588 23.015 11.2465Z'
      fill='white'
    />
    <path
      d='M34 17C34 26.3887 26.3887 34 17 34C7.61128 34 0 26.3887 0 17C0 7.61128 7.61128 0 17 0C26.3887 0 34 7.61128 34 17Z'
      fill='#6BC63C'
    />
    <path
      d='M17.0002 19.833C13.5785 19.833 11.3081 19.4344 8.50022 18.8885C7.85894 18.7648 6.61133 18.8885 6.61133 20.7774C6.61133 24.5552 10.9511 29.2774 17.0002 29.2774C23.0484 29.2774 27.3891 24.5552 27.3891 20.7774C27.3891 18.8885 26.1415 18.7639 25.5002 18.8885C22.6924 19.4344 20.4219 19.833 17.0002 19.833Z'
      fill='#3D9B27'
    />
    <path
      d='M8.5 20.7773C8.5 20.7773 11.3333 21.7218 17 21.7218C22.6667 21.7218 25.5 20.7773 25.5 20.7773C25.5 20.7773 23.6111 24.5551 17 24.5551C10.3889 24.5551 8.5 20.7773 8.5 20.7773Z'
      fill='#3D9B27'
    />
    <path
      d='M14.8111 4.1675L10.5214 4.924L8.31139 0.907273C8.06772 0.464328 7.56716 0.227273 7.07228 0.314162C6.57455 0.401995 6.18733 0.796773 6.10989 1.29544L5.40627 5.82594L1.11661 6.58244C0.61133 6.67122 0.221275 7.07827 0.15233 7.58733C0.083386 8.09638 0.351608 8.59127 0.816275 8.81227L4.66016 10.6369L3.95372 15.1816C3.87533 15.6878 4.13222 16.1865 4.58933 16.4179C4.75839 16.5029 4.93972 16.5435 5.12011 16.5435C5.42894 16.5435 5.734 16.4226 5.96161 16.1922L9.2785 12.829L13.5455 14.8548C14.0083 15.0739 14.5598 14.971 14.9112 14.5979C15.2625 14.2249 15.3324 13.6686 15.0859 13.22L12.8683 9.18911L15.8575 6.15933C16.2183 5.79383 16.3004 5.23661 16.0624 4.78138C15.8226 4.32616 15.3135 4.07966 14.8111 4.1675ZM19.1895 4.1675L23.4792 4.924L25.6892 0.907273C25.9328 0.464328 26.4334 0.227273 26.9283 0.314162C27.4251 0.401995 27.8123 0.796773 27.8897 1.29544L28.5933 5.82594L32.883 6.58244C33.3892 6.67122 33.7793 7.07733 33.8473 7.58638C33.9153 8.09544 33.648 8.59033 33.1833 8.81133L29.3394 10.636L30.0459 15.1807C30.1243 15.6869 29.8674 16.1855 29.4103 16.4169C29.2412 16.5019 29.0599 16.5426 28.8795 16.5426C28.5707 16.5426 28.2656 16.4217 28.038 16.1912L24.7211 12.8281L20.4541 14.8539C19.9913 15.073 19.4398 14.9701 19.0884 14.597C18.7371 14.2239 18.6672 13.6677 18.9137 13.2191L21.1313 9.18911L18.1421 6.15933C17.7813 5.79383 17.6992 5.23661 17.9372 4.78138C18.178 4.32616 18.6871 4.07966 19.1895 4.1675Z'
      fill='#C8DC19'
    />
  </svg>
);

export const CanDoBetterIcon = (
  <svg
    width='34'
    height='34'
    viewBox='0 0 34 34'
    fill='none'
    xmlns='https://www.w3.org/2000/svg'
  >
    <path
      d='M17 34C26.3888 34 34 26.3888 34 17C34 7.61116 26.3888 0 17 0C7.61116 0 0 7.61116 0 17C0 26.3888 7.61116 34 17 34Z'
      fill='#FFCC4D'
    />
    <path
      d='M9.93034 22.3092C9.97284 22.4782 11.0334 26.4449 16.9995 26.4449C22.9665 26.4449 24.0262 22.4782 24.0687 22.3092C24.1206 22.1042 24.0281 21.8917 23.8448 21.7859C23.6607 21.6811 23.4302 21.7123 23.2782 21.8587C23.2602 21.8766 21.4327 23.6115 16.9995 23.6115C12.5663 23.6115 10.7378 21.8766 10.7208 21.8596C10.6302 21.7699 10.5093 21.7227 10.3884 21.7227C10.3091 21.7227 10.2288 21.7425 10.1561 21.7831C9.97095 21.8889 9.87839 22.1033 9.93034 22.3092Z'
      fill='#D99B08'
    />
    <path
      d='M11.3328 16.0554C12.6368 16.0554 13.6939 14.5755 13.6939 12.7499C13.6939 10.9243 12.6368 9.44434 11.3328 9.44434C10.0288 9.44434 8.97168 10.9243 8.97168 12.7499C8.97168 14.5755 10.0288 16.0554 11.3328 16.0554Z'
      fill='#D99B08'
    />
    <path
      d='M22.6668 16.0554C23.9708 16.0554 25.0279 14.5755 25.0279 12.7499C25.0279 10.9243 23.9708 9.44434 22.6668 9.44434C21.3628 9.44434 20.3057 10.9243 20.3057 12.7499C20.3057 14.5755 21.3628 16.0554 22.6668 16.0554Z'
      fill='#D99B08'
    />
  </svg>
);

export const NotSatisfiedIcon = (
  <svg
    width='33'
    height='33'
    viewBox='0 0 33 33'
    fill='none'
    xmlns='https://www.w3.org/2000/svg'
  >
    <path
      d='M33 16.5C33 25.6126 25.6126 33 16.5 33C7.38833 33 0 25.6126 0 16.5C0 7.38833 7.38833 0 16.5 0C25.6126 0 33 7.38833 33 16.5Z'
      fill='#FF3530'
    />
    <path
      d='M10.5417 18.3327C11.8073 18.3327 12.8333 16.8963 12.8333 15.1243C12.8333 13.3524 11.8073 11.916 10.5417 11.916C9.27601 11.916 8.25 13.3524 8.25 15.1243C8.25 16.8963 9.27601 18.3327 10.5417 18.3327Z'
      fill='white'
    />
    <path
      d='M22.4587 18.3327C23.7243 18.3327 24.7503 16.8963 24.7503 15.1243C24.7503 13.3524 23.7243 11.916 22.4587 11.916C21.193 11.916 20.167 13.3524 20.167 15.1243C20.167 16.8963 21.193 18.3327 22.4587 18.3327Z'
      fill='white'
    />
    <path
      d='M22.917 23.8336C18.0003 21.5457 15.0003 21.4094 10.0837 23.8336C9.57766 23.8336 9.16699 23.4239 9.16699 22.917C9.16699 22.41 9.68508 22.1714 10.0837 22.0003C15.2731 19.773 18.0003 19.6367 22.917 22.0003C23.4549 22.2589 23.8337 22.41 23.8337 22.917C23.8337 23.4239 23.4239 23.8336 22.917 23.8336Z'
      fill='white'
    />
  </svg>
);

export const EyeIcon = (
  <svg
    width='24'
    height='24'
    xmlns='https://www.w3.org/2000/svg'
    fillRule='evenodd'
    clipRule='evenodd'
  >
    <path d='M12.01 20c-5.065 0-9.586-4.211-12.01-8.424 2.418-4.103 6.943-7.576 12.01-7.576 5.135 0 9.635 3.453 11.999 7.564-2.241 4.43-6.726 8.436-11.999 8.436zm-10.842-8.416c.843 1.331 5.018 7.416 10.842 7.416 6.305 0 10.112-6.103 10.851-7.405-.772-1.198-4.606-6.595-10.851-6.595-6.116 0-10.025 5.355-10.842 6.584zm10.832-4.584c2.76 0 5 2.24 5 5s-2.24 5-5 5-5-2.24-5-5 2.24-5 5-5zm0 1c2.208 0 4 1.792 4 4s-1.792 4-4 4-4-1.792-4-4 1.792-4 4-4z' />
  </svg>
);

export const EyeCloseIcon = (
  <svg
    width='24'
    height='24'
    xmlns='https://www.w3.org/2000/svg'
    fillRule='evenodd'
    clipRule='evenodd'
  >
    <path d='M8.137 15.147c-.71-.857-1.146-1.947-1.146-3.147 0-2.76 2.241-5 5-5 1.201 0 2.291.435 3.148 1.145l1.897-1.897c-1.441-.738-3.122-1.248-5.035-1.248-6.115 0-10.025 5.355-10.842 6.584.529.834 2.379 3.527 5.113 5.428l1.865-1.865zm6.294-6.294c-.673-.53-1.515-.853-2.44-.853-2.207 0-4 1.792-4 4 0 .923.324 1.765.854 2.439l5.586-5.586zm7.56-6.146l-19.292 19.293-.708-.707 3.548-3.548c-2.298-1.612-4.234-3.885-5.548-6.169 2.418-4.103 6.943-7.576 12.01-7.576 2.065 0 4.021.566 5.782 1.501l3.501-3.501.707.707zm-2.465 3.879l-.734.734c2.236 1.619 3.628 3.604 4.061 4.274-.739 1.303-4.546 7.406-10.852 7.406-1.425 0-2.749-.368-3.951-.938l-.748.748c1.475.742 3.057 1.19 4.699 1.19 5.274 0 9.758-4.006 11.999-8.436-1.087-1.891-2.63-3.637-4.474-4.978zm-3.535 5.414c0-.554-.113-1.082-.317-1.562l.734-.734c.361.69.583 1.464.583 2.296 0 2.759-2.24 5-5 5-.832 0-1.604-.223-2.295-.583l.734-.735c.48.204 1.007.318 1.561.318 2.208 0 4-1.792 4-4z' />
  </svg>
);

export const SearchIcon = ({ width = 17, height = 17, fill = '#FF4D53' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 17 17'
      fill='none'
      xmlns='https://www.w3.org/2000/svg'
    >
      <path
        d='M15.75 15.75L12.3855 12.3795L15.75 15.75ZM14.25 7.875C14.25 9.56576 13.5784 11.1873 12.3828 12.3828C11.1873 13.5784 9.56576 14.25 7.875 14.25C6.18424 14.25 4.56274 13.5784 3.36719 12.3828C2.17165 11.1873 1.5 9.56576 1.5 7.875C1.5 6.18424 2.17165 4.56274 3.36719 3.36719C4.56274 2.17165 6.18424 1.5 7.875 1.5C9.56576 1.5 11.1873 2.17165 12.3828 3.36719C13.5784 4.56274 14.25 6.18424 14.25 7.875V7.875Z'
        stroke={fill}
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  );
};

export const FilterIcon = ({ width = 17, height = 12, fill = '#FF4D53' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 12'
      fill='none'
      xmlns='https://www.w3.org/2000/svg'
    >
      <path
        d='M0 10.5905C0 10.2874 0.0975528 9.99668 0.271198 9.78235C0.444842 9.56803 0.680355 9.44762 0.925926 9.44762H6.48148C6.72705 9.44762 6.96256 9.56803 7.13621 9.78235C7.30985 9.99668 7.40741 10.2874 7.40741 10.5905C7.40741 10.8936 7.30985 11.1843 7.13621 11.3986C6.96256 11.6129 6.72705 11.7333 6.48148 11.7333H0.925926C0.680355 11.7333 0.444842 11.6129 0.271198 11.3986C0.0975528 11.1843 0 10.8936 0 10.5905ZM0 5.86667C0 5.56356 0.0975528 5.27287 0.271198 5.05854C0.444842 4.84422 0.680355 4.72381 0.925926 4.72381H11.2222C11.4678 4.72381 11.7033 4.84422 11.877 5.05854C12.0506 5.27287 12.1481 5.56356 12.1481 5.86667C12.1481 6.16977 12.0506 6.46046 11.877 6.67479C11.7033 6.88912 11.4678 7.00952 11.2222 7.00952H0.925926C0.680355 7.00952 0.444842 6.88912 0.271198 6.67479C0.0975528 6.46046 0 6.16977 0 5.86667ZM0 1.14286C0 0.839753 0.0975528 0.549063 0.271198 0.334735C0.444842 0.120408 0.680355 0 0.925926 0H15.0741C15.3196 0 15.5552 0.120408 15.7288 0.334735C15.9024 0.549063 16 0.839753 16 1.14286C16 1.44596 15.9024 1.73665 15.7288 1.95098C15.5552 2.16531 15.3196 2.28571 15.0741 2.28571H0.925926C0.680355 2.28571 0.444842 2.16531 0.271198 1.95098C0.0975528 1.73665 0 1.44596 0 1.14286Z'
        fill={fill}
      />
    </svg>
  );
};
