import React from 'react';
import { Link } from 'react-router-dom';
import successBgImage from 'assets/images/scheduleDemo/successBgImage.png';

export default function RequestSuccessPage() {
  return (
    <div
      className='h-screen -mt-20 pt-40'
      style={{
        background: `url(${successBgImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        backgroundPosition: 'cover',
      }}
    >
      <lottie-player
        autoplay
        mode='normal'
        speed={1}
        style={{ width: '300px', height: '350px', margin: 'auto' }}
        src='https://assets10.lottiefiles.com/packages/lf20_7br3ojw5.json'
      ></lottie-player>
      <p className='text-center text-lg px-4'>
        Thank you for showing interest in our product, we will get back to you
        soon
      </p>
      <Link to='/'>
        <p className='text-center text-primary py-4 cursor-pointer select-none'>
          Go to home
        </p>
      </Link>
    </div>
  );
}
