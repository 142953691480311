import React, { useState, useContext, useEffect } from 'react';
import { useQuery, useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndPoints';

import { AuthContext } from 'context/authContext';

import PrimaryButton from 'components/primaryButton/primaryButton';
import SecondaryButton from 'components/secondaryButton/secondaryButton';

import ActivateDeviceModal from './components/activateDeviceModal';
import OrderCancelModal from './components/orderCancelModal';
import OrderTrackStatus from './components/orderTrackStatus';
import OrderNotRecived from './components/orderNotRecived';

import { ReactComponent as CancelIcon } from 'assets/images/trackorder/cancelIcon.svg';
import { ReactComponent as UnderlineIcon } from 'assets/images/trackorder/underlineIcon.svg';

const TrackOrder = () => {
  const { authState } = useContext(AuthContext);
  const navigate = useNavigate();

  const [orderStatus, setOrderStatus] = useState('New');
  const [isActivateModalOpen, setActivateModalOpen] = useState(false);
  const [isCancelModalOpen, setCancelModalOpen] = useState(false);
  const [isOrdeNotRecivedModalOpen, setOrdeNotRecivedModalOpen] =
    useState(false);
  const [requestedCancellation, setRequestedCancellation] = useState('');
  const [isAmountRefunded, setAmountRefunded] = useState();

  // eslint-disable-next-line no-unused-vars
  const getProductOrderDetails = useQuery(
    ['getUserOrderDetails'],
    async () => {
      const resData = await makeAPIRequest.get(
        `${apiEndPoints.USER_ORDER_DETAILS}?user_id=${JSON.parse(
          localStorage.getItem('user_id')
        )}`
      );
      return resData?.data;
    },
    {
      onSuccess: (data) => {
        setAmountRefunded(data?.data[0]?.is_refunded);
        setRequestedCancellation(data?.data[0]?.requested_cancellation);
        setOrderStatus(data?.data[0]?.order_status);
        if (data?.data[0]?.requested_cancellation) {
          setCancelModalOpen(true);
        }
        if (data?.data[0]?.shipping_status === 'Delivered') {
          setActivateModalOpen(true);
        }
      },
    }
  );

  const updateUseProfile = useMutation(async (data) => {
    const res = await makeAPIRequest.put(
      apiEndPoints.UPDATE_USER_PROFILE,
      data
    );
    return res.data;
  });

  const handleOrderStatus = () => {
    const formData = new FormData();
    formData.append('order_received', 'True');
    updateUseProfile.mutate(formData);
    setActivateModalOpen(true);
  };

  useEffect(() => {
    if (authState.device_activated) {
      navigate('/app/people-and-training', { replace: true });
    }
  }, [authState.device_activated, navigate]);

  return (
    <main className='max-w-main-layout mx-auto md:h-full md:-mt-20 px-4 pt-8 md:pt-32 pb-10'>
      <div className='w-full bg-white md:h-full rounded-lg border border-font-placeholder border-opacity-10 grid content-between py-8'>
        <div>
          <p className='text-primary text-heading-3 lg:text-heading-2 text-center font-medium'>
            Track your order
          </p>
          <UnderlineIcon className='w-16 mx-auto' />
        </div>
        <div>
          <OrderTrackStatus data={getProductOrderDetails?.data?.data[0]} />
        </div>

        {orderStatus === 'New' ? (
          <div
            className='px-8 md:px-12 flex items-center cursor-pointer mt-8 md:mt-0'
            onClick={() => setCancelModalOpen(true)}
          >
            <CancelIcon className='w-4 h-4 mr-1' />
            <p className='text-primary text-text-primary select-none'>
              Request cancellation
            </p>
          </div>
        ) : orderStatus === 'Shipped' || orderStatus === 'Delivered' ? (
          <div className='px-4 md:px-12 flex flex-wrap md:flex-nowrap items-center md:justify-center space-x-6 text-heading-4 md:mr-6'>
            <p className='text-515151 w-full md:w-auto mt-10 mb-4 md:mb-0 md:mt-0 text-center'>
              Have you received your order
            </p>
            <button onClick={() => setOrdeNotRecivedModalOpen(true)}>
              <SecondaryButton>No</SecondaryButton>
            </button>
            <button onClick={() => handleOrderStatus()}>
              <PrimaryButton>Yes</PrimaryButton>
            </button>
          </div>
        ) : (
          <div></div>
        )}
      </div>
      <ActivateDeviceModal
        isActivateModalOpen={isActivateModalOpen}
        setActivateModalOpen={setActivateModalOpen}
      />
      <OrderCancelModal
        isCancelModalOpen={isCancelModalOpen}
        setCancelModalOpen={setCancelModalOpen}
        requestedCancellation={requestedCancellation}
        isAmountRefunded={isAmountRefunded}
      />
      <OrderNotRecived
        isOrdeNotRecivedModalOpen={isOrdeNotRecivedModalOpen}
        setOrdeNotRecivedModalOpen={setOrdeNotRecivedModalOpen}
      />
    </main>
  );
};

export default TrackOrder;
