import { atom, useAtom } from 'jotai';

import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';

import cx from 'classnames';

import {
  peopleAddAndEditModalVisibleAtom,
  peopleModalTypeAtom,
} from './addAndEditPeopleModal';
import { peopleDeleteModalVisibleAtom } from './deletePeopleModal';

export const currentSelectedPersonAtom = atom({});

const PeopleCardActionDropdown = ({ currentUser }) => {
  const [, setIsModalOpen] = useAtom(peopleAddAndEditModalVisibleAtom);
  const [, setIsDeleteModalOpen] = useAtom(peopleDeleteModalVisibleAtom);
  const [, setModalType] = useAtom(peopleModalTypeAtom);

  const [, setCurrentSelectedPerson] = useAtom(currentSelectedPersonAtom);

  return (
    <DropdownMenuPrimitive.Root>
      <DropdownMenuPrimitive.Trigger asChild>
        <button className='bg-primary whitespace-nowrap flex justify-center rounded-full text-[15px] text-white items-center space-x-2 focus:outline focus:outline-offset-1 focus:outline-2 outline-primary'>
          <svg
            width='22'
            height='22'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <circle cx='12' cy='12' r='12' fill='#FF4D53' />
            <path
              d='M17.3996 12.1C17.3996 11.6029 16.9967 11.2 16.4996 11.2C16.0026 11.2 15.5996 11.6029 15.5996 12.1C15.5996 12.5971 16.0026 13 16.4996 13C16.9967 13 17.3996 12.5971 17.3996 12.1Z'
              fill='white'
              stroke='white'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M12.5998 12.1C12.5998 11.6029 12.1969 11.2 11.6998 11.2C11.2027 11.2 10.7998 11.6029 10.7998 12.1C10.7998 12.5971 11.2027 13 11.6998 13C12.1969 13 12.5998 12.5971 12.5998 12.1Z'
              fill='white'
              stroke='white'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M7.8 12.1C7.8 11.6029 7.39706 11.2 6.9 11.2C6.40294 11.2 6 11.6029 6 12.1C6 12.5971 6.40294 13 6.9 13C7.39706 13 7.8 12.5971 7.8 12.1Z'
              fill='white'
              stroke='white'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </button>
      </DropdownMenuPrimitive.Trigger>

      <DropdownMenuPrimitive.Content
        align='end'
        sideOffset={5}
        className={cx(
          'radix-side-top:animate-slide-up radix-side-bottom:animate-slide-down',
          'w-32 rounded px-1.5 py-1 shadow-md',
          'bg-white border border-opacity-20 border-font-placeholder'
        )}
      >
        <DropdownMenuPrimitive.Item
          onClick={() => {
            setModalType('Edit');
            setIsModalOpen(true);
            setCurrentSelectedPerson(currentUser);
          }}
          className='text-font-primary focus:bg-font-placeholder focus:bg-opacity-5 flex cursor-pointer select-none items-center rounded-md px-2 py-1 my-1 text-small outline-none space-x-2'
        >
          <svg
            width='17'
            height='18'
            viewBox='0 0 17 18'
            fill='none'
            xmlns='https://www.w3.org/2000/svg'
          >
            <path
              d='M12.042 2.25023C12.228 2.05324 12.4489 1.89699 12.692 1.79038C12.935 1.68378 13.1956 1.62891 13.4587 1.62891C13.7218 1.62891 13.9823 1.68378 14.2254 1.79038C14.4684 1.89699 14.6893 2.05324 14.8753 2.25023C15.0614 2.44721 15.2089 2.68106 15.3096 2.93843C15.4103 3.1958 15.4621 3.47165 15.4621 3.75023C15.4621 4.0288 15.4103 4.30465 15.3096 4.56202C15.2089 4.81939 15.0614 5.05324 14.8753 5.25023L5.31283 15.3752L1.41699 16.5002L2.47949 12.3752L12.042 2.25023Z'
              stroke='#FF4D53'
              strokeWidth='1.58333'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>

          <span className='flex-grow'>Edit</span>
        </DropdownMenuPrimitive.Item>
        <DropdownMenuPrimitive.Item
          onClick={() => {
            setIsDeleteModalOpen(true);
            setCurrentSelectedPerson({
              id: currentUser.id,
              name: currentUser.name,
            });
          }}
          className='text-font-primary focus:bg-font-placeholder focus:bg-opacity-5 flex cursor-pointer select-none items-center rounded-md px-2 py-1 my-1 text-small outline-none space-x-2'
        >
          <svg
            width='18'
            height='20'
            viewBox='0 0 18 20'
            fill='none'
            xmlns='https://www.w3.org/2000/svg'
          >
            <path
              d='M16.3333 4.49967H12.6667V3.58301C12.6667 2.85366 12.3769 2.15419 11.8612 1.63846C11.3455 1.12274 10.646 0.833008 9.91667 0.833008H8.08333C7.35399 0.833008 6.65451 1.12274 6.13879 1.63846C5.62306 2.15419 5.33333 2.85366 5.33333 3.58301V4.49967H1.66667C1.42355 4.49967 1.19039 4.59625 1.01849 4.76816C0.846577 4.94007 0.75 5.17323 0.75 5.41634C0.75 5.65946 0.846577 5.89261 1.01849 6.06452C1.19039 6.23643 1.42355 6.33301 1.66667 6.33301H2.58333V16.4163C2.58333 17.1457 2.87306 17.8452 3.38879 18.3609C3.90451 18.8766 4.60399 19.1663 5.33333 19.1663H12.6667C13.396 19.1663 14.0955 18.8766 14.6112 18.3609C15.1269 17.8452 15.4167 17.1457 15.4167 16.4163V6.33301H16.3333C16.5764 6.33301 16.8096 6.23643 16.9815 6.06452C17.1534 5.89261 17.25 5.65946 17.25 5.41634C17.25 5.17323 17.1534 4.94007 16.9815 4.76816C16.8096 4.59625 16.5764 4.49967 16.3333 4.49967ZM7.16667 3.58301C7.16667 3.33989 7.26324 3.10674 7.43515 2.93483C7.60706 2.76292 7.84022 2.66634 8.08333 2.66634H9.91667C10.1598 2.66634 10.3929 2.76292 10.5648 2.93483C10.7368 3.10674 10.8333 3.33989 10.8333 3.58301V4.49967H7.16667V3.58301ZM13.5833 16.4163C13.5833 16.6595 13.4868 16.8926 13.3148 17.0645C13.1429 17.2364 12.9098 17.333 12.6667 17.333H5.33333C5.09022 17.333 4.85706 17.2364 4.68515 17.0645C4.51324 16.8926 4.41667 16.6595 4.41667 16.4163V6.33301H13.5833V16.4163Z'
              fill='#FF4D53'
            />
          </svg>

          <span className='flex-grow'>Delete</span>
        </DropdownMenuPrimitive.Item>
      </DropdownMenuPrimitive.Content>
    </DropdownMenuPrimitive.Root>
  );
};

export default PeopleCardActionDropdown;
