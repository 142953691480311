import React, { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { PrimaryButton } from 'components/primaryButton';
import { useForm, Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useMutation } from 'react-query';
import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndPoints';

import { ReactComponent as CubeIlls } from '../../../assets/images/trackorder/cubeIlls.svg';
import { ReactComponent as CancelIcon } from 'assets/images/trackorder/cancelIcon.svg';
import { ReactComponent as RefundCubeIlls } from 'assets/images/trackorder/refundCubeIlls.svg';

export default function OrderCancelModal({
  isCancelModalOpen,
  setCancelModalOpen,
  requestedCancellation,
  isAmountRefunded,
}) {
  const [isOrderCanceled, setIsOrderCanceled] = useState(false);

  useEffect(() => {
    if (requestedCancellation === true) {
      setIsOrderCanceled(true);
    }
  }, [requestedCancellation]);

  const defaultValues = {
    cancellation_reason: '',
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ defaultValues });

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <small className='text-placeholder text-alert-error'>
          {errors[name].message}
        </small>
      )
    );
  };

  const orderCancelMutation = useMutation(
    async (data) => {
      const res = await makeAPIRequest.post(
        apiEndPoints.REQUEST_ORDER_CANCEL,
        data
      );
      return res.data;
    },
    {
      onSuccess: () => {
        reset({
          cancellation_reason: '',
        });
        setIsOrderCanceled(true);
      },
      onError: (err) => {},
    }
  );

  const onSubmit = (data) => {
    orderCancelMutation.mutate(data);
  };

  return (
    <div>
      <Transition appear show={isCancelModalOpen} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-10 overflow-y-auto w-full'
          onClose={() =>
            requestedCancellation
              ? setCancelModalOpen(true)
              : setCancelModalOpen(false)
          }
        >
          <Dialog.Overlay />
          <div className='h-full px-4 text-center flex items-center justify-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-black opacity-60' />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <div className='inline-block md:w-3/4 lg:w-1/2 xl:w-2/5 px-10 md:h-3/4 pb-6 pt-4 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg'>
                {isOrderCanceled && !isAmountRefunded ? (
                  <div className='h-full'>
                    <div className='flex justify-end -mr-4'>
                      <Link to='/'>
                        <CancelIcon className='cursor-pointer' />
                      </Link>
                    </div>
                    <p className='text-heading-3 text-center font-medium text-primary'>
                      Order Cancelled
                    </p>
                    <div className='h-full md:pb-12 grid content-around'>
                      <CubeIlls className='w-32 mx-auto  pt-8 md:pt-0' />
                      <p className='text-center text-gray2 text-heading-4  pt-8 md:pt-0'>
                        Your request has been submitted successfully. You will
                        receive the refund amount within 3 working days.
                      </p>
                      <p className='text-text-primary text-font-placeholder text-center  pt-4 md:pt-0'>
                        For any query please contact{' '}
                        <span className='text-blue'>support@aptai.com</span>
                      </p>
                      <div className='flex justify-center'>
                        <Link to='/'>
                          <button className='w-40 pt-8 md:pt-0'>
                            <PrimaryButton>Back to home</PrimaryButton>
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                ) : isOrderCanceled && isAmountRefunded ? (
                  <div className='h-full'>
                    <div className='flex justify-end -mr-4'>
                      <Link to='/'>
                        <CancelIcon className='cursor-pointer' />
                      </Link>
                    </div>
                    <p className='text-heading-3 text-center font-medium text-primary'>
                      Order Cancelled
                    </p>
                    <div className='h-full md:pb-12 grid content-around'>
                      <RefundCubeIlls className='w-40 mx-auto  pt-8 md:pt-10' />
                      <p className='text-center text-gray2 text-heading-4  pt-8 md:pt-12'>
                        The amount has been refunded successfully.
                      </p>
                      <p className='text-text-primary text-font-placeholder text-center pb-8'>
                        For any query please contact{' '}
                        <span className='text-blue'>support@aptai.com</span>
                      </p>
                      <div className='flex justify-center'>
                        <Link to='/'>
                          <button className='w-40 pt-8 md:pt-0'>
                            <PrimaryButton>Back to home</PrimaryButton>
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className='h-3/4'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className='flex justify-end -mr-4'>
                        <CancelIcon
                          onClick={() => setCancelModalOpen(false)}
                          className='cursor-pointer'
                        />
                      </div>
                      <div className='h-full grid content-around'>
                        <div>
                          <p className='text-heading-3 text-center font-medium text-primary'>
                            Cancel Order
                          </p>
                          <p className='text-gray text-center'>
                            Are you sure you would like to cancel the order ?
                          </p>
                        </div>
                        <div className='mt-8'>
                          <CubeIlls className='w-32 mx-auto' />
                        </div>
                        <div className='schedule-demo my-8 w-full'>
                          <label htmlFor='message' className='pl-1 text-gray2'>
                            Reason*
                          </label>
                          <Controller
                            name='cancellation_reason'
                            control={control}
                            rules={{
                              required: 'This field is required',
                            }}
                            render={({ field }) => (
                              <textarea
                                {...field}
                                className='w-full mt-1 border-primary h-24 overflow-y-scroll scroller'
                              />
                            )}
                          />
                          {getFormErrorMessage('cancellation_reason')}
                        </div>
                        <div className='flex justify-center pb-5'>
                          <button>
                            <PrimaryButton
                              isLoading={orderCancelMutation?.isLoading}
                            >
                              Submit
                            </PrimaryButton>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
