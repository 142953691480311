import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useMutation } from 'react-query';
import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndPoints';

import { PrimaryButton } from 'components/primaryButton';
import SecondaryButton from 'components/secondaryButton/secondaryButton';

import { ReactComponent as CubeIlls } from 'assets/images/trackorder/cubeIlls.svg';
import { ReactComponent as Cube2Ills } from 'assets/images/trackorder/cube2Ills.svg';

export default function OrderNotRecived({
  isOrdeNotRecivedModalOpen,
  setOrdeNotRecivedModalOpen,
}) {
  const [showSupportSection, setSupportSection] = useState(false);

  // send otp mutation
  const updateOrderStatus = useMutation(async () => {
    const res = await makeAPIRequest.put(apiEndPoints.ACTIVATE_DEVICE);
    return res.data;
  });

  return (
    <div>
      <Transition appear show={isOrdeNotRecivedModalOpen} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-10 overflow-y-auto w-full'
          onClose={() => setOrdeNotRecivedModalOpen(false)}
        >
          <Dialog.Overlay />
          <div className='h-full px-4 text-center flex items-center justify-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-black opacity-60' />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <div className='inline-block md:w-3/4 lg:w-1/2 xl:w-2/5 px-10 h-1/2 pb-6 pt-4 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg'>
                {showSupportSection ? (
                  <div className='h-full grid content-around py-4'>
                    <Cube2Ills className='w-40 mx-auto' />
                    <p className='text-font-secondary w-full text-center'>
                      Do not worry, Incase you have not received the product
                      please contact{' '}
                      <span className='text-blue'>support@aptai.com</span>
                    </p>
                    <div className='flex justify-center w-full space-x-4'>
                      <button onClick={() => setOrdeNotRecivedModalOpen(false)}>
                        <PrimaryButton>Back</PrimaryButton>
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className='h-full grid content-around py-4'>
                    <CubeIlls className='w-32 mx-auto' />
                    <p className='text-font-secondary w-full text-center'>
                      Are you sure you did not receive the product yet?
                    </p>
                    <div className='flex justify-center w-full space-x-4'>
                      <button onClick={() => setOrdeNotRecivedModalOpen(false)}>
                        <SecondaryButton>No, I did</SecondaryButton>
                      </button>
                      <button
                        onClick={() => {
                          updateOrderStatus.mutate();
                          setSupportSection(true);
                        }}
                      >
                        <PrimaryButton>Yes, I did not</PrimaryButton>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
