import { useAtom } from 'jotai';
import { useMutation, useQueryClient } from 'react-query';

import apiEndPoints from 'services/apiEndPoints';
import makeAPIRequest from 'services/makeApiRequest';
import { SuccessToast, ErrorToast } from 'services/toasterService';

import getFormData from 'helper/getFormData';

import { peopleAddAndEditModalVisibleAtom } from '../components/addAndEditPeopleModal';

const useAddPeopleMutation = (reset) => {
  const queryClient = useQueryClient();

  const [, setIsModalOpen] = useAtom(peopleAddAndEditModalVisibleAtom);

  return useMutation(
    async (data) => {
      const formData = getFormData(data);
      let resData = await makeAPIRequest.post(
        apiEndPoints.PEOPLES_API,
        formData
      );
      return resData.data;
    },
    {
      onSuccess: (data) => {
        SuccessToast({ text: data.message });
        queryClient.invalidateQueries('peoples');
        setIsModalOpen(false);
        reset();
      },
      onError: (err) => {
        ErrorToast({ text: err.response.data.message });
      },
    }
  );
};

export default useAddPeopleMutation;
