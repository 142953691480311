import { useContext } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from 'context/authContext';

import makeApiRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndPoints';
import { ErrorToast } from 'services/toasterService';

export default function useLogin() {
  const { authDispatch } = useContext(AuthContext);

  const navigate = useNavigate();

  return useMutation(
    async (data) => {
      const res = await makeApiRequest.post(apiEndPoints.LOGIN, data);
      return res.data;
    },
    {
      onSuccess: (data) => {
        authDispatch({
          type: 'LOGIN',
          payload: data,
        });
        navigate('/app/dashboard', { replace: true });
      },
      onError: (error) => {
        ErrorToast({ text: error.response.data.message });
      },
    }
  );
}
