import { useQuery } from 'react-query';

import apiEndPoints from 'services/apiEndPoints';
import makeAPIRequest from 'services/makeApiRequest';

import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';

export default function UseGetAllPeople(peopleSearhvalue) {
  return useQuery(['peoples', peopleSearhvalue], async () => {
    const { data } = await makeAPIRequest.get(
      createQueryParamsForGetReq(apiEndPoints.PEOPLES_API, {
        search: peopleSearhvalue,
      })
    );
    return data.data;
  });
}
