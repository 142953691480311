import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useMutation } from "react-query";
import makeAPIRequest from "services/makeApiRequest";
import apiEndPoints from "services/apiEndPoints";

import { ReactComponent as AptaIFooterLogo } from "../../../assets/images/aptaIFooterLogo.svg";
import { ReactComponent as Aptagrim } from "../../../assets/images/Aptagrim.svg";
import { ReactComponent as TwitterLogo } from "../../../assets/images/footer/twitterLogo.svg";
import { ReactComponent as YoutubeLogo } from "../../../assets/images/footer/youtubeLogo.svg";
import { ReactComponent as InstaLogo } from "../../../assets/images/footer/instaLogo.svg";
import { ReactComponent as ShareIcon } from "../../../assets/images/footer/shareIcon.svg";
import dayjs from "dayjs";

export default function Footer({ testimonialRef }) {
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const defaultValues = {
    email: "",
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ defaultValues });

  const subscribeMutation = useMutation(
    async (data) => {
      const res = await makeAPIRequest.post(
        apiEndPoints.SUBSCRIBE_ALERTS,
        data
      );
      return res.data;
    },
    {
      onSuccess: (data) => {
        setSuccessMessage(data?.message);
        reset({ email: "" });
        setTimeout(() => {
          setSuccessMessage("");
        }, 3000);
      },
      onError: (err) => {
        setErrorMessage(err.response.data.message);
        setTimeout(() => {
          setErrorMessage();
        }, 3000);
      },
    }
  );

  const onSubmit = (data) => {
    subscribeMutation.mutate(data);
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <small className="text-placeholder text-alert-error pl-1">
          {errors[name].message}
        </small>
      )
    );
  };

  return (
    <div className="bg-0B0D17 lg:h-72 flex flex-wrap pt-6 md:pt-12 lg:pt-16">
      <div className="w-1/2 lg:w-1/4 -mt-2 md:-mt-3 pl-4 md:pl-20 lg:pl-12 xl:pl-20">
        <AptaIFooterLogo className="w-20 md:w-28" />
        <p className="text-D9DBE1 pt-1 text-placeholder md:text-base">
          Copyright © {dayjs().format("YYYY")} AptaI
        </p>
        <div className="flex space-x-3 md:space-x-4 pt-3 md:pt-5">
          <a
            href="https://twitter.com/aptagrim"
            target="_blank"
            rel="noreferrer"
          >
            <div className="w-8 h-8 bg-white bg-opacity-10 rounded-full flex justify-center items-center cursor-pointer">
              <TwitterLogo />
            </div>
          </a>
          <a
            href="https://www.youtube.com/channel/UC6JUZUqqLd-aUW20VK_Oo2w/playlists"
            target="_blank"
            rel="noreferrer"
          >
            <div className="w-8 h-8 bg-white bg-opacity-10 rounded-full flex justify-center items-center cursor-pointer">
              <YoutubeLogo />
            </div>
          </a>
          <a
            href="https://www.instagram.com/aptagrim/"
            target="_blank"
            rel="noreferrer"
          >
            <div className="w-8 h-8 bg-white bg-opacity-10 rounded-full flex justify-center items-center cursor-pointer">
              <InstaLogo />
            </div>
          </a>
        </div>
      </div>
      <div className="w-2/4 flex -ml-2">
        <div className="w-1/2 lg:w-1/4 md:pl-6 lg:pl-0">
          <p className="text-white md:text-heading-4 font-semibold">Company</p>
          <div className="text-D9DBE1 pt-2 md:pt-4 text-placeholder md:text-small select-none">
            <a href="https://aptagrim.com/" target="_blank" rel="noreferrer">
              <p className="pb-1 lg:pb-1.5 cursor-pointer">About us</p>
            </a>
            <Link to="/contact">
              <p className="py-1 lg:py-1.5 cursor-pointer">Contact us</p>
            </Link>
            {/* <Link to='/products'>
              <p className='py-1 lg:py-1.5 cursor-pointer'>Pricing</p>
            </Link> */}
            {/* <p
              className="pt-1 lg:pt-1.5 cursor-pointer"
              onClick={() => testimonialRef()}
            >
              Testimonials
            </p> */}
          </div>
        </div>
        <div className="w-1/2 lg:w-1/4 pl-2 xl:pl-8">
          <p className="text-white md:text-xl font-semibold">Support</p>
          <div className="text-D9DBE1 pt-2 md:pt-4 text-placeholder md:text-small select-none opacity-30">
            <p className="pb-1.5 cursor-pointer">Help center</p>
            <p className="py-1.5 cursor-pointer">Terms of service</p>
            <p className="pt-1.5 cursor-pointer">Privacy policy</p>
          </div>
        </div>
        {/* stay up to date feature code for large screens */}
        <div className="hidden lg:block w-2/4 pl-4 xl:pl-20">
          <p className="text-white text-heading-4 font-semibold">
            Stay up to date
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex footer-email">
              <Controller
                name="email"
                control={control}
                rules={{
                  required: "This field is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Invalid email address.",
                  },
                }}
                render={({ field }) => (
                  <input
                    {...field}
                    placeholder="Your email address"
                    className=" focus:outline-none text-white rounded-l-md p-2.5 text-small mt-4 bg-gray3"
                  />
                )}
              />
              <div className="bg-gray3 flex justify-center items-center mt-4 rounded-r-md">
                <button>
                  <ShareIcon className="mx-3 mt-0.5" />
                </button>
              </div>
            </div>
            {getFormErrorMessage("email")}
            {errorMessage && (
              <p className="text-placeholder text-alert-error">
                {errorMessage}
              </p>
            )}
            {successMessage && (
              <p className="text-placeholder text-alert-success">
                {successMessage}
              </p>
            )}
          </form>
        </div>
      </div>
      {/* stay up to date feature code for mobile and tablet */}
      <div className="lg:hidden w-1/2 pl-4 md:pl-20 py-8">
        <p className="text-white md:text-heading-4 font-semibold">
          Stay up to date
        </p>
        <form>
          <div className="flex footer-email">
            <input
              placeholder="Your email address"
              className=" focus:outline-none text-white rounded-l-md p-2.5 text-small mt-2 md:mt-4 bg-gray3 w-36 md:w-auto "
            />
            <div className="bg-gray3 flex justify-center items-center mt-2 md:mt-4 rounded-r-md">
              <button>
                <ShareIcon className="mx-3 mt-0.5" />
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="w-1/4 lg:pr-12 xl:pr-20 py-8 lg:py-0 ml-16 md:ml-28 lg:ml-0">
        <p className="md:text-heading-4 font-medium text-right text-white">
          Product by
        </p>
        <div className="flex justify-end pt-3 lg:pt-4">
          <Aptagrim className="w-40" />
        </div>
      </div>
    </div>
  );
}
