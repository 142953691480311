import { Link } from "react-router-dom";
import ReactPlayer from "react-player";

import { PrimaryButton } from "components/primaryButton";

import heroBg from "assets/images/landingpage/heroBg.png";
import HeroMobilebg from "assets/images/landingpage/HeroMobilebg.png";

export default function Hero() {
  return (
    <div className="pb-4 relative bg-black">
      {/* <div className="absolute top-0 left-0 w-full h-full -z-10">
        
      </div> */}
      <div
        className="h-screen px-5 md:pl-8 md:pr-0 flex md:items-center md:gap-x-10 pt-20 md:pt-0"
        // style={{
        //   background: `url(${window.innerWidth < 450 ? HeroMobilebg : heroBg})`,
        //   backgroundRepeat: "no-repeat",
        //   backgroundSize: "100% 100%",
        //   backgroundPosition: "cover",
        // }}
      >
        <div className="w-full md:w-1/2 md:mb-28">
          <p className="text-[28px] lg:[36px] xl:text-[42px] font-semibold leading-tight text-primary">
            Our vision is to{" "}
            <span className="text-white">
              make you hear what you want to see{" "}
            </span>
          </p>
          <p className=" md:text-heading-4 xl:text-heading-4 pt-5 text-white leading-snug">
            AI-powered assistive smart glasses for differently abled
          </p>
          <div className="flex pt-12 space-x-5">
            <a
              href="https://aptagrim.com/industries/healthcare"
              target="_blank"
              rel="noreferrer"
            >
              <button className="w-auto p-2 text-white text-sm font-semibold border-2 px-4 rounded-md border-white">
                Know more
              </button>
            </a>
            {/* <Link to="/products">
              <button>
                <PrimaryButton>
                  <p className=" leading-8 font-semibold">Order now</p>
                </PrimaryButton>
              </button>
            </Link> */}
          </div>
        </div>

        <div className="hidden md:grid w-1/2 h-full place-items-center">
          <video
            src="https://stgaptahr.blob.core.windows.net/aptai/AptaI-broll_v2_final.mp4"
            playsInline
            autoPlay
            loop
            muted
            className="w-full"
          />
        </div>
      </div>
    </div>
  );
}
