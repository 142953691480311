import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';

import cx from 'classnames';

const DropdownMenu = ({ children, triggerIcon }) => {
  return (
    <DropdownMenuPrimitive.Root>
      <DropdownMenuPrimitive.Trigger asChild>
        <button className='px-2 py-1 outline-none rounded'>
          {triggerIcon}
        </button>
      </DropdownMenuPrimitive.Trigger>

      <DropdownMenuPrimitive.Content
        align='end'
        sideOffset={10}
        className={cx(
          'radix-side-top:animate-slide-up radix-side-bottom:animate-slide-down',
          'w-36 rounded px-1.5 py-1 shadow-md md:w-44',
          'bg-white border border-opacity-20 border-font-placeholder'
        )}
      >
        {children}
      </DropdownMenuPrimitive.Content>
    </DropdownMenuPrimitive.Root>
  );
};

export default DropdownMenu;
