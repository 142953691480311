import { Outlet } from 'react-router-dom';

import { LandingHeader } from '../components/header';

const LandingLayout = () => {
  return (
    <div className='w-full'>
      <LandingHeader />
      <Outlet />
    </div>
  );
};

export default LandingLayout;
