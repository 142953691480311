import React, { useState } from "react";
import { Link } from "react-router-dom";

import { ReactComponent as AptaIlogo } from "assets/images/AptaILogo.svg";
import { ReactComponent as UserIlls } from "assets/images/landingpage/userIcon.svg";
import { ReactComponent as CalendarIlls } from "assets/images/landingpage/calendarIcon.svg";
import { ReactComponent as ContactIcon } from "assets/images/landingpage/contactIcon.svg";
import { ReactComponent as MenuIcon } from "assets/Icon/menuIcon.svg";
import { ReactComponent as CrossIcon } from "assets/Icon/crossIcon.svg";

import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

export default function LandingHeader() {
  const [notificationVisible, setNotificationPanelVisible] = useState(false);
  return (
    <div className="h-20 px-5 md:px-8 py-4 shadow-lg flex justify-between z-50 bg-white relative">
      <div className="flex items-center">
        <Link to="/">
          <AptaIlogo className="w-20 md:w-24" />
        </Link>
      </div>
      <div className="ml-auto flex md:hidden items-center">
        <MenuIcon
          onClick={() => setNotificationPanelVisible(!notificationVisible)}
        />
      </div>
      <div className="hidden ml-auto md:flex items-center space-x-3 md:space-x-8">
        {/* <Link to='/schedule-demo'>
          <div className='flex items-center space-x-2 select-none cursor-pointer text-434343'>
            <CalendarIlls />
            <p className='text-sm md:text-base'>Schedule Demo</p>
          </div>
        </Link> */}
        <Link to="/contact">
          <div className="flex items-center space-x-2 select-none cursor-pointer text-434343">
            <ContactIcon className="w-4" />
            <p className="text-sm md:text-base">Contact us</p>
          </div>
        </Link>
        {/* <div>
          <Link to="/auth/login">
            <button>
              <div className="w-auto p-2 text-white text-xs md:text-sm lg:text-base font-medium border px-3 md:px-6 rounded-md bg-primary flex items-center">
                <UserIlls className="w-3 md:w-4" />
                <p className="pl-1 font-medium">Sign in</p>
              </div>
            </button>
          </Link>
        </div> */}
      </div>

      {/* small screen menu */}
      <div>
        <Transition.Root show={notificationVisible} as={Fragment}>
          <Dialog
            as="div"
            auto-reopen="true"
            className="fixed inset-0 "
            style={{ zIndex: "2000" }}
            onClose={() => setNotificationPanelVisible(false)}
          >
            <Dialog.Overlay />
            <div className="absolute inset-0">
              <div className="fixed inset-x-0 bottom-0 flex">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-y-full"
                  enterTo="translate-y-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-y-0"
                  leaveTo="translate-y-full"
                >
                  <div className="relative w-screen max-w-md bg-FDF5F0 h-52 rounded-t-2xl p-4">
                    <div className="flex justify-end">
                      <button>
                        <CrossIcon
                          onClick={() => setNotificationPanelVisible(false)}
                        />
                      </button>
                    </div>
                    <div className="grid content-around h-48">
                      <Link to="/schedule-demo">
                        <div
                          className="flex w-full items-center"
                          onClick={() => setNotificationPanelVisible(false)}
                        >
                          <CalendarIlls className="w-4 mr-2" />
                          <p>Schedule Demo</p>
                        </div>
                      </Link>
                      <Link to="/contact">
                        <div
                          className="flex w-full items-center"
                          onClick={() => setNotificationPanelVisible(false)}
                        >
                          <ContactIcon className="w-4 mr-2" />
                          <p>Contact us</p>
                        </div>
                      </Link>
                      <div className="mb-8">
                        <Link to="/auth/login">
                          <button>
                            <div className="w-auto p-2 text-white text-xs md:text-sm lg:text-base font-medium border px-6 rounded-md bg-primary flex items-center">
                              <UserIlls className="w-3 md:w-4" />
                              <p className="pl-1 font-medium">Sign in</p>
                            </div>
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </div>
  );
}
