import React from "react";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";

import { PrimaryButton } from "components/primaryButton";
import { SecondaryButton } from "components/secondaryButton";

import { ReactComponent as VisitIcon } from "../../../assets/images/landingpage/visitIcon.svg";
import { ReactComponent as FaceCaptureIcon } from "../../../assets/images/landingpage/faceCaptureIcon.svg";
import { ReactComponent as BookIcon } from "../../../assets/images/landingpage/bookIcon.svg";

export default function PossibleWithAptaI() {
  return (
    <div>
      <p className="text-heading-4 md:text-heading-3 lg:text-heading-2 text-center font-semibold pt-12 lg:pt-20 pb-8 md:pb-12 bg-F9F9F9">
        What’s possible with Apta<span className="text-primary">I</span>
      </p>
      <div className="bg-F9F9F9 px-4 md:px-0 ">
        <div className="w-11/12 lg:w-10/12 xl:w-4/5 mx-auto md:flex md:space-x-10 lg:space-x-16 xl:space-x-20 ">
          <div className="md:w-1/2 rounded-lg">
            <ReactPlayer
              url="https://www.youtube.com/watch?v=k5ZJQ4lIGGI"
              width="auto"
              height="300px"
              controls={true}
            />
          </div>
          <div className="md:w-1/2 pt-3 lg:pt-6">
            <VisitIcon className="w-12 md:w-16 pt-1 " />
            <p className="w-full text-text-primary md:text-heading-4 lg:text-heading-3 font-semibold py-3">
              Identify people and surroundings
            </p>
            <p className="md:text-text-primary text-828282">
              Apta<span className="text-primary">I</span> leverages artificial
              intelligence to enable you to easily identify your surroundings
              and people along with their emotions.
            </p>
          </div>
        </div>
      </div>
      <div className="w-11/12 lg:w-10/12 xl:w-4/5 mx-auto flex flex-wrap-reverse md:flex-nowrap md:space-x-10 lg:space-x-16 xl:space-x-20 pt-8 md:pt-16 px-4 md:px-0">
        <div className="w-full md:w-1/2">
          <BookIcon className="w-10 md:w-12 pt-3 md:pt-0" />
          <p className="w-full text-text-primary md:text-heading-4 lg:text-heading-3 font-semibold py-3">
            Read a text
          </p>
          <p className="md:text-text-primary text-828282">
            Apta<span className="text-primary">I</span> narrates the world to
            you and helps you read the text in front of you making your life
            easier.
          </p>
        </div>
        <div className="w-full md:w-1/2 rounded-lg">
          <ReactPlayer
            url="https://youtu.be/LEXTsHTqGyA"
            width="auto"
            height="300px"
            controls={true}
          />
        </div>
      </div>
      <div className="w-11/12 lg:w-10/12 xl:w-4/5 mx-auto md:flex md:space-x-10 lg:space-x-16 xl:space-x-20 pt-8 md:pt-16 px-4 md:px-0">
        <div className="w-full md:w-1/2 rounded-lg">
          <ReactPlayer
            url="https://www.youtube.com/watch?v=ra1G0B1ed48"
            width="auto"
            height="300px"
            controls={true}
          />
        </div>
        <div className="w-full md:w-1/2 xl:pt-6">
          <FaceCaptureIcon className="w-9 md:w-10 lg:w-12 pt-4 md:pt-0" />
          <p className="w-full text-text-primary md:text-heading-4 lg:text-heading-3 font-semibold py-3 md:py-2 lg:pt-4 lg:pb-3">
            Face, Gender & Emotion Recognition
          </p>
          <p className="md:text-text-primary text-828282">
            Intelligent, AI-based facial recognition technology to easily
            identify people along with their emotions. It identifies known
            people and trains unkown people to identify the next time.
          </p>
          <p className="text-text-primary text-828282">
            Apta<span className="text-primary">I</span> also enables you to
            identify someone’s gender by just looking at them.
          </p>
        </div>
      </div>
      {/* <div className='flex justify-center pt-8 md:pt-12 lg:pt-16 space-x-5'>
        <div>
          <Link to='/schedule-demo'>
            <SecondaryButton>
              <p className='leading-7 whitespace-nowrap'>Schedule a demo</p>
            </SecondaryButton>
          </Link>
        </div>
        <div>
          <Link to='/products'>
            <button>
              <PrimaryButton>
                {' '}
                <p className='leading-7'>Order now</p>
              </PrimaryButton>
            </button>
          </Link>
        </div>
      </div> */}
    </div>
  );
}
