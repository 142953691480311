import React from "react";

import aptaIBenifitImg from '../../../assets/images/landingpage/aptaIBenifitImg.png';

export default function AptaIBenifit() {
  return (
    <div className='md:pb-16 -mt-20 w-11/12 mx-auto lg:px-6 pt-8 md:pt-0'>
      <p className='md:hidden text-center text-heading-4 font-semibold pb-5'>
        Who can benefit from Apta<span className='text-primary'>I</span>
      </p>
      <div className='flex md:pt-12'>
        <div className='w-1/2'>
          <img src={aptaIBenifitImg} alt='' className='h-full' />
        </div>
        <div className='w-1/2 pl-4 md:pl-8 lg:pl-16 flex items-center'>
          <div>
            <p className='hidden md:block text-heading-4 md:text-heading-3 lg:text-heading-2 font-semibold pb-4'>
              Who can benefit from Apta<span className='text-primary'>I</span>
            </p>
            <ul className='text-515151 text-placeholder md:text-text-primary lg:text-heading-4'>
              <li>Cataracts</li>
              <li className='py-2'>Stargardt’s Disease</li>
              <li>Diabetic Retinopathy</li>
              <li className='py-2'>Macular Degeneration</li>
              <li>Ocular Albinism</li>
              <li className='py-2'>Optic Atrophy</li>
              <li>Retinopathy of Prematurity</li>
            </ul>
            {/* <div className='pt-5 hidden md:block'>
              <SecondaryButton>Know more</SecondaryButton>
            </div> */}
          </div>
        </div>
      </div>
      {/* <div className='pt-6 flex justify-center md:hidden'>
        <SecondaryButton>Know more</SecondaryButton>
      </div> */}
    </div>
  );
}
