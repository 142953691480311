import React, { useEffect } from 'react';
import Footer from 'pages/landing/components/footer';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndPoints';
import DOMPurify from 'dompurify';

import checkoutBg from 'assets/images/scheduleDemo/checkoutBg.png';
import PrimaryButton from 'components/primaryButton/primaryButton';

export default function ProductsList() {
  const getProductOrderDetails = useQuery(
    ['getUserOrderDetails'],
    async () => {
      const resData = await makeAPIRequest.get(`${apiEndPoints.PRODUCT_INFO}`);
      return resData?.data;
    },
    {
      onSuccess: (data) => {},
    }
  );

  const createMarkup = (data) => {
    return { __html: DOMPurify.sanitize(data) };
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  return (
    <div className='bg-F9F9F9'>
      <div className='pt-10 px-8 max-w-main-layout mx-auto'>
        <p className='text-heading-3 text-515151 lg:pb-80'>
          Information <span className='text-black'>&#62; Products</span>
        </p>
      </div>
      <div className='bg-FDF5F0 pb-10'>
        <div
          className='px-8 max-w-main-layout mx-auto md:flex flex-wrap lg:flex-nowrap justify-around lg:space-x-8 xl:space-x-12 pb-10'
          style={{
            backgroundImage: `url(${checkoutBg})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'bottom',
          }}
        >
          {getProductOrderDetails?.data?.data?.map((item) => (
            <div
              className='w-full md:w-1/2 lg:w-1/3 bg-white border border-font-placeholder border-opacity-10 rounded-xl p-8 mt-20 lg:-mt-52 shadow-lg md:-ml-4 lg:-ml-0'
              key={item.id}
            >
              <img
                src={item.image}
                alt='ProductImage'
                className='-mt-24 w-40 mx-auto rounded-full'
              />
              <p className='text-heading-4 pt-10'>{item?.title}</p>
              <p className='text-heading-3 py-2'>
                <span className='text-primary font-semibold mr-1'>$</span>{' '}
                {item?.amount}
              </p>
              <p className='font-medium text-text-primary pt-1'>Description</p>
              <p className='text-text-primary text-828282 pt-1'>
                {item?.description}
              </p>
              <p className='font-medium text-text-primary pt-4'>
                Specifications
              </p>
              <div
                dangerouslySetInnerHTML={createMarkup(item.features)}
                className='text-text-primary text-828282 markup-content'
              ></div>
              <div className='mt-12 xl:mt-10 flex justify-center'>
                <Link to={`/checkout/${item.id}`}>
                  <button>
                    <PrimaryButton>Buy now</PrimaryButton>
                  </button>
                </Link>
              </div>
            </div>
          ))}
        </div>
        {/* <img src={checkoutBg} alt=' ' className='mx-auto pt-4' /> */}
      </div>
      <Footer />
    </div>
  );
}
