import React, { useState } from 'react';
import SecondaryButton from 'components/secondaryButton/secondaryButton';
import PrimaryButton from 'components/primaryButton/primaryButton';
import { useQuery } from 'react-query';
import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndPoints';
import dateFormatter from 'helper/dateFormatter';
import SoftwareUpdateModal from './components/softwareUpdateModal';

import { ReactComponent as DownloadIcon } from 'assets/images/mydevice/downloadIcon.svg';

export default function MyDevicePage() {
  const [isOpen, setIsOpen] = useState(false);

  // get device details
  const getDeviceDetails = useQuery(
    ['get-user-device-details'],
    async () => {
      const resData = await makeAPIRequest.get(
        `${apiEndPoints.DEVICECRUDAPI}?user_id=${JSON.parse(
          localStorage.getItem('user_id')
        )}&fields=device_id,activation_date,product__image`
      );
      return resData?.data;
    },
    {
      onSuccess: (data) => {},
    }
  );

  return (
    <div>
      <p className='text-primary text-heading-4 font-medium'>My Device</p>
      <div className='flex'>
        <div className='mt-6 w-40 '>
          <img
            src={getDeviceDetails?.data?.data[0]?.product__image}
            alt='device'
            className='w-40 border-3 border-primary border-opacity-70 rounded-lg'
          />
        </div>
        <div className='flex items-center ml-8'>
          <table>
            <tbody>
              <tr>
                <td className='text-434343 pb-4'>Device ID</td>
                <td>
                  <p className='px-4 515151 pb-4'>:</p>
                </td>
                <td className='text-font-primary pb-4'>
                  {getDeviceDetails?.data?.data[0]?.device_id}
                </td>
              </tr>
              <tr>
                <td className='text-434343'>Activated on</td>
                <td>
                  <p className='px-4 515151'>:</p>
                </td>
                <td className='text-font-primary'>
                  {dateFormatter(
                    getDeviceDetails?.data?.data[0]?.activation_date,
                    'DD MMM YYYY, hh:mm A'
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className='flex items-center ml-auto'>
          <button className='whitespace-nowrap cursor-default opacity-60 pb-6'>
            <SecondaryButton>
              <div className='flex items-center'>
                <DownloadIcon className='mr-1' />
                Download invoice
              </div>
            </SecondaryButton>
          </button>
        </div>
      </div>
      <div>
        <p className='text-font-primary font-semibold text-heading-4 mt-8'>
          Software update
        </p>
        <p className='text-text-primary text-434343 pt-2'>
          Lorem ipsum dolor sit amet, consectetur adipiscing{' '}
        </p>
        <p className='pt-2 text-gray2'>
          Lorem ipsum dolor sit amet, consectetur adipiscing Lorem ipsum dolor
          sit amet, consectetur adipiscing Lorem ipsum dolor sit amet,
          consectetur adipiscing Lorem ipsum dolor sit amet, consectetur
          adipiscing Lorem ipsum dolor sit amet, consectetur adipiscing Lorem
          ipsum dolor sit amet, consectetur adipiscing{' '}
        </p>
        <p className='pt-4 text-alert-info cursor-pointer select-none' onClick={()=>setIsOpen(true)}>
          See what’s in this update
        </p>
        <button className='whitespace-nowrap pt-7 cursor-default opacity-60'>
          <PrimaryButton>Download and update</PrimaryButton>
        </button>
      </div>
      <SoftwareUpdateModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
}
