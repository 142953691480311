import React, { useState } from "react";
import { useQuery } from "react-query";
import Slider from "react-slick";

import makeAPIRequest from "services/makeApiRequest";
import apiEndPoints from "services/apiEndPoints";

import activeslickdot from "assets/images/landingpage/activeslickdot.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

var dayjs = require("dayjs");

export default function InNews() {
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    // custom slick dots
    customPaging: function (i) {
      if (i === currentSlide) {
        return (
          <div className="flex items-end">
            <img src={activeslickdot} alt=" " className="h-1 rounded-full" />
          </div>
        );
      } else {
        return <p className="text-heading-3 font-extrabold text-C1C1C1">.</p>;
      }
    },
    dotsClass: "slick-dots slick-thumb",
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: window.innerWidth < 470 ? 1 : window.innerWidth < 770 ? 2 : 3,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    beforeChange: (prev, next) => {
      setCurrentSlide(next);
    },
  };

  // const getNewsData = useQuery(['getNewsData'], async () => {
  //   const resData = await makeAPIRequest.get(`${apiEndPoints.NEWS_UPDATES}`);
  //   return resData?.data;
  // });

  const getNewsData = {
    data: {
      data: [
        {
          id: 1,
          image: "/images/hysea_event_pic_1.png",
          description:
            "Aptagrim showcasing AptaI at HYSEA. Recognized as one of the top ten startups of Hyderabad-2021",
          date_posted_on_news: "16 Dec, 2021",
        },
        {
          id: 2,
          image: "/images/braile_img_1.png",
          description:
            "The intelligence is artificial but the sight, natural. An INDIA AI arcticle on AptaI.",
          date_posted_on_news: "25 Jun, 2020",
        },
        {
          id: 3,
          image: "/images/Group 14847.png",
          description:
            "AI-powered smart glass for visually impaired. An INDIA AI arcticle on AptaI.",
          date_posted_on_news: "04 Feb, 2020",
        },
        {
          id: 4,
          image: "/images/Group 14849.png",
          description:
            "Aptagrim (Singapore) gets selected for the SCALE program conducted by SMU and ISEAD,supported by Enterprise Singapore",
          date_posted_on_news: "Oct, 2019",
        },
        {
          id: 5,
          image: "/images/Group 14850.png",
          description:
            "Aptagrim featured in NASSCOM’s AI for Good Compendium report 2019.",
          date_posted_on_news: "Jul, 2019",
        },
        {
          id: 6,
          image: "/images/Group 14626.png",
          description:
            "APTAGRIM showcases their new Artificial Intelligence solution AptaI at NASSCOM event-AI for Good. AptaI is a AI wearable device transforming the lives of the blind and the visually impaired.",
          date_posted_on_news: "16 Apr, 2019",
        },
      ],
    },
  };

  return (
    <div className="pb-12 md:pb-20 pt-10 md:pt-4">
      <p className="text-center font-semibold text-heading-4 md:text-heading-3 lg:text-heading-2">
        In the news
      </p>
      <div className="w-11/12 mx-auto mt-4 md:mt-8">
        <Slider {...settings}>
          {getNewsData?.data?.data?.map((item) => (
            <div key={item?.id}>
              <div className="px-6">
                <div>
                  <img src={item?.image} alt="hyseaImage" className="w-full" />
                </div>
                <p className="text-828282 py-2 text-placeholder md:text-base lg:text-text-primary">
                  {item?.description}
                </p>
                <p className="text-primary text-placeholder md:text-base lg:text-text-primary pb-2">
                  {dayjs(item?.date_posted_on_news).format("DD MMM YYYY")}
                </p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}
