import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import PrimaryButton from './primaryButton/primaryButton';
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from 'react-query';
import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndPoints';

import { SuccessToast } from 'services/toasterService';

import { ReactComponent as CancelIcon } from 'assets/images/trackorder/cancelIcon.svg';
import { ReactComponent as NotSatisfiedEmoji } from 'assets/images/mydevice/notSatisfiedEmoji.svg';
import { ReactComponent as SatisfiedEmoji } from 'assets/images/mydevice/satisfiedEmoji.svg';
import { ReactComponent as HighlySatisfiedEmoji } from 'assets/images/mydevice/highlySatisfiedEmoji.svg';
import { ReactComponent as RecommendEmoji } from 'assets/images/mydevice/recommendEmoji.svg';
import { ReactComponent as CanDoBetterEmoji } from 'assets/images/mydevice/canDoBetterEmoji.svg';

export default function UserFeedbackModal({
  isFeedbackModalOpen,
  setFeedbackModalOpen,
}) {
  const [errorMessage, setErrorMessage] = useState('');
  const [feedbackText, setFeedbackText] = useState('');

  function closeModal() {
    setFeedbackModalOpen(false);
    setFeedbackText('');
    reset({ message: '' });
  }

  const defaultValues = {
    message: '',
  };

  const { control, handleSubmit, reset, watch } = useForm({ defaultValues });

  const feedbackMessage = watch('message');

  const userFeedbackMutation = useMutation(
    async (data) => {
      const res = await makeAPIRequest.post(apiEndPoints.USER_FEEDBACK, data);
      return res?.data;
    },
    {
      onSuccess: (data) => {
        SuccessToast({ text: 'Feedback submitted successfully' });
        setFeedbackModalOpen(false);
        setFeedbackText('');
        reset();
      },
      onError: (err) => {
        setErrorMessage(err.response.data.message);
        setTimeout(() => {
          setErrorMessage('');
        }, 3000);
      },
    }
  );

  const onSubmit = (data) => {
    const newData = {
      message: data?.message,
      rating_text: feedbackText,
      rating_val:
        feedbackText === 'Not satisfied'
          ? 1
          : feedbackText === 'Can do better'
          ? 2
          : feedbackText === 'Satisfied'
          ? 3
          : feedbackText === 'Highly satisfied'
          ? 4
          : 5,
    };
    userFeedbackMutation.mutate(newData);
  };

  return (
    <>
      <Transition appear show={isFeedbackModalOpen} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-10 overflow-y-auto'
          onClose={closeModal}
        >
          <div className='min-h-screen px-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-black opacity-50' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className='inline-block h-screen align-middle'
              aria-hidden='true'
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <div className='inline-block w-3/4 xl:w-1/2 max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-xl relative'>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='absolute right-4'>
                    <CancelIcon
                      className='ml-auto cursor-pointer'
                      onClick={() => closeModal()}
                    />
                  </div>
                  <p className='text-heading-4 text-primary font-semibold text-center'>
                    Feedback
                  </p>
                  <p className='text-center text-font-tertiary text-text-primary'>
                    How satisfied are you with the product
                  </p>
                  <div className='pt-12 pb-6 flex justify-around'>
                    <div
                      className={`rounded-lg w-28 select-none cursor-pointer py-2 border-3 ${
                        feedbackText === 'Not satisfied'
                          ? 'border-primary '
                          : 'border-white'
                      }`}
                      onClick={() => setFeedbackText('Not satisfied')}
                    >
                      <NotSatisfiedEmoji className='mx-auto' />
                      <p className='text-gray text-center text-small pt-2'>
                        Not satisfied
                      </p>
                    </div>
                    <div
                      className={`rounded-lg w-28 select-none cursor-pointer py-2 border-3 ${
                        feedbackText === 'Can do better'
                          ? 'border-primary '
                          : 'border-white'
                      }`}
                      onClick={() => setFeedbackText('Can do better')}
                    >
                      <CanDoBetterEmoji className='mx-auto' />
                      <p className='text-gray text-small text-center pt-2'>
                        Can do better
                      </p>
                    </div>
                    <div
                      className={`rounded-lg w-28 select-none cursor-pointer py-2 border-3 ${
                        feedbackText === 'Satisfied'
                          ? 'border-primary '
                          : 'border-white'
                      }`}
                      onClick={() => setFeedbackText('Satisfied')}
                    >
                      <SatisfiedEmoji className='mx-auto' />
                      <p className='text-gray text-small text-center pt-2'>
                        Satisfied
                      </p>
                    </div>
                    <div
                      className={`rounded-lg w-28 select-none cursor-pointer py-2 border-3 ${
                        feedbackText === 'Highly satisfied'
                          ? 'border-primary '
                          : 'border-white'
                      }`}
                      onClick={() => setFeedbackText('Highly satisfied')}
                    >
                      <HighlySatisfiedEmoji className='mx-auto' />
                      <p className='text-gray text-small text-center pt-2'>
                        Highly satisfied
                      </p>
                    </div>
                    <div
                      className={`rounded-lg w-28 select-none cursor-pointer py-2 border-3 ${
                        feedbackText === 'Will recommend'
                          ? 'border-primary '
                          : 'border-white'
                      }`}
                      onClick={() => setFeedbackText('Will recommend')}
                    >
                      <RecommendEmoji className='mx-auto' />
                      <p className='text-gray text-center text-small pt-2 whitespace-nowrap'>
                        Will recommend
                      </p>
                    </div>
                  </div>
                  <div className='px-4'>
                    <label className='text-font-placeholder pl-1'>
                      Message
                    </label>
                    <Controller
                      name='message'
                      control={control}
                      rules={{
                        required: 'This field is required',
                      }}
                      render={({ field }) => (
                        <textarea
                          {...field}
                          className='base-textarea h-36 mt-1'
                        />
                      )}
                    />
                  </div>
                  {errorMessage && (
                    <p className='text-alert-error text-small text-center'>
                      {errorMessage}
                    </p>
                  )}
                  <div className='flex justify-center mt-6'>
                    <button
                      disabled={!feedbackText || !feedbackMessage}
                      className={`${
                        !feedbackMessage || !feedbackText ? 'opacity-70' : ''
                      }`}
                    >
                      <PrimaryButton
                        isLoading={userFeedbackMutation?.isLoading}
                      >
                        Submit
                      </PrimaryButton>
                    </button>
                  </div>
                </form>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
