import React, { useRef } from "react";
import Hero from "./components/hero";
import AptaIFeatures from "./components/aptaIFeatures";
import PossibleWithAptaI from "./components/possibleWithAptaI";
import HappyUsers from "./components/happyUsers";
import AptaIBenifit from "./components/aptaIBenifit";
import ProductGallery from "./components/productGallery";
import InNews from "./components/inNews";
import GetInTouch from "./components/getInTouch";
import Footer from "./components/footer";

export default function LandingPage() {
  const testimonial = useRef(null);

  const scrollToRef = (ref) =>
    window.scrollTo({ top: ref.current.offsetTop, behavior: "smooth" });

  const executeScrollToTestimonial = () => scrollToRef(testimonial);

  return (
    <div>
      <Hero />
      <AptaIFeatures />
      <PossibleWithAptaI />
      <div ref={testimonial}>
        {" "}
        <HappyUsers />
      </div>
      <ProductGallery />
      <AptaIBenifit />
      <InNews />
      <GetInTouch />
      <Footer testimonialRef={executeScrollToTestimonial} />
    </div>
  );
}
