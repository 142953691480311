import React, { useRef, useEffect } from 'react';
import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndPoints';

import { useNavigate } from 'react-router-dom';

export default function Paypal({ email, id, couponId }) {
  const paypal = useRef();
  let navigate = useNavigate();

  useEffect(() => {
    let payloadData;
    if (couponId > 0) {
      payloadData = {
        email_or_number: email,
        product_id: id,
        coupon_obj_id: couponId,
      };
    } else {
      payloadData = {
        email_or_number: email,
        product_id: id,
      };
    }
    window.paypal
      .Buttons({
        style: {
          layout: 'vertical',
          color: 'blue',
          shape: 'rect',
          label: 'paypal',
        },
        fundingSource: window.paypal.FUNDING.PAYPAL,
        createOrder: (data, actions, err) => {
          return makeAPIRequest
            .post(apiEndPoints.PAYPAL_PAYMENT_API, payloadData)
            .then(function (res) {
              return res.data.data;
            })
            .then(function (orderData) {
              return orderData.id;
            });
          // actions.order.create({
          //   intent: 'CAPTURE',
          //   purchase_units: [
          //     {
          //       description: 'Cool looking table',
          //       amount: {
          //         currency_code: 'CAD',
          //         value: 650.0,
          //       },
          //     },
          //   ],
          // });
        },
        onApprove: async (data, actions) => {
          (async () => {
            await makeAPIRequest.put(apiEndPoints.PAYPAL_PAYMENT_API, {
              order_cancel: false,
              paypal_order_id: data.orderID,
            });
            navigate(`/payment-success`);
          })();
        },
        onError: (err) => {
          console.log(err);
        },
        onCancel: (dataOnCancel) => {
          // Show a cancel page, or return to cart
          (async () => {
            await makeAPIRequest.put(apiEndPoints.PAYPAL_PAYMENT_API, {
              order_cancel: true,
              paypal_order_id: dataOnCancel.orderID,
            });
            navigate(`/checkout/${id}`);
          })();
        },
      })
      .render(paypal.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div ref={paypal}></div>
    </div>
  );
}
