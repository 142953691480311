import { ReactComponent as PersonIcon } from '../../../assets/images/landingpage/personIcon.svg';
import { ReactComponent as BookIcon } from '../../../assets/images/landingpage/bookIcon.svg';
import { ReactComponent as CartIcon } from '../../../assets/images/landingpage/cartIcon.svg';
import { ReactComponent as CommunicateIcon } from '../../../assets/images/landingpage/communicateIcon.svg';
import { ReactComponent as LocationIcon } from '../../../assets/images/landingpage/locationIcon.svg';
import { ReactComponent as FaceIcon } from '../../../assets/images/landingpage/faceIcon.svg';
import { ReactComponent as CallIlls } from '../../../assets/images/landingpage/callIcon.svg';
import { ReactComponent as PlantIcon } from '../../../assets/images/landingpage/plantIcon.svg';
import aptaIFeaturesBg from '../../../assets/images/landingpage/aptaIFeaturesBg.png';

export default function AptaIFeatures() {
  return (
    <div
      className='pt-12'
      style={{
        background: `url(${aptaIFeaturesBg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 50%',
        backgroundPosition: 'bottom',
      }}
    >
      <p className='text-center text-heading-4 md:text-heading-3 lg:text-heading-2 font-semibold'>
        AI-powered assistive smart glasses
      </p>
      <p className='text-515151 md:text-heading-4 text-center pt-4 px-2'>
        Transforming the lives of the{' '}
        <span className='text-primary'>visually impaired</span> with{' '}
        <span className='text-primary'>AI. </span>
        <span className='md:hidden'>
          Assistive smart glasses to breeze through the day
        </span>
      </p>
      <p className='text-515151 hidden md:block md:text-heading-4 text-center'>
        {' '}
        Assistive smart glasses to breeze through the day
      </p>
      <div className='grid grid-cols-4 gap-6 lg:gap-10 mt-12 lg:mt-16 px-4 md:px-16 xl:px-28'>
        <div className='flex justify-center flex-wrap col-span-2 lg:col-span-1'>
          <div className='rounded-full bg-white shadow-lg w-20 md:w-24 h-20 md:h-24 flex items-center justify-center border border-font-placeholder border-opacity-20 mb-5'>
            <PersonIcon className='p-4 md:p-5' />
          </div>
          <p className='w-full text-center text-small md:text-heading-4 text-515151'>
            Say hello to your friends
          </p>
        </div>
        <div className='flex justify-center flex-wrap col-span-2 lg:col-span-1'>
          <div className='rounded-full bg-white shadow-lg w-20 md:w-24 h-20 md:h-24 flex items-center justify-center border border-font-placeholder border-opacity-20 mb-5'>
            <BookIcon className='p-6 md:p-7' />
          </div>
          <p className='w-full text-center text-sm md:text-heading-4 text-515151'>
            Read a book
          </p>
        </div>
        <div className='flex justify-center flex-wrap col-span-2 lg:col-span-1'>
          <div className='rounded-full bg-white shadow-lg w-20 md:w-24 h-20 md:h-24 flex items-center justify-center border border-font-placeholder border-opacity-20 mb-5'>
            <LocationIcon className='p-6 md:p-7' />
          </div>
          <p className='w-full text-center text-sm md:text-heading-4 text-515151'>
            Explore your surroundings
          </p>
        </div>
        <div className='flex justify-center flex-wrap col-span-2 lg:col-span-1'>
          <div className='rounded-full bg-white shadow-lg w-20 md:w-24 h-20 md:h-24 flex items-center justify-center border border-font-placeholder border-opacity-20 mb-5'>
            <FaceIcon className='p-5 md:p-6 mt-3' />
          </div>
          <p className='w-full text-center text-sm md:text-heading-4 text-515151'>
            Detect emotion and gender
          </p>
        </div>
        <div className='flex justify-center flex-wrap col-span-2 lg:col-span-1'>
          <div className='rounded-full bg-white shadow-lg w-20 md:w-24 h-20 md:h-24 flex items-center justify-center border border-font-placeholder border-opacity-20 mb-5'>
            <CallIlls className='p-7 md:p-8' />
          </div>
          <p className='w-full text-center text-sm md:text-heading-4 text-515151'>
            Call anyone without effort
          </p>
        </div>
        <div className='flex justify-center flex-wrap col-span-2 lg:col-span-1'>
          <div className='rounded-full bg-white shadow-lg w-20 md:w-24 h-20 md:h-24 flex items-center justify-center border border-font-placeholder border-opacity-20 mb-5'>
            <CommunicateIcon className='p-7 md:p-8' />
          </div>
          <p className='w-full text-center text-sm md:text-heading-4 text-515151'>
            Commute with ease
          </p>
        </div>
        <div className='flex justify-center flex-wrap col-span-2 lg:col-span-1'>
          <div className='rounded-full bg-white shadow-lg w-20 md:w-24 h-20 md:h-24 flex items-center justify-center border border-font-placeholder border-opacity-20 mb-5'>
            <CartIcon className='p-7 md:p-8' />
          </div>
          <p className='w-full text-center text-sm md:text-heading-4 text-515151'>
            Do regular activities hassle-free
          </p>
        </div>
        <div className='flex justify-center flex-wrap col-span-2 lg:col-span-1'>
          <div className='rounded-full bg-white shadow-lg w-20 md:w-24 h-20 md:h-24 flex items-center justify-center border border-font-placeholder border-opacity-20 mb-8'>
            <PlantIcon className='p-7 md:p-8' />
          </div>
          <p className='w-full text-center text-sm md:text-heading-4 text-515151'>
            Enjoy hobbies like gardening and cooking
          </p>
        </div>
      </div>
      {/* <div className='grid grid-cols-3 lg:grid-cols-5 gap-6 lg:gap-10 mt-12 lg:mt-16 px-4 md:px-12'>
        <div className='flex justify-center flex-wrap'>
          <div className='rounded-full bg-white shadow-lg w-20 md:w-24 h-20 md:h-24 flex items-center justify-center border border-font-placeholder border-opacity-20 -mb-2'>
            <CommunicateIcon className='p-7 md:p-8' />
          </div>
          <p className='w-full text-center text-sm md:text-heading-4 text-515151'>
            Commute with ease
          </p>
        </div>
        <div className='flex justify-center flex-wrap'>
          <div className='rounded-full bg-white shadow-lg w-20 md:w-24 h-20 md:h-24 flex items-center justify-center border border-font-placeholder border-opacity-20 mb-5'>
            <CartIcon className='p-6 md:p-7' />
          </div>
          <p className='w-full text-center text-sm md:text-heading-4 text-515151'>
            Do regular activities hassle-free
          </p>
        </div>
        <div className='flex justify-center flex-wrap'>
          <div className='rounded-full bg-white shadow-lg w-20 md:w-24 h-20 md:h-24 flex items-center justify-center border border-font-placeholder border-opacity-20 mb-5'>
            <PlantIcon className='p-7 md:p-8' />
          </div>
          <p className='w-full text-center text-sm md:text-heading-4 text-515151'>
            Enjoy hobbies like gardening and cooking
          </p>
        </div>
      </div> */}
    </div>
  );
}
