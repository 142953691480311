import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';

import { ReactComponent as CancelIcon } from 'assets/images/trackorder/cancelIcon.svg';

export default function SoftwareUpdateModal({ isOpen, setIsOpen }) {
  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-10 overflow-y-auto'
          onClose={closeModal}
        >
          <div className='h-full px-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-black opacity-40' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className='inline-block h-screen align-middle'
              aria-hidden='true'
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <div className='inline-block w-2/5 p-6 my-8 h-3/4 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-xl'>
                <div className='flex justify-end mb-2'>
                  <CancelIcon
                    className='cursor-pointer'
                    onClick={() => closeModal()}
                  />
                </div>
                <p className='text-primary font-semibold text-heading-4'>
                  Software update
                </p>
                <p className='text-text-primary font-medium text-font-secondary pt-2'>
                  Lorem ipsum dolor sit amet, consectetur adipiscing{' '}
                </p>
                <p className='pt-2 text-font-placeholder'>
                  Lorem ipsum dolor sit amet, consectetur adipiscing Lorem ipsum
                  dolor sit amet, consectetur adipiscing Lorem ipsum dolor sit
                  amet, consectetur adipiscing Lorem ipsum dolor sit amet,
                  consectetur adipiscing Lorem ipsum dolor sit amet, consectetur
                  adipiscing Lorem ipsum dolor sit amet, con sum dolor sit amet,
                  consectetur adipiscing Lorem ipsum dolor sit amet, consectetur
                  adipiscing Lorem ipsum dolor sit amet, consectetur adipiscing
                  Lorem ipsum dolor sit a met, dolor sit amet, consectetur
                  adipisc ing ng Lorem ipsum dolor sit amet, consectetur
                  adipiscing Lorem ipsum dolor sit amet, consectetur.
                </p>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
