import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';

import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndPoints';

import successBgImage from 'assets/images/scheduleDemo/successBgImage.png';

export default function PaymentSuccessPage() {
  const location = useLocation();

  // update stripe payment status
  const updateStripePaymentMutation = useMutation(async (data) => {
    const res = await makeAPIRequest.put(apiEndPoints.STRIPE_PAYMENT_API, data);
    return res.data;
  });

  useEffect(() => {
    updateStripePaymentMutation.mutate({
      stripe_order_id: location.search.split('=')[1],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  return (
    <div
      className='h-screen -mt-20 pt-40'
      style={{
        background: `url(${successBgImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        backgroundPosition: 'cover',
      }}
    >
      <lottie-player
        autoplay
        mode='normal'
        speed={1}
        style={{ width: '300px', height: '350px', margin: 'auto' }}
        src='https://assets10.lottiefiles.com/packages/lf20_7br3ojw5.json'
      ></lottie-player>
      <p className='text-center text-lg px-4'>
        Thank you, your payment has been processed.
      </p>
    </div>
  );
}
