import { useState, useEffect, useCallback, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';

import { CountDown } from './components/countDown';
import PayWithPaypalButton from './components/PaypalButton';

import Footer from '../landing/components/footer';
import { PrimaryButton } from 'components/primaryButton';
import Loader from 'components/Loader';

import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndPoints';

import { ReactComponent as QuestionIcon } from 'assets/images/scheduleDemo/questionIcon.svg';
import { ReactComponent as TickIcon } from 'assets/images/scheduleDemo/tickIcon.svg';
import { ReactComponent as Stripe } from 'assets/images/scheduleDemo/stripe.svg';
import { ReactComponent as SuccessIlls } from 'assets/images/scheduleDemo/successIlls.svg';
import { ReactComponent as ErrorIlls } from 'assets/images/scheduleDemo/errorIlls.svg';
// import { ReactComponent as Paypal } from 'assets/images/scheduleDemo/paypal.svg';
// import checkoutBg from '../../assets/images/scheduleDemo/checkoutBg.png';

export default function CheckoutPage() {
  const params = useParams();
  const location = useLocation();
  const paypal = useRef();

  const [userEmail, setUserEmail] = useState();
  const [otp, setOtp] = useState('');
  const [otpSentStatus, setOptSentStatus] = useState(false);
  const [isFormDisabled, setFormDisabled] = useState(true);
  const [isBillingSame, setBillingSame] = useState(true);
  const [enableVarifyUser, setEnableUser] = useState(false);
  const [isUserExit, setUserExit] = useState(false);
  const [showPaymentGateway, setShowPaymentgateway] = useState(false);
  const [shippingCodeDetails, setShippingCodeDetails] = useState();
  const [billingCodeDetails, setBillingCodeDetails] = useState();
  const [couponCode, setCouponCode] = useState('');
  const [couponId, setCouponId] = useState('');
  const [isPaymentCompleted, setPaymentCompleted] = useState(false);

  useEffect(() => {
    if (otp.length === 6) {
      verifyOtp.mutate({ email_or_number: userEmail, otp: otp });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otp]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  // get countries Data
  const getCountriesData = useQuery(
    ['getCountriesList'],
    async () => {
      const resData = await makeAPIRequest.get(
        `${apiEndPoints.COUNTRIES_LIST}`
      );
      return resData?.data;
    },
    { staleTime: 180000 }
  );

  // get all states data of selected country for shipping details
  const getStatesData = useQuery(
    ['getStatesList', shippingCodeDetails],
    async () => {
      const resData = await makeAPIRequest.get(
        `${apiEndPoints.STATES_LIST}?country_code=${shippingCodeDetails?.countryCode}`
      );
      return resData?.data;
    },
    {
      staleTime: 180000,
      enabled: shippingCodeDetails?.countryCode?.length > 1,
    }
  );

  // get all states data of selected country for billing details
  const getStatesDataForBilling = useQuery(
    ['getStatesListForBilling', billingCodeDetails?.countryCode],
    async () => {
      const resData = await makeAPIRequest.get(
        `${apiEndPoints.STATES_LIST}?country_code=${billingCodeDetails?.countryCode}`
      );
      return resData?.data;
    },
    {
      staleTime: 180000,
      enabled: billingCodeDetails?.countryCode?.length > 1,
    }
  );

  // get all cities data of selected country for shipping details
  const getCitiesData = useQuery(
    [
      'getCitiesList',
      shippingCodeDetails?.countryCode,
      shippingCodeDetails?.stateCode,
    ],
    async () => {
      const resData = await makeAPIRequest.get(
        `${apiEndPoints.CITIES_LIST}?country_code=${shippingCodeDetails?.countryCode}&state_code=${shippingCodeDetails?.stateCode}`
      );
      return resData?.data;
    },
    {
      staleTime: 180000,
      enabled: shippingCodeDetails?.stateCode?.length > 1,
    }
  );

  // get all cities data of selected country for shipping details
  const getCitiesDataForBilling = useQuery(
    [
      'getCitiesListForBilling',
      billingCodeDetails?.countryCode,
      billingCodeDetails?.stateCode,
    ],
    async () => {
      const resData = await makeAPIRequest.get(
        `${apiEndPoints.CITIES_LIST}?country_code=${billingCodeDetails?.countryCode}&state_code=${billingCodeDetails?.stateCode}`
      );
      return resData?.data;
    },
    {
      staleTime: 180000,
      enabled: billingCodeDetails?.stateCode?.length > 1,
    }
  );

  // get products data
  const getProductsData = useQuery(['getProductsData', params], async () => {
    const resData = await makeAPIRequest.get(
      `${apiEndPoints.PRODUCT_INFO}?id=${params?.id}`
    );
    return resData?.data;
  });

  const defaultValues = {
    email: location.search.includes('user_email_or_number')
      ? location.search.split('?')[1].split('=')[1]
      : '',
  };

  const defaultValuesforShipping = {
    is_billing_same: isBillingSame,
    shipping_address: '',
    shipping_full_name: '',
    shipping_contact_number: '',
    shipping_country: '',
    shipping_landmark: '',
    shipping_pin_code: '',
    shipping_city: '',
    shipping_state: '',
    billing_address: '',
    billing_full_name: '',
    billing_contact_number: '',
    billing_country: '',
    billing_landmark: '',
    billing_pin_code: '',
    billing_city: '',
    billing_state: '',
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ defaultValues });

  const {
    control: controlShipping,
    handleSubmit: handleSubmitShipping,
    formState: { errors: shippingError },
    reset: resetShipping,
    watch,
  } = useForm({
    defaultValues: defaultValuesforShipping,
  });

  // coupon form
  const defaultValuesforCoupon = {
    coupon_code: '',
  };

  const {
    control: controlCoupon,
    handleSubmit: handleCouponFormSubmit,
    formState: { errors: couponError },
  } = useForm({
    defaultValues: defaultValuesforCoupon,
  });

  const watchShippingCountry = watch('shipping_country');
  const watchShippingState = watch('shipping_state');
  const watchBillingCountry = watch('billing_country');
  const watchBillingState = watch('billing_state');

  // updating country code for shipping details
  useEffect(() => {
    const getSingleCountry = getCountriesData?.data?.data?.find(
      (item) => item.common_name === watchShippingCountry
    );
    setShippingCodeDetails({
      ...shippingCodeDetails,
      countryCode: getSingleCountry?.iso2,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchShippingCountry]);

  // updating country code for billing details
  useEffect(() => {
    const getSingleCountry = getCountriesData?.data?.data?.find(
      (item) => item.common_name === watchBillingCountry
    );
    setBillingCodeDetails({
      ...billingCodeDetails,
      countryCode: getSingleCountry?.iso2,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchBillingCountry]);

  //updating state code for shipping details
  useEffect(() => {
    const getSingleState = getStatesData?.data?.data?.find(
      (item) => item.name === watchShippingState
    );
    setShippingCodeDetails({
      ...shippingCodeDetails,
      stateCode: getSingleState?.iso2,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchShippingState]);

  //updating state code for billing details
  useEffect(() => {
    const getSingleState = getStatesData?.data?.data?.find(
      (item) => item.name === watchBillingState
    );
    setBillingCodeDetails({
      ...billingCodeDetails,
      stateCode: getSingleState?.iso2,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchBillingState]);

  // eslint-disable-next-line no-unused-vars
  const verifyCustomer = useQuery(
    ['getProductsData', userEmail, enableVarifyUser],
    async () => {
      const resData = await makeAPIRequest.get(
        `${apiEndPoints.VERIFY_CUSTOMER}?email_or_number=${userEmail}`
      );
      return resData?.data;
    },
    {
      onSuccess: (res) => {
        if (res?.message === 'User Not Found') {
          setPaymentCompleted(false);
          sendOtpForEmail(userEmail, false);
        } else if (res.data.payment_completed === false) {
          setPaymentCompleted(false);
          setUserExit(true);
          setBillingSame(res.data.address_info[0].is_billing_same);
          reset({
            email: res.data.user_info.email,
          });
          resetShipping({
            is_billing_same: res.data.address_info[0].is_billing_same,
            shipping_address: res.data.address_info[0].shipping_address,
            shipping_full_name: res.data.address_info[0].shipping_full_name,
            shipping_contact_number:
              res.data.address_info[0].shipping_contact_number,
            shipping_country: res.data.address_info[0].shipping_country,
            shipping_landmark: res.data.address_info[0].shipping_landmark,
            shipping_pin_code: res.data.address_info[0].shipping_pin_code,
            shipping_city: res.data.address_info[0].shipping_city,
            shipping_state: res.data.address_info[0].shipping_state,
            billing_address: res.data.address_info[0].billing_address,
            billing_full_name: res.data.address_info[0].billing_full_name,
            billing_contact_number:
              res.data.address_info[0].billing_contact_number,
            billing_country: res.data.address_info[0].billing_country,
            billing_landmark: res.data.address_info[0].billing_landmark,
            billing_pin_code: res.data.address_info[0].billing_pin_code,
            billing_city: res.data.address_info[0].billing_city,
            billing_state: res.data.address_info[0].billing_state,
          });
          setFormDisabled(false);
        } else if (res.data.payment_completed === true) {
          setPaymentCompleted(true);
        } else {
          return null;
        }
      },
      enabled: enableVarifyUser,
    }
  );

  // userEmail submit handle
  const onSubmit = (data) => {
    setUserEmail(data.email);
    setEnableUser(true);
  };

  // send otp mutation
  const sendOtpMutation = useMutation(
    async (data) => {
      const res = await makeAPIRequest.post(
        apiEndPoints.CUSTOMER_RSEND_OTP,
        data
      );
      return res.data;
    },
    {
      onSuccess: () => {
        setOptSentStatus(true);
      },
      onError: (err) => {},
    }
  );

  const sendOtpForEmail = useCallback((email, resend) => {
    sendOtpMutation.mutate({ email: email, resend: resend });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // verify otp request
  const verifyOtp = useMutation(
    async (data) => {
      const res = await makeAPIRequest.post(
        apiEndPoints.CUSTOMER_VERIFY_OTP,
        data
      );
      return res.data;
    },
    {
      onSuccess: () => {
        setFormDisabled(false);
      },
      onError: (err) => {},
    }
  );

  // create user API
  const createUserMutation = useMutation(
    async (data) => {
      const res = await makeAPIRequest.post(apiEndPoints.CREATE_CUSTOMER, data);
      return res.data;
    },
    {
      onSuccess: () => {
        setShowPaymentgateway(true);
      },
    }
  );

  // update user mutation
  const updateUserMutation = useMutation(
    async (data) => {
      const res = await makeAPIRequest.put(apiEndPoints.CREATE_CUSTOMER, data);
      return res.data;
    },
    {
      onSuccess: () => {
        setShowPaymentgateway(true);

        // if (paymentGateway === 'stripe') {
        //   stripePaymentMutation.mutate({
        //     email_or_number: userEmail,
        //     product_id: getProductsData?.data?.data[0]?.id,
        //     success_url: `${window.location.origin}/payment-success`,
        //     cancel_url: `${window.location.origin}/checkout?user_email_or_number=${userEmail}`,
        //   });
        // } else {
        //   paypalPaymentMutation.mutate({
        //     email_or_number: userEmail,
        //     product_id: getProductsData?.data?.data[0]?.id,
        //   });
        // }
      },
    }
  );

  // stripe Payment

  const stripePaymentMutation = useMutation(
    async (data) => {
      const res = await makeAPIRequest.post(
        apiEndPoints.STRIPE_PAYMENT_API,
        data
      );
      return res.data;
    },
    {
      onSuccess: (data) => {
        window.location = data?.data?.url;
      },
      onError: (err) => {},
    }
  );

  // shipping form submit
  const onShippingFormSubmit = (data) => {
    let addressData;
    if (isBillingSame) {
      addressData = {
        ...data,
        is_billing_same: true,
        billing_address: data.shipping_address,
        billing_full_name: data.shipping_full_name,
        billing_contact_number: data.shipping_contact_number,
        billing_country: data.shipping_country,
        billing_landmark: data.shipping_landmark,
        billing_pin_code: data.shipping_pin_code,
        billing_city: data.shipping_city,
        billing_state: data.shipping_state,
      };
    } else {
      addressData = { ...data, is_billing_same: false };
    }
    if (isUserExit) {
      updateUserMutation.mutate({ email: userEmail, address: addressData });
    } else {
      createUserMutation.mutate({ email: userEmail, address: addressData });
    }
  };

  useEffect(() => {
    if (location.search.includes('user_email_or_number')) {
      setUserEmail(location.search.split('?')[1].split('=')[1]);
      setEnableUser(true);
    }
  }, [location.search]);

  // validate coupon
  const validateCoupon = useQuery(
    ['validating-coupon', userEmail, couponCode, params],
    async () => {
      const resData = await makeAPIRequest.get(
        `${apiEndPoints.VALIDATE_COUPON_CODE}?email_or_number=${userEmail}&coupon_code=${couponCode}&product_id=${params?.id}`
      );
      return resData?.data;
    },
    {
      onSuccess: (res) => {
        setCouponId(res?.data?.id);
      },
      enabled: couponCode.length > 0,
    }
  );

  // coupon form submit
  const onCouponSubmit = (data) => {
    setCouponCode(data?.coupon_code);
  };

  const getFormErrorMessage = (name, formType) => {
    if (formType === 'login') {
      return (
        errors[name] && (
          <small className='text-placeholder text-alert-error'>
            {errors[name].message}
          </small>
        )
      );
    } else if (formType === 'shipping') {
      return (
        shippingError[name] && (
          <small className='text-placeholder text-alert-error'>
            {shippingError[name].message}
          </small>
        )
      );
    } else {
      return (
        couponError[name] && (
          <small className='text-placeholder text-alert-error'>
            {couponError[name].message}
          </small>
        )
      );
    }
  };

  return (
    <div className='pt-10 px-8 max-w-main-layout checkout-page-container mx-auto'>
      {location.search.includes('user_email_or_number') ? (
        <div className='mb-4 bg-red-400 rounded-md'>
          <div className='max-w-7xl mx-auto py-3'>
            <p className='ml-3 font-medium text-white truncate text-lg text-center'>
              <span>Your payment got failed please try again</span>
            </p>
          </div>
        </div>
      ) : null}

      <p className='text-heading-4'>
        Information <span className='text-C9F9F9F'>&#62; Payment</span>
      </p>
      <div className='flex flex-wrap schedule-demo'>
        <div className='w-full md:w-1/2 md:pr-8 lg:pr-12'>
          <div>
            <p className='text-heading-4 pt-4 text-primary'>
              Contact information
            </p>
            <div className='pt-2'>
              <form onSubmit={handleSubmit(onSubmit)}>
                <label htmlFor='email' className='text-C9F9F9F pl-1'>
                  Email*
                </label>
                <div className='relative email-input'>
                  <Controller
                    name='email'
                    control={control}
                    rules={{
                      required: 'This field is required',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: 'Invalid email address.',
                      },
                    }}
                    render={({ field }) => (
                      <input
                        {...field}
                        type='text'
                        className='w-full mt-1 '
                        disabled={!isFormDisabled || otpSentStatus}
                      />
                    )}
                  />
                  {getFormErrorMessage('email', 'login')}
                  {isFormDisabled ? (
                    <button className='absolute top-4 z-50 right-4 text-primary'>
                      Verify
                    </button>
                  ) : (
                    <span className='flex absolute top-4 z-50 right-4 bg-transparent text-primary select-none'>
                      <TickIcon className='w-4 pr-1' />
                      Verified
                    </span>
                  )}
                  {isPaymentCompleted ? (
                    <p className='text-small text-primary pl-1'>
                      Already placed your order.
                    </p>
                  ) : null}
                </div>
              </form>
            </div>

            {otpSentStatus && isFormDisabled ? (
              <div className='pt-3 relative'>
                <label htmlFor='Otp' className='text-C9F9F9F pl-1'>
                  Enter OTP
                </label>{' '}
                <input
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  type='text'
                  maxLength={6}
                  className='w-full mt-1 base-input'
                />
                {verifyOtp && verifyOtp?.error?.response?.data?.message ? (
                  <p className='text-alert-error text-small pl-1'>
                    {verifyOtp?.error?.response?.data?.message}
                  </p>
                ) : null}
                <div className='w-10 absolute top-12 pt-1.5 right-6'>
                  <CountDown
                    sendOtpForEmail={sendOtpForEmail}
                    userEmail={userEmail}
                  />
                </div>
              </div>
            ) : null}
          </div>
          <p className='text-heading-4 text-primary mt-8 mb-2'>
            Shipping details
          </p>
          <div className='pr-2 lg:pr-4 max-h-80vh overflow-y-scroll scroller'>
            <div className={isFormDisabled ? 'opacity-50' : null}>
              <form
                id='shipping-form'
                onSubmit={handleSubmitShipping(onShippingFormSubmit)}
              >
                <div className='pt-2'>
                  <label htmlFor='name' className='text-C9F9F9F pl-1'>
                    Full name*
                  </label>
                  <Controller
                    name='shipping_full_name'
                    control={controlShipping}
                    rules={{ required: 'This field is required' }}
                    render={({ field }) => (
                      <input
                        {...field}
                        disabled={isFormDisabled}
                        className='w-full mt-1 base-input'
                        type='text'
                      />
                    )}
                  />
                  {getFormErrorMessage('shipping_full_name', 'shipping')}
                </div>
                <div className='pt-3'>
                  <label htmlFor='contact' className='text-C9F9F9F pl-1'>
                    Contact number*
                  </label>
                  <Controller
                    name='shipping_contact_number'
                    control={controlShipping}
                    rules={{ required: 'This field is required' }}
                    render={({ field }) => (
                      <input
                        {...field}
                        disabled={isFormDisabled}
                        className='w-full mt-1 base-input'
                        type='text'
                      />
                    )}
                  />
                  {getFormErrorMessage('shipping_contact_number', 'shipping')}
                </div>
                <div className='pt-3'>
                  <label htmlFor='country' className='text-C9F9F9F pl-1'>
                    Country*
                  </label>
                  <Controller
                    name='shipping_country'
                    control={controlShipping}
                    rules={{ required: 'This field is required' }}
                    render={({ field }) => (
                      <select
                        {...field}
                        className='mt-0.5 base-select'
                        id='select-input'
                        disabled={isFormDisabled}
                      >
                        <option value=''>Select country</option>
                        {getCountriesData?.data?.data?.map((country) => (
                          <option
                            key={country.common_name}
                            value={country.common_name}
                          >
                            {country.common_name}
                          </option>
                        ))}
                      </select>
                    )}
                  />
                  {getFormErrorMessage('shipping_country', 'shipping')}
                </div>
                <div className='pt-3'>
                  <label htmlFor='address' className='text-C9F9F9F pl-1'>
                    Address*
                  </label>
                  <Controller
                    name='shipping_address'
                    control={controlShipping}
                    rules={{ required: 'This field is required' }}
                    render={({ field }) => (
                      <textarea
                        {...field}
                        disabled={isFormDisabled}
                        className='w-full mt-1 base-textarea'
                        rows={4}
                      />
                    )}
                  />
                  {getFormErrorMessage('shipping_address', 'shipping')}
                </div>
                <div className='pt-3'>
                  <label htmlFor='landmark' className='text-C9F9F9F pl-1'>
                    Nearby landmark*
                  </label>
                  <Controller
                    name='shipping_landmark'
                    control={controlShipping}
                    rules={{ required: 'This field is required' }}
                    render={({ field }) => (
                      <input
                        {...field}
                        disabled={isFormDisabled}
                        className='w-full mt-1 base-input'
                        type='text'
                      />
                    )}
                  />
                  {getFormErrorMessage('shipping_landmark', 'shipping')}
                </div>
                <div className='pt-3'>
                  <label htmlFor='state' className='text-C9F9F9F pl-1'>
                    State*
                  </label>
                  <Controller
                    name='shipping_state'
                    control={controlShipping}
                    rules={{ required: 'This field is required' }}
                    render={({ field }) => (
                      <select
                        {...field}
                        className='mt-0.5 base-textarea'
                        disabled={isFormDisabled}
                      >
                        <option value=''>Select State</option>
                        {getStatesData?.data?.data?.map((state) => (
                          <option key={state.name} value={state.name}>
                            {state.name}
                          </option>
                        ))}
                      </select>
                    )}
                  />
                  {getFormErrorMessage('shipping_state', 'shipping')}
                </div>
                <div className='pt-3'>
                  <label htmlFor='city' className='text-C9F9F9F pl-1'>
                    City*
                  </label>
                  <Controller
                    name='shipping_city'
                    control={controlShipping}
                    rules={{ required: 'This field is required' }}
                    render={({ field }) => (
                      <select
                        {...field}
                        className='mt-0.5 base-select'
                        disabled={isFormDisabled}
                      >
                        <option value=''></option>
                        {getCitiesData?.data?.data?.map((state) => (
                          <option key={state.name} value={state.name}>
                            {state.name}
                          </option>
                        ))}
                      </select>
                    )}
                  />
                  {getFormErrorMessage('shipping_city', 'shipping')}
                </div>
                <div className='pt-3'>
                  <label htmlFor='pincode' className='text-C9F9F9F pl-1'>
                    Pin code*
                  </label>
                  <Controller
                    name='shipping_pin_code'
                    control={controlShipping}
                    rules={{ required: 'This field is required' }}
                    render={({ field }) => (
                      <input
                        {...field}
                        disabled={isFormDisabled}
                        className='w-full mt-1 base-input'
                        type='text'
                      />
                    )}
                  />
                  {getFormErrorMessage('shipping_pin_code', 'shipping')}
                </div>
                <div className='pt-3 pb-1 flex ml-1'>
                  <input
                    checked={isBillingSame}
                    onChange={() => setBillingSame(!isBillingSame)}
                    disabled={isFormDisabled}
                    type='checkbox'
                    id='billing'
                    className='mt-1.5 mr-2 border-none'
                  />
                  <label
                    htmlFor='billing'
                    className='text-C9F9F9F select-none cursor-pointer'
                  >
                    Billing address same as shipping
                  </label>
                </div>
              </form>
            </div>

            {/* billing form */}
            {isBillingSame ? null : (
              <div>
                <p className='text-heading-3 pt-6 text-primary'>
                  Billing details
                </p>
                <div className='pt-2'>
                  <label
                    htmlFor='billing_full_name'
                    className='text-C9F9F9F pl-1'
                  >
                    Full name*
                  </label>
                  <Controller
                    name='billing_full_name'
                    control={controlShipping}
                    rules={{ required: 'This field is required' }}
                    render={({ field }) => (
                      <input
                        {...field}
                        disabled={isFormDisabled}
                        className='w-full mt-1 base-input'
                        type='text'
                      />
                    )}
                  />
                  {getFormErrorMessage('billing_full_name', 'shipping')}
                </div>
                <div className='pt-3'>
                  <label
                    htmlFor='billing_contact_number'
                    className='text-C9F9F9F pl-1'
                  >
                    Contact number*
                  </label>
                  <Controller
                    name='billing_contact_number'
                    control={controlShipping}
                    rules={{ required: 'This field is required' }}
                    render={({ field }) => (
                      <input
                        {...field}
                        disabled={isFormDisabled}
                        className='w-full mt-1 base-input'
                        type='text'
                      />
                    )}
                  />
                  {getFormErrorMessage('billing_contact_number', 'shipping')}
                </div>
                <div className='pt-3'>
                  <label
                    htmlFor='billing_country'
                    className='text-C9F9F9F pl-1'
                  >
                    Country*
                  </label>
                  <Controller
                    name='billing_country'
                    control={controlShipping}
                    rules={{ required: 'This field is required' }}
                    render={({ field }) => (
                      <select
                        {...field}
                        className='mt-0.5 base-select'
                        disabled={isFormDisabled}
                      >
                        <option value=''>Select country</option>
                        {getCountriesData?.data?.data?.map((country) => (
                          <option
                            key={country.common_name}
                            value={country.common_name}
                          >
                            {country.common_name}
                          </option>
                        ))}
                      </select>
                    )}
                  />
                  {getFormErrorMessage('billing_country', 'shipping')}
                </div>
                <div className='pt-3'>
                  <label
                    htmlFor='billing_address'
                    className='text-C9F9F9F pl-1'
                  >
                    Address*
                  </label>
                  <Controller
                    name='billing_address'
                    control={controlShipping}
                    rules={{ required: 'This field is required' }}
                    render={({ field }) => (
                      <textarea
                        {...field}
                        disabled={isFormDisabled}
                        className='w-full mt-1 base-textarea'
                        rows={4}
                      />
                    )}
                  />
                  {getFormErrorMessage('billing_address', 'shipping')}
                </div>
                <div className='pt-3'>
                  <label
                    htmlFor='billing_landmark'
                    className='text-C9F9F9F pl-1'
                  >
                    Nearby landmark*
                  </label>
                  <Controller
                    name='billing_landmark'
                    control={controlShipping}
                    rules={{ required: 'This field is required' }}
                    render={({ field }) => (
                      <input
                        {...field}
                        disabled={isFormDisabled}
                        className='w-full mt-1 base-input'
                        type='text'
                      />
                    )}
                  />
                  {getFormErrorMessage('billing_landmark', 'shipping')}
                </div>
                <div className='pt-3'>
                  <label htmlFor='billing_state' className='text-C9F9F9F pl-1'>
                    State*
                  </label>
                  <Controller
                    name='billing_state'
                    control={controlShipping}
                    rules={{ required: 'This field is required' }}
                    render={({ field }) => (
                      <select
                        {...field}
                        className='mt-0.5 base-select'
                        disabled={isFormDisabled}
                      >
                        <option value=''>Select State</option>
                        {getStatesDataForBilling?.data?.data?.map((state) => (
                          <option key={state.name} value={state.name}>
                            {state.name}
                          </option>
                        ))}
                      </select>
                    )}
                  />
                  {getFormErrorMessage('billing_state', 'shipping')}
                </div>
                <div className='pt-3'>
                  <label htmlFor='billing_city' className='text-C9F9F9F pl-1'>
                    City*
                  </label>
                  <Controller
                    name='billing_city'
                    control={controlShipping}
                    rules={{ required: 'This field is required' }}
                    render={({ field }) => (
                      <select
                        {...field}
                        className='mt-0.5 base-select'
                        disabled={isFormDisabled}
                      >
                        <option value=''>Select State</option>
                        {getCitiesDataForBilling?.data?.data?.map((city) => (
                          <option key={city.name} value={city.name}>
                            {city.name}
                          </option>
                        ))}
                      </select>
                    )}
                  />
                  {getFormErrorMessage('billing_city', 'shipping')}
                </div>
                <div className='pt-3'>
                  <label
                    htmlFor='billing_pin_code'
                    className='text-C9F9F9F pl-1'
                  >
                    Pin code*
                  </label>
                  <Controller
                    name='billing_pin_code'
                    control={controlShipping}
                    rules={{ required: 'This field is required' }}
                    render={({ field }) => (
                      <input
                        {...field}
                        disabled={isFormDisabled}
                        className='w-full mt-1 base-input'
                        type='text'
                      />
                    )}
                  />
                  {getFormErrorMessage('billing_pin_code', 'shipping')}
                </div>
              </div>
            )}
          </div>
        </div>
        {/* product details */}
        <div
          className={`w-full md:w-1/2 pt-8 ${
            isFormDisabled ? ' opacity-50' : null
          }`}
        >
          {getProductsData.isLoading ? (
            <div>
              <div className='flex animate-pulse'>
                <div className='w-1/3 h-24 lg:h-36 bg-slate-200 rounded-lg'></div>
                <div>
                  <p className='lg:w-72 ml-6 h-12 lg:h-8 bg-slate-200 rounded-lg'></p>
                  <p className='w-40 ml-6 h-8 bg-slate-200 mt-4 rounded-lg'></p>
                </div>
              </div>
              <p className='w-80 h-8 bg-slate-200 mt-4 mb-10 rounded-lg'></p>
            </div>
          ) : (
            <div>
              <div className='flex'>
                <img
                  src={getProductsData?.data?.data[0]?.image}
                  alt=' '
                  className='w-1/3'
                />
                <div>
                  <p className='text-heading-4 lg:text-heading-4 pl-4'>
                    {getProductsData?.data?.data[0]?.title}
                  </p>
                  <p className='text-heading-4 lg:text-heading-4 pl-4 pt-2 lg:pt-4'>
                    <span className='text-primary font-semibold mr-1'>$</span>
                    {getProductsData?.data?.data[0]?.amount}
                  </p>
                </div>
              </div>
              <p className='text-text-primary text-828282 pt-2 pb-8'>
                {getProductsData?.data?.data[0]?.description}
              </p>
            </div>
          )}
          <hr className='border-B7B6B6 mr-4' />
          <form onSubmit={handleCouponFormSubmit(onCouponSubmit)}>
            <div className='pt-8 flex w-full pr-7'>
              <Controller
                name='coupon_code'
                control={controlCoupon}
                rules={{ required: 'This field is required' }}
                render={({ field }) => (
                  <input
                    {...field}
                    placeholder='Apply coupon code here'
                    className='rounded-r-0 border-primary rounded-md base-input focus:outline-none w-full'
                    disabled={isFormDisabled}
                    type='text'
                  />
                )}
              />
              <button className='bg-primary -ml-1 rounded-r-md w-60 flex justify-center items-center'>
                <PrimaryButton isLoading={validateCoupon?.isLoading}>
                  Apply
                </PrimaryButton>
              </button>
            </div>
            {getFormErrorMessage('coupon_code', 'coupon')}
            <div>
              {validateCoupon && validateCoupon?.data?.message === 'Success' ? (
                <div className='flex items-center'>
                  <SuccessIlls className='w-4 ml-1' />
                  <p className='text-small font-medium text-alert-success pl-1 pt-0.5'>
                    Coupon applied succesfully
                  </p>
                </div>
              ) : validateCoupon &&
                validateCoupon?.error?.response?.data?.message ? (
                <div className='flex items-center'>
                  <ErrorIlls className='w-4 ml-1' />
                  <p className='text-small font-medium text-alert-error pl-1 pt-0.5'>
                    {validateCoupon?.error?.response?.data?.message}
                  </p>
                </div>
              ) : null}
            </div>
          </form>
          <div className='py-6'>
            <div className='flex pr-6'>
              <p className='text-828282 whitespace-nowrap'>Total MRP</p>
              <p className='w-full text-right text-font-tertiary'>
                <span className='text-primary font-semibold'>$</span>{' '}
                {!getProductsData.isLoading &&
                  getProductsData?.data?.data[0]?.amount}
              </p>
            </div>
            <div className='flex pr-6 pt-2'>
              <span className='flex'>
                <p className='whitespace-nowrap text-828282'>Discount on MRP</p>
              </span>
              <p className='w-full text-right text-font-tertiary'>
                <span className='text-primary font-semibold'>$</span> 0
              </p>
            </div>
            <div className='flex pr-6 pt-2'>
              <span className='flex'>
                <p className='whitespace-nowrap text-828282'>Coupon Discount</p>
              </span>
              <p className='w-full text-right text-font-tertiary'>
                <span className='text-primary font-semibold'>$</span>{' '}
                {validateCoupon?.data?.data?.discounted_amount || '0'}
              </p>
            </div>
            <div className='flex pr-6 pt-2'>
              <span className='flex'>
                <p className='whitespace-nowrap text-828282'>
                  Shipping Charges
                </p>
                <QuestionIcon className='w-4 ml-2' />
              </span>
              <p className='w-full text-right text-font-tertiary'>
                <span className='text-primary font-semibold'>$</span> 0
              </p>
            </div>
          </div>
          <hr className='border-B7B6B6 mr-4' />
          <div className='flex pt-6 pr-6'>
            <p className='text-font-tertiary'>Total</p>
            <p className='w-full text-right text-font-tertiary'>
              <span className='text-primary font-semibold'>$</span>{' '}
              {validateCoupon?.data?.data?.payable_amount ||
                (!getProductsData.isLoading &&
                  getProductsData?.data?.data[0]?.amount)}
            </p>
          </div>
          {!showPaymentGateway ? (
            <button
              className='w-60 rounded-md mt-8 mb-8 md:mb-0 outline-none'
              disabled={isFormDisabled}
              form='shipping-form'
            >
              <PrimaryButton
                isLoading={
                  createUserMutation.isLoading ||
                  updateUserMutation.isLoading ||
                  stripePaymentMutation.isLoading
                }
              >
                <p className='whitespace-nowrap'>Continue to payment</p>
              </PrimaryButton>
            </button>
          ) : (
            <div className='mt-8'>
              <p className=' text-text-primary'>Pay with</p>
              <div className='flex mt-4 space-x-6 payment_int'>
                <button className='border border-primary rounded-lg h-9 w-40 outline-none flex justify-center items-center overflow-hidden bg-secondary'>
                  <PayWithPaypalButton
                    paypal={paypal}
                    email={userEmail}
                    id={1}
                    couponId={couponId}
                  />
                </button>
                <button
                  className='border  border-primary rounded-lg h-9 w-40 outline-none flex justify-center items-center'
                  onClick={() => {
                    if (couponId > 0) {
                      stripePaymentMutation.mutate({
                        email_or_number: userEmail,
                        product_id: getProductsData?.data?.data[0]?.id,
                        success_url: `${window.location.origin}/payment-success`,
                        cancel_url: `${window.location.origin}/checkout?user_email_or_number=${userEmail}`,
                        coupon_obj_id: couponId,
                      });
                    } else {
                      stripePaymentMutation.mutate({
                        email_or_number: userEmail,
                        product_id: getProductsData?.data?.data[0]?.id,
                        success_url: `${window.location.origin}/payment-success`,
                        cancel_url: `${window.location.origin}/checkout?user_email_or_number=${userEmail}`,
                      });
                    }
                  }}
                >
                  {stripePaymentMutation.isLoading ? (
                    <Loader color='#FF4D53' />
                  ) : (
                    <Stripe className='w-20 h-6 mx-auto' />
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='hidden md:block mt-20'>
        {/* <img src={checkoutBg} alt='' /> */}
        <hr className='mt-6 mb-3 border-primary border-opacity-40' />
        <div className='flex text-515151 select-none'>
          <div className='flex space-x-10 lg:space-x-28 pb-5'>
            <p className='cursor-pointer'>Refund policy</p>
            <p className='cursor-pointer'>Shipping policy</p>
          </div>
          <div className='flex justify-end space-x-10 lg:space-x-28 ml-auto'>
            <p className='cursor-pointer'>Privacy policy</p>
            <p className='cursor-pointer'>Terms of service</p>
          </div>
        </div>
      </div>
      <div className='-mx-8 mt-4 md:hidden'>
        <Footer />
      </div>
    </div>
  );
}
