import { useAtom } from 'jotai';
import { memo } from 'react';
import { Item } from '@radix-ui/react-dropdown-menu';

import Avatar from '../avatar/avatar';
import DropdownMenu from './dropdownMenu';

import { logoutModalVisibleAtom } from 'pages/auth/components/LogoutModal';

import { ReactComponent as AptaILogo } from 'assets/aptai-logo.svg';
import { ReactComponent as SignoutIlls } from 'assets/images/signoutIlls.svg';

const Header = () => {
  const [, setIsmodalVisible] = useAtom(logoutModalVisibleAtom);

  const HeaderDropdownMenu = () => {
    return (
      <DropdownMenu triggerIcon={<Avatar />}>
        <Item
          className='text-font-secondary focus:bg-font-placeholder focus:bg-opacity-5 flex cursor-default select-none items-center rounded-md px-2 py-1 my-1 text-base outline-none'
          onClick={() => setIsmodalVisible(true)}
        >
          <SignoutIlls />
          <span className='flex-grow pl-3'>Logout</span>
        </Item>
      </DropdownMenu>
    );
  };

  return (
    <nav className='h-20 items-center bg-white w-full shadow'>
      <div className='flex justify-between py-3 px-4  max-w-main-layout mx-auto'>
        <AptaILogo />

        <ul className='flex space-x-4 items-center select-none'>
          <li className=' cursor-pointer'>
            <HeaderDropdownMenu />
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default memo(Header);
