import React from 'react';
import * as AvatarRadix from '@radix-ui/react-avatar';

const Avatar = () => {
  return (
    <AvatarRadix.Root className='relative inline-flex h-10 w-10'>
      <AvatarRadix.Image
        className='h-full w-full object-cover rounded-full'
        src={JSON.parse(localStorage.getItem('profile_picture'))}
      />
      <AvatarRadix.Fallback className='flex h-full w-full items-center justify-center bg-tertiary bg-opacity-30 rounded-full'>
        <span className='text-sm font-medium uppercase text-gray-700 '>pp</span>
      </AvatarRadix.Fallback>
    </AvatarRadix.Root>
  );
};

export default Avatar;
