import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useQuery } from "react-query";
import makeAPIRequest from "../../../services/makeApiRequest";
import apiEndPoints from "../../../services/apiEndPoints";
import { useWindowSize } from "react-use";

import activeslickdot from "../../../assets/images/landingpage/activeslickdot.png";
import heroBg from "../../../assets/images/landingpage/productGalleyBg.png";
import ReactPlayer from "react-player";
export default function ProductGallery() {
  const { width } = useWindowSize();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [imageCount, setImageCount] = useState(1);

  // const getGalleryData = useQuery(
  //   ['getGalleryData'],
  //   async () => {
  //     const resData = await makeAPIRequest.get(
  //       `${apiEndPoints.PRODUCT_GALLERY}`
  //     );
  //     return resData?.data;
  //   },
  //   {
  //     onSuccess: (data) => {
  //       setImageCount(data?.data?.length);
  //     },
  //   }
  // );

  const getGalleryData = {
    data: {
      data: [
        {
          id: 1,
          image:
            "https://letsenhance.io/static/8f5e523ee6b2479e26ecc91b9c25261e/1015f/MainAfter.jpg",
        },
        {
          id: 2,
          image:
            "https://letsenhance.io/static/8f5e523ee6b2479e26ecc91b9c25261e/1015f/MainAfter.jpg",
        },
      ],
    },
  };

  const settings = {
    // custom slick dots
    customPaging: function (i) {
      if (i === currentSlide) {
        return (
          <div className="flex items-end">
            <img src={activeslickdot} alt=" " className="h-1 rounded-full" />
          </div>
        );
      } else {
        return <p className="text-heading-3 font-extrabold text-C1C1C1">.</p>;
      }
    },
    dotsClass: "slick-dots slick-thumb",
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow:
      width < 500 || imageCount === 1
        ? 1
        : (width > 500 && width < 780) || imageCount === 2
        ? 2
        : 3,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    beforeChange: (prev, next) => {
      setCurrentSlide(next);
    },
  };

  return (
    <div
      className="pb-24 md:pb-28 lg:pb-36 xl:pb-40 md:pt-4 lg:pt-8 px-4 lg:px-10 xl:px-20"
      style={{
        background: `url(${heroBg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition: "cover",
      }}
    >
      <p className="text-center font-semibold text-heading-3 lg:text-heading-2 pb-6 pt-6">
        Product gallery
      </p>

      <div className="grid place-items-center">
        <ReactPlayer
          url={
            "https://stgaptahr.blob.core.windows.net/aptai/Visit_to_DEVNAR.mp4"
          }
          // width={"80%"}
          controls
        />
      </div>
      {/* <Slider {...settings}>
        {getGalleryData?.data?.data?.map((item) => (
          <div key={item?.id}>
            <div className="w-11/12 lg:w-11/12 mx-auto pb-8">
              <img src={item?.image} alt=" " className="w-full h-80" />
            </div>
          </div>
        ))}
      </Slider> */}
    </div>
  );
}
