import { QueryClient, QueryClientProvider } from 'react-query';
import { Toaster } from 'react-hot-toast';

import AppRoutes from './router/routes';

import AuthProvider from './context/authContext';

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: 1,
      },
    },
  });

  return (
    <>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <Toaster
            position='top-right'
            reverseOrder={false}
            containerStyle={{
              position: 'fixed',
            }}
          />
          <AppRoutes />
        </QueryClientProvider>
      </AuthProvider>
    </>
  );
}

export default App;
