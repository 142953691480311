import { useState } from 'react';
import Datetime from 'react-datetime';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import moment from 'moment';

import { PrimaryButton } from 'components/primaryButton';

import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndPoints';

import scheduleDemoBg from 'assets/images/scheduleDemo/scheduleDemoBg.png';
import { ReactComponent as CalenderIcon } from 'assets/images/scheduleDemo/calenderIcon.svg';

import 'react-datetime/css/react-datetime.css';

export default function ScheduleDemo() {
  const [error, setErrorMessage] = useState();
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const navigate = useNavigate();

  const defaultValues = {
    name: '',
    country: '',
    company_name: '',
    schedule_date: '',
    email: '',
    contact_number: '',
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
  } = useForm({ defaultValues });

  const getCountriesData = useQuery(['getCountriesList'], async () => {
    const resData = await makeAPIRequest.get(`${apiEndPoints.COUNTRIES_LIST}`);
    return resData?.data;
  });

  const scheduleDemoMutation = useMutation(
    async (data) => {
      const res = await makeAPIRequest.post(apiEndPoints.SCHEDULE_DEMO, data);
      return res.data;
    },
    {
      onSuccess: () => {
        reset({
          name: '',
          country: '',
          company_name: '',
          schedule_date: '',
          email: '',
          contact_number: '',
        });
        navigate('/success');
      },
      onError: (err) => {
        setErrorMessage(err.response.data.message);
        setTimeout(() => {
          setErrorMessage();
        }, 3000);
      },
    }
  );

  const onSubmit = (data) => {
    scheduleDemoMutation.mutate({
      company_name: data?.company_name,
      contact_number: data?.contact_number,
      country: data?.country,
      email: data?.email,
      name: data?.name,
      schedule_date: data?.schedule_date._d,
    });
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <small className='text-placeholder text-alert-error pl-1'>
          {errors[name].message}
        </small>
      )
    );
  };

  // checking user existence
  const checkExistingEmail = (val) => {
    makeAPIRequest
      .get(
        `${apiEndPoints.CHECK_USER_EXISTENCE}?email=${val}&scheduled_client=true`
      )
      .then((res) => {
        if (res?.data?.message === 'Client Found') {
          setError('email', {
            type: 'manual',
            message: 'User with same email already requested',
          });
          setDisableSubmitButton(true);
        } else {
          setDisableSubmitButton(false);
        }
      });
  };

  // validation for date picker
  const yesterday = moment().subtract(1, 'day');

  const valid = function (current) {
    return current.isAfter(yesterday);
  };

  return (
    <div
      className='h-screen -mt-20  pt-20 overflow-y-scroll scroller'
      style={{
        background: `url(${scheduleDemoBg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 85%',
        backgroundPosition: 'bottom',
      }}
    >
      <p className='text-primary text-heading-2 font-medium text-center pt-8 pb-6'>
        Schedule a demo
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='shadow-lg border border-font-placeholder border-opacity-20 w-11/12 md:w-3/4 lg:w-2/3 xl:w-1/2 mx-auto rounded-lg bg-white pt-8 px-8 md:px-10 pb-6'>
          <div className='flex flex-wrap'>
            <div className='w-full md:w-1/2 md:pr-4 pb-4'>
              <label htmlFor='name' className='pl-1 text-828282'>
                Full name
              </label>
              <Controller
                name='name'
                control={control}
                rules={{ required: 'This field is required' }}
                render={({ field }) => (
                  <input {...field} className='mt-0.5 base-input' type='text' />
                )}
              />
              {getFormErrorMessage('name')}
            </div>
            <div className='w-full md:w-1/2 md:pl-4 pb-4 md:pb-0'>
              <label htmlFor='country' className='pl-1 text-828282'>
                Country
              </label>
              <Controller
                name='country'
                control={control}
                rules={{ required: 'This field is required' }}
                render={({ field }) => (
                  <select {...field} className='mt-0.5 base-select'>
                    <option value=''>Select country</option>
                    {getCountriesData?.data?.data?.map((country) => (
                      <option
                        key={country.common_name}
                        value={country.common_name}
                      >
                        {country.common_name}
                      </option>
                    ))}
                  </select>
                )}
              />
              {getFormErrorMessage('country')}
            </div>
            <div className='w-full md:w-1/2 md:pr-4 pb-4'>
              <label htmlFor='company_name' className='pl-1 text-828282'>
                Company name
              </label>
              <Controller
                name='company_name'
                control={control}
                rules={{ required: 'This field is required' }}
                render={({ field }) => (
                  <input {...field} className='mt-0.5 base-input' type='text' />
                )}
              />
              {getFormErrorMessage('company_name')}
            </div>
            <div className='relative w-full md:w-1/2 md:pl-4 pb-4 md:pb-0'>
              <label htmlFor='schedule_date' className='pl-1 text-828282'>
                Select Date & Time
              </label>
              <Controller
                name='schedule_date'
                control={control}
                rules={{ required: 'This field is required' }}
                render={({ field: { onChange } }) => (
                  <Datetime
                    className='w-full mt-0.5 react-cal-input'
                    onChange={(date) => onChange(date)}
                    isValidDate={valid}
                  />
                )}
              />
              {getFormErrorMessage('schedule_date')}
              <CalenderIcon className='absolute top-9 right-3' />
            </div>
            <div className='w-full md:w-1/2 md:pr-4 pb-4 md:pb-0'>
              <label htmlFor='email' className='pl-1 text-828282'>
                Email
              </label>
              <Controller
                name='email'
                control={control}
                rules={{
                  required: 'This field is required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'Invalid email address.',
                  },
                }}
                render={({ field }) => (
                  <input
                    {...field}
                    className='mt-0.5 base-input'
                    type='text'
                    onBlur={(e) => checkExistingEmail(e.target.value)}
                  />
                )}
              />
              {getFormErrorMessage('email')}
            </div>
            <div className='w-full md:w-1/2 md:pl-4'>
              <label htmlFor='contact_number' className='pl-1 text-828282'>
                Contact
              </label>
              <Controller
                name='contact_number'
                control={control}
                rules={{
                  required: 'This field is required',
                  pattern: {
                    value: /^[0-9]*$/i,
                    message: 'Only numbers allowed.',
                  },
                }}
                render={({ field }) => (
                  <input {...field} className='mt-0.5 base-input' type='text' />
                )}
              />
              {getFormErrorMessage('contact_number')}
            </div>
          </div>
          <div className='flex justify-center pt-6'>
            <button className='w-32' disabled={disableSubmitButton}>
              <PrimaryButton isLoading={scheduleDemoMutation?.isLoading}>
                Submit
              </PrimaryButton>
            </button>
          </div>
          {error && (
            <p className='text-xs text-center pt-2 text-red-600'>{error}</p>
          )}
        </div>
      </form>
    </div>
  );
}
