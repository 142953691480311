import { useState } from 'react';
import { useDebounce } from 'react-use';
import { useSearchParams } from 'react-router-dom';

import { SearchIcon } from 'components/Icons';

import PeopleCard from './components/peopleCard';
import AddPeopleDropdown from './components/addPeopleDropdown';
import AddAndEditPeopleModal from './components/addAndEditPeopleModal';
import DeletePeopleModal from './components/deletePeopleModal';

import UseGetAllPeople from './hooks/useGetAllPeople';

import { ReactComponent as PersonIlls } from 'assets/images/noPeopleIlls.svg';

const PeopleAndTrainingPage = () => {
  const [searchParams, setSearchParam] = useSearchParams();

  const [peopleSearhvalue, setPeopleSearchvalue] = useState(
    searchParams.get('search') || ''
  );

  const peoples = UseGetAllPeople(peopleSearhvalue);

  useDebounce(
    () => {
      if (peopleSearhvalue) {
        setSearchParam({ search: peopleSearhvalue });
      }
    },
    500,
    [peopleSearhvalue]
  );

  return (
    <>
      <div className='flex items-center justify-between mb-4'>
        <p className=' text-primary text-heading-4 xl:text-heading-3 font-semibold '>
          People and training
        </p>

        <div className='flex items-center space-x-4 mb-4 lg:mb-0'>
          {!peopleSearhvalue && peoples.isLoading ? (
            <div className='relative w-60  h-10 animate-pulse bg-font-placeholder/10 rounded-lg'></div>
          ) : (
            <>
              {' '}
              <div className='relative w-44 xl:w-52'>
                <input
                  type='text'
                  className='border border-primary rounded-3xl focus:ring-primary focus:border-primary block w-full  py-1.5 pl-9 text-small bg-[#FEFAF8] placeholder:tracking-wide'
                  placeholder='Search'
                  value={peopleSearhvalue}
                  onChange={(e) => setPeopleSearchvalue(e.target.value)}
                />
                <span className='absolute left-3 top-2.5 '>
                  <SearchIcon />
                </span>
              </div>
              {peopleSearhvalue || peoples.data?.length > 0 ? (
                <AddPeopleDropdown />
              ) : null}
            </>
          )}
        </div>
      </div>
      {peoples.isLoading ? (
        <div className='grid grid-cols-3 xl:grid-cols-4 gap-10 scroller overflow-y-auto max-h-[70vh] py-4 px-2'>
          <PeopleCard isLoading />
          <PeopleCard isLoading />
          <PeopleCard isLoading />
          <PeopleCard isLoading />
        </div>
      ) : (
        <div className='grid grid-cols-3 xl:grid-cols-4 gap-10 scroller overflow-y-auto max-h-[70vh] py-4 px-2'>
          {peoples.data?.length > 0 ? (
            <>
              {peoples.data?.map((person) => (
                <PeopleCard key={person.id} personDetails={person} />
              ))}
            </>
          ) : (
            <div className=' col-span-3 xl:col-span-4 flex justify-center items-center h-[50vh]'>
              <div className='flex flex-col items-center'>
                <PersonIlls />
                <p className='text-center text-text-primary my-6'>
                  {peopleSearhvalue
                    ? 'No people found'
                    : 'Looks good with people'}
                </p>
                {peopleSearhvalue ? null : <AddPeopleDropdown isEmpty />}
              </div>
            </div>
          )}
        </div>
      )}

      <AddAndEditPeopleModal />
      <DeletePeopleModal />
    </>
  );
};

export default PeopleAndTrainingPage;
