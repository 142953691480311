export const PeopleIcon = (
  <svg
    width='22'
    height='22'
    viewBox='0 0 25 25'
    fill='none'
    xmlns='https://www.w3.org/2000/svg'
  >
    <path
      d='M23.9585 21.8753V19.792C23.9578 18.8688 23.6505 17.972 23.0849 17.2423C22.5193 16.5127 21.7274 15.9915 20.8335 15.7607'
      stroke='#FF4D53'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M17.7082 21.875V19.7917C17.7082 18.6866 17.2692 17.6268 16.4878 16.8454C15.7064 16.064 14.6466 15.625 13.5415 15.625H5.20817C4.1031 15.625 3.04329 16.064 2.26189 16.8454C1.48049 17.6268 1.0415 18.6866 1.0415 19.7917V21.875'
      stroke='#FF4D53'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M16.6665 3.26074C17.5628 3.49022 18.3572 4.01147 18.9245 4.74232C19.4918 5.47316 19.7997 6.37202 19.7997 7.2972C19.7997 8.22238 19.4918 9.12124 18.9245 9.85209C18.3572 10.5829 17.5628 11.1042 16.6665 11.3337'
      stroke='#FF4D53'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.37516 11.4583C11.6763 11.4583 13.5418 9.59285 13.5418 7.29167C13.5418 4.99048 11.6763 3.125 9.37516 3.125C7.07398 3.125 5.2085 4.99048 5.2085 7.29167C5.2085 9.59285 7.07398 11.4583 9.37516 11.4583Z'
      stroke='#FF4D53'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export const MyDeviceIcon = (
  <svg
    width='26'
    height='25'
    viewBox='0 0 30 13'
    fill='none'
    xmlns='https://www.w3.org/2000/svg'
  >
    <path
      d='M8.64824 0.85C6.66895 0.85 4.92141 1.85572 3.88662 3.38106H1.58896C1.18091 3.38106 0.85 3.71197 0.85 4.12002C0.85 4.52806 1.18091 4.85898 1.58896 4.85898H3.16748C2.99219 5.40883 2.89703 5.99412 2.89703 6.60111C2.89703 9.77246 5.47689 12.3523 8.64824 12.3523C11.8196 12.3523 14.3994 9.77246 14.3994 6.60111C14.3994 3.42976 11.8196 0.85 8.64824 0.85ZM8.64824 10.8744C6.2921 10.8744 4.37495 8.95725 4.37495 6.60111C4.37495 4.24497 6.2921 2.32782 8.64824 2.32782C11.0044 2.32782 12.9215 4.24497 12.9215 6.60111C12.9215 8.95725 11.0044 10.8744 8.64824 10.8744Z'
      fill='#FF4D53'
      stroke='#FF4D53'
      strokeWidth='0.3'
    />
    <path
      d='M21.0726 0.85C19.0858 0.85 17.3324 1.8632 16.2991 3.3984C15.8434 2.90555 15.1916 2.59568 14.4681 2.59568C14.0502 2.59568 13.6365 2.70119 13.2715 2.90091L13.2714 2.90092C12.9134 3.09687 12.782 3.54591 12.9779 3.90401L12.9779 3.90402C13.1738 4.26202 13.6229 4.39319 13.9809 4.19756L13.981 4.1975C14.1311 4.1153 14.2944 4.0737 14.4681 4.0737C15.0288 4.0737 15.4852 4.53013 15.4852 5.09082C15.4852 5.12876 15.4881 5.16591 15.4936 5.20206C15.3811 5.65027 15.3214 6.119 15.3214 6.60131C15.3214 9.77266 17.9012 12.3525 21.0726 12.3525C23.4538 12.3525 25.6167 10.8564 26.4561 8.62879L26.4561 8.62877C26.5999 8.24694 26.4071 7.82069 26.0251 7.67688C25.6432 7.53275 25.2168 7.7258 25.073 8.10782C24.4496 9.76234 22.8415 10.8747 21.0726 10.8747C18.7164 10.8747 16.7993 8.95755 16.7993 6.60141C16.7993 4.24527 18.7164 2.32812 21.0726 2.32812C23.1907 2.32812 24.9652 3.84308 25.2937 5.93102C25.3571 6.33418 25.7352 6.60939 26.1385 6.54611L26.1386 6.5461C26.5417 6.48267 26.817 6.10445 26.7537 5.70137L26.7537 5.70134C26.7086 5.41445 26.6411 5.13312 26.5537 4.85917H28.4114C28.8195 4.85917 29.1504 4.52826 29.1504 4.12021C29.1504 3.71222 28.8195 3.38106 28.4113 3.38106H25.8364C25.5536 2.9628 25.2156 2.57964 24.8273 2.24475C23.7846 1.34525 22.4511 0.85 21.0726 0.85Z'
      fill='#FF4D53'
      stroke='#FF4D53'
      strokeWidth='0.3'
    />
  </svg>
);

export const HowItWorksIcon = (
  <svg
    width='20'
    height='19'
    viewBox='0 0 15 17'
    fill='none'
    xmlns='https://www.w3.org/2000/svg'
  >
    <path
      d='M-1.39164e-06 5.899C0.00976423 5.82092 0.0286184 5.74399 0.0562482 5.6695L0.0562482 5.593C0.101325 5.5056 0.161451 5.42527 0.234374 5.355L5.85937 0.255001C5.93687 0.188884 6.02548 0.13437 6.12187 0.0935007L6.20625 0.0935007C6.30149 0.0439808 6.40666 0.0121933 6.51562 7.41728e-07L12.1875 2.45877e-07C12.9334 1.80666e-07 13.6488 0.26866 14.1762 0.746878C14.7037 1.2251 15 1.8737 15 2.55L15 14.45C15 15.1263 14.7037 15.7749 14.1762 16.2531C13.6488 16.7313 12.9334 17 12.1875 17L2.8125 17C2.06658 17 1.35121 16.7313 0.823761 16.2531C0.296316 15.7749 -5.84965e-07 15.1263 -6.4409e-07 14.45L-1.38718e-06 5.95C-1.38718e-06 5.95 -1.38718e-06 5.95 -1.39164e-06 5.899ZM5.625 2.8985L3.19687 5.1L4.6875 5.1C4.93614 5.1 5.1746 5.01045 5.35041 4.85104C5.52623 4.69164 5.625 4.47543 5.625 4.25L5.625 2.8985ZM1.875 14.45C1.875 14.6754 1.97377 14.8916 2.14959 15.051C2.3254 15.2104 2.56386 15.3 2.8125 15.3L12.1875 15.3C12.4361 15.3 12.6746 15.2104 12.8504 15.051C13.0262 14.8916 13.125 14.6754 13.125 14.45L13.125 2.55C13.125 2.32457 13.0262 2.10837 12.8504 1.94896C12.6746 1.78955 12.4361 1.7 12.1875 1.7L7.5 1.7L7.5 4.25C7.5 4.9263 7.20368 5.57491 6.67624 6.05312C6.14879 6.53134 5.43342 6.8 4.6875 6.8L1.875 6.8L1.875 14.45Z'
      fill='#FF4D53'
    />
  </svg>
);

export const FaqIcon = (
  <svg
    width='20'
    height='20'
    viewBox='0 0 18 18'
    fill='none'
    xmlns='https://www.w3.org/2000/svg'
  >
    <path
      d='M0.8 9C0.8 4.47127 4.47127 0.8 9 0.8C13.5287 0.8 17.2 4.47127 17.2 9C17.2 13.5287 13.5287 17.2 9 17.2H2.20755C1.43018 17.2 0.8 16.5698 0.8 15.7925V9Z'
      stroke='#FF4D53'
      strokeWidth='1.6'
    />
    <path
      d='M7.51525 11.0209V10.8819C7.51798 10.4046 7.56025 10.0241 7.64207 9.74049C7.72662 9.45686 7.84934 9.22777 8.01025 9.05322C8.17116 8.87868 8.3648 8.72049 8.59116 8.57868C8.76025 8.46958 8.91162 8.3564 9.04525 8.23913C9.17889 8.12186 9.28525 7.99231 9.36434 7.85049C9.44344 7.70595 9.48298 7.54504 9.48298 7.36777C9.48298 7.17958 9.43798 7.01458 9.34798 6.87277C9.25798 6.73095 9.13662 6.62186 8.98389 6.54549C8.83389 6.46913 8.66753 6.43095 8.4848 6.43095C8.30753 6.43095 8.1398 6.47049 7.98162 6.54958C7.82344 6.62595 7.69389 6.74049 7.59298 6.89322C7.49207 7.04322 7.43753 7.23004 7.42934 7.45368H5.76025C5.77389 6.90822 5.9048 6.45822 6.15298 6.10368C6.40116 5.7464 6.7298 5.48049 7.13889 5.30595C7.54798 5.12868 7.99934 5.04004 8.49298 5.04004C9.03571 5.04004 9.51571 5.13004 9.93298 5.31004C10.3503 5.48731 10.6775 5.74504 10.9148 6.08322C11.1521 6.4214 11.2707 6.82913 11.2707 7.3064C11.2707 7.62549 11.2175 7.90913 11.1112 8.15731C11.0075 8.40277 10.8616 8.62095 10.6734 8.81186C10.4853 9.00004 10.263 9.17049 10.0066 9.32322C9.79116 9.4514 9.61389 9.58504 9.4748 9.72413C9.33844 9.86322 9.23616 10.0241 9.16798 10.2069C9.10253 10.3896 9.06844 10.6146 9.06571 10.8819V11.0209H7.51525ZM8.32525 13.6391C8.05253 13.6391 7.81934 13.5437 7.62571 13.3528C7.4348 13.1591 7.34071 12.9273 7.34344 12.6573C7.34071 12.39 7.4348 12.1609 7.62571 11.97C7.81934 11.7791 8.05253 11.6837 8.32525 11.6837C8.58434 11.6837 8.81207 11.7791 9.00844 11.97C9.2048 12.1609 9.30434 12.39 9.30707 12.6573C9.30434 12.8373 9.25662 13.0023 9.16389 13.1523C9.07389 13.2996 8.95525 13.4182 8.80798 13.5082C8.66071 13.5955 8.4998 13.6391 8.32525 13.6391Z'
      fill='#FF4D53'
    />
  </svg>
);

export const SupportIcon = (
  <svg
    width='20'
    height='20  '
    viewBox='0 0 20 20'
    fill='none'
    xmlns='https://www.w3.org/2000/svg'
  >
    <path
      d='M9.99984 18.3337C14.6022 18.3337 18.3332 14.6027 18.3332 10.0003C18.3332 5.39795 14.6022 1.66699 9.99984 1.66699C5.39746 1.66699 1.6665 5.39795 1.6665 10.0003C1.6665 14.6027 5.39746 18.3337 9.99984 18.3337Z'
      stroke='#FF4D53'
      strokeWidth='1.7'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10 13.3333V10'
      stroke='#FF4D53'
      strokeWidth='1.7'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10 6.66699H10.01'
      stroke='#FF4D53'
      strokeWidth='1.7'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export const LogoutIcon = (
  <svg
    width='20'
    height='20'
    viewBox='0 0 18 18'
    fill='none'
    xmlns='https://www.w3.org/2000/svg'
  >
    <path
      d='M13.7698 4.98047C14.7136 5.92456 15.3563 7.1273 15.6166 8.43661C15.8768 9.74592 15.743 11.103 15.2321 12.3363C14.7211 13.5696 13.856 14.6236 12.746 15.3652C11.636 16.1068 10.331 16.5027 8.9961 16.5027C7.66117 16.5027 6.35621 16.1068 5.24623 15.3652C4.13624 14.6236 3.27108 13.5696 2.76012 12.3363C2.24916 11.103 2.11536 9.74592 2.37563 8.43661C2.63591 7.1273 3.27856 5.92456 4.22235 4.98047'
      stroke='#FF4D53'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9 1.5V9'
      stroke='#FF4D53'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
