import { useAtom } from 'jotai';
import { useMutation, useQueryClient } from 'react-query';

import apiEndPoints from 'services/apiEndPoints';
import makeAPIRequest from 'services/makeApiRequest';
import { SuccessToast, ErrorToast } from 'services/toasterService';

import { peopleDeleteModalVisibleAtom } from '../components/deletePeopleModal';
import { currentSelectedPersonAtom } from '../components/peopleCardActionDropdown';

const useDeletePeopleMutation = () => {
  const queryClient = useQueryClient();

  const [, setIsModalOpen] = useAtom(peopleDeleteModalVisibleAtom);
  const [, setCurrentSelectedPerson] = useAtom(currentSelectedPersonAtom);

  return useMutation(
    async (data) => {
      let resData = await makeAPIRequest.delete(apiEndPoints.PEOPLES_API, {
        data: data,
      });
      return resData.data;
    },
    {
      onSuccess: (data) => {
        SuccessToast({ text: data.message });
        queryClient.invalidateQueries('peoples');
        setIsModalOpen(false);
        setCurrentSelectedPerson({});
      },
      onError: (err) => {
        ErrorToast({ text: err.response.data.message });
      },
    }
  );
};

export default useDeletePeopleMutation;
